export const ko = {
  _LOGIN: {
    _EMAIL: "이메일",
    _PASSWORD: "비밀번호",
    _REMEMBER: "기억하기",
    _LOGIN: "로그인",
    _SIGNUP: "가입하기",
    _FORGOTPASSWORD: "비밀번호를 잊으셨나요?",
    _NOACCOUNT: "계정이 없으신가요?",
    _ERROR_EMAIL: "등록되지 않은 것 같습니다! 다시 시도하세요.",
    _ERROR_PASSWORD: "잘못된 비밀번호",
    _SUCCESS: "로그인 성공",
  },
  _REGISTER: {
    _REGISTER: "계정 등록",
    _NAME: "사용자 이름",
    _EMAIL: "이메일",
    _PASSWORD: "비밀번호",
    _CONFIRMPASSWORD: "비밀번호 확인",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "텔레그램 (선택사항)",
    _HAVEACCOUNT: "이미 계정이 있습니다",
    _ERROR_REFERAL: "잘못된 추천 코드",
    _ERROR_EMAIL: "이미 존재하는 이메일입니다",
    _SUCCESS: "등록 성공",
  },
  _SIDEBAR: {
    _PAGES: "페이지",
    _OPEN: "사이드바 열기",
    _DASHBOARD: "홈",
    _NETWORK: "네트워크",
    _FINANCE: "지갑",
    _PACKAGES: "플랜",
    _SIGNOUT: "로그아웃",
    _LANGUAGE: "언어",
    _PROFILE: "프로필",
  },
  _DASHBOARD: {
    _TITLE: "잔액",
    _TITLE2: "예금",
    _TITLE3: "총 수익",
    _TITLE4: "유니레벨 수익",
    _TITLE5: "랭크 보너스",
    _TITLE6: "플랜",
    _TOTAL: "총합",
    _TABLE3: "패키지 목록",
    _START: "시작",
    _FINISH: "종료",
    _STATUS: "상태",
    _STATUS_ACTIVE: "활성",
    _STATUS_INACTIVE: "비활성",
    _CURRENT: "현재",
    _UPGRADE: "업그레이드",
    _COPYMESSAGE: "성공적으로 복사됨",
    _NOTIFICATION1: "새 예금",
    _NOTIFICATION2: "Cryptotradex 수익",
    _NOTIFICATION3: "유니레벨 수익",
    _NOTIFICATION4: "무제한 범위",
    _NOTIFICATION5: "패키지 구매",
    _NOTIFICATION7: "반복 직접 추천 보너스",
    _PREV: "이전",
    _NEXT: "다음",
    _TABLE4_A: "운영",
    _TABLE4_B: "수익",
    _TABLE4_C: "ROI",
    _TABLE4_D: "날짜",
    _SHARED: "추천 링크를 공유하세요",
  },
  _FINANCE: {
    _DEPOSIT: "예금",
    _QR: "다음 QR 코드를 스캔하거나 주소를 복사하세요",
    _NETWORK: "네트워크",
    _INSTRUCTIONS: "TRON (TRX)을 보내는 방법",
    _STEP1: "선택한 네트워크가 TRON (TRC20)인지 확인하세요.",
    _STEP2: "다음 지갑 주소로 TRON (TRX)을 보내세요.",
    _COPY: "복사",
    _DISCLAIMER:
      "면책 조항: TRON (TRC20) 네트워크 외부에서 이루어진 거래나 TRON (TRX) 외의 암호화폐 전송에 대해 우리는 책임지지 않습니다. 이러한 오류로 인한 자금 손실은 전적으로 송신자의 책임입니다.",
    _TABLE1: "예금 내역",
    _TABLE1_A: "주문",
    _TABLE1_B: "금액",
    _TABLE1_C: "날짜",
    _TABLE1_D: "상태",
    _SUCCESS: "패키지가 성공적으로 추가되었습니다",
    _COPYMESSAGE: "지갑 주소가 성공적으로 복사되었습니다",
  },
  _PACKAGES: {
    _ATTENTION:
      "주의: 당신은 기업 계정을 가지고 있습니다. 패키지를 구매하면 계정과 모든 혜택이 초기화됩니다.",
    _PACKAGES: "가장 적합한 플랜을 선택하세요",
    _SUBTITLE: "8가지 옵션이 있습니다",
    _BALANCE: "예금 잔액",
    _PACKAGE: "패키지",
    _PACKAGE_1: "당신은 레벨에서부터 수익을 얻게 됩니다",
    _PACKAGE_2: "최대 수익",
    _PACKAGE_3: "일일 ROI",
    _BUTTON1: "가득 참",
    _BUTTON2: "구매",
    _CALCULATE: "플랜 계산",
    _RECHARGE: "잔액이 부족합니다. 지갑을 충전하세요",
    _PACKAGEMESSAGE: "더 높은 패키지를 구매해야 합니다",
    _SUCCESS: "패키지가 성공적으로 추가되었습니다",
    _UPDATE: "패키지 업그레이드 가격",
  },
  _NETWORK: {
    _TODAY: "오늘",
    _AVAILABLE: "사용 가능",
    _WITHDRAW: "총 인출액",
    _SEARCHLABEL: "이메일 검색",
    _SEARCH: "검색",
    _NETWORK: "네트워크",
    _DIRECTS: "직접",
    _SUCCESS: "검색 성공",
    _ERROR: "당신의 이진 트리의 일부가 아닙니다.",
    _LEVEL: "레벨",
    _USERS: "사용자",
    _AMOUNT: "금액",
    _USERNAME: "사용자 이름",
    _EMAIL: "이메일",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "패키지",
  },
  _PROFILE: {
    _TITLE: "프로필",
    _NAME: "당신의 이름",
    _EMAIL: "당신의 이메일",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "현재 비밀번호",
    _PASSWORD: "새 비밀번호",
    _CONFIRMPASSWORD: "새 비밀번호 확인",
    _WALLET: "인출 주소",
    _ACTION: "수정",
    _TIPS: "팁",
    _TIPS_1: "이름은 읽기 쉬워야 하며 20자를 초과해서는 안됩니다.",
    _TIPS_2:
      "비밀번호는 숫자, 문자, 기호를 포함해야 하며, 복잡할수록 좋습니다.",
    _TIPS_3:
      "이메일은 변경할 수 없습니다. 변경이 필요하면 지원팀에 연락하세요.",
    _TIPS_4:
      "인출 주소는 이전에 활성화된 2단계 인증을 통해서만 수정할 수 있습니다.",
    _ERROR_PASSWORD: "이전 비밀번호 오류",
    _SUCCESS: "프로필이 성공적으로 업데이트되었습니다",
    _MIN: "최소 인출",
    _UPDATE: "프로필 업데이트",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "비밀번호를 잊으셨나요?",
    _EMAIL: "당신의 이메일",
    _BUTTON: "이메일 전송",
    _MESSAGE: "비밀번호가 이메일로 전송되었습니다",
    _ERROR_EMAIL: "이 이메일은 등록되지 않았습니다",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "현재 등급",
    _NEXT: "다음 등급",
    _SILVER: "실버",
    _GOLD: "골드",
    _RUBY: "루비",
    _EMERALD: "에메랄드",
    _DIAMOND: "다이아몬드",
    _DIAMONDB: "블루 다이아몬드",
    _DIAMONDBB: "블랙 다이아몬드",
    _NONE: "없음",
  },
  _AUTH: {
    _TITLEDEFAULT: "인출 지갑 변경",
    _TITLE: "1단계",
    _TITLE2: "2단계",
    _TITLE3: "3단계",
    _STEP1: "기기에 인증 앱을 다운로드하고 설치하세요",
    _STEP2: "코드를 스캔하여 저장하세요",
    _FORMEMAIL: "이메일 인증",
    _FORMHELPEMAIL: "보낸 6자리 인증 코드를 입력하세요",
    _FORMHELPAUTH: "인증 앱에서 생성된 6자리 코드를 입력하세요",
    _FORM1: "인증 앱 코드",
    _FORM2: "인출 주소",
    _FORMMIN: "인출 주소는 34자리여야 합니다",
    _FORMCODESEND: "코드 전송 완료",
    _VERIFF: "검증",
    _ACTION: "검증 및 저장",
    _ERROR: "오류가 발생했습니다. 다시 시도하세요.",
    _SUCCESS: "성공적으로 변경되었습니다",
    _RETRYOTP: "코드 전송 오류, 다시 시도하세요.",
    _SENDOTP: "코드가 성공적으로 전송되었습니다",
    _SUCCESSOTP: "OTP가 성공적으로 확인되었습니다",
    _SUCCESSAUTH2F: "2단계 인증이 성공적으로 확인되었습니다",
    _ERRORCODE: "잘못된 코드, 다시 시도하세요",
  },
  _COUNTDOWN: {
    _TITLE: "다음 컷오프:",
    _DAYS: "일",
    _HOURS: "시간",
    _MINUTES: "분",
    _SECONDS: "초",
  },
  _WITHDRAWAL: {
    _TITLE: "인출 거래",
  },
  _SYSTEM: {
    _SYSTEM: "시스템",
    _GLOBAL: "글로벌",
    _INVESTMENT: "투자 철회",
    _INVESTMENTTEXT: "투자의 35%가 벌금으로 부과된다는 점을 기억하세요",
    _ACCELERATE: "가속",
    _REPORT: "Cryptotradex 투자 보고서",
    _PARNERT: "스폰서 정보",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "투자 인출",
    _MESSAGE:
      "지금 자본을 인출하려면 35%의 운영 수수료가 부과됩니다 - 누적된 수익은 24시간 이내에 처리됩니다. 이렇게 하면 즉각적인 성장 잠재력을 멈출 것입니다. 장기적인 시각으로 결정하세요!",
    _BUTTON: "인출",
    _BUTTONWAIT: "인출 진행 중",
  },
  _SIGNAL: {
    _NEWS: "새로운",
    _SIGNALS: "신호",
    _WHATIS: "Cryptotradex Signal이란 무엇인가요?",
    _DESCRIPTION:
      "우리의 독점적인 신호 그룹에 가입하여 매일 10개 이상의 추천을 받아 수익을 극대화하세요. 높은 위험과 낮은 위험 투자에서 최대 200%의 수익을 얻을 수 있습니다.",
    _COMMING: "곧 출시 예정.",
    _SIGNALS10: "SIGNALS10X",
    _CHAT: "독점 채팅",
    _DESCRIPTIONGLANDING:
      "우리의 독점 채팅에서 실시간 신호를 받으며 각 신호에 대해 질문하거나 기여할 수 있습니다.",
    _TESTIMONIALS: "사용자 후기",
    _GETDAILY: "CTX의 일일 신호를 받고 자본을 증대시키기 시작하세요!",
    _TOBE:
      "Cryptotradex Signals의 일원이 되려면 최소 1,000 USD 이상의 투자가 필요합니다.",
    _IWANT: "신호를 원합니다",
    _TESTIMONIALS1:
      "Solana 네트워크에서 이 신호를 사용하기 시작했고, 몇 시간 만에 초기 200 USD를 600 USD로 만들었습니다. Dexscreener에서 제공한 정보가 결정적인 차이를 만들었습니다!",
    _TESTIMONIALS2:
      "실시간 신호를 통해 매주 초기 투자를 계속해서 증가시켰습니다. Solana 데이터와 Dexscreener의 조합 덕분에 5주 만에 3,000 USD 이상을 벌었습니다!",
    _TESTIMONIALS3:
      "이 신호 뒤의 커뮤니티는 훌륭합니다. Solana 보유량을 크게 증가시켰을 뿐만 아니라, 거래마다 나를 안내해주는 훌륭한 지원 그룹도 찾았습니다.",
    _TESTIMONIALS4:
      "처음에는 회의적이었지만, Solana 기반 기회와 Dexscreener 정보 간의 시너지가 내가 틀렸음을 증명했습니다. 내 포트폴리오는 내가 상상했던 것 이상으로 성장했습니다!",
  },
  _ACADEMY: {
    _NEWS: "새로운",
    _TITLE: "아카데미",
    _WHATIS: "Cryptotradex Academy란 무엇인가요?",
    _DESCRIPTION:
      "모든 수준을 위한 온라인 플랫폼을 통해 암호화폐, 지갑, 밈 및 Web3에 대해 배우세요.",
    _ACTION1: "아카데미로 이동",
    _DISABLED: "최소 500 USD의 입금이 필요합니다",
    _TITLANDING: "Cryptomemes 전문가 되기",
    _DESCRIPTIONLANDING:
      "BRETT 패키지에서 독점적인 튜토리얼에 접근하고 MEMECOINS의 세계를 마스터하세요.",
    _ACTIONLANDING: "지금 등록하고 배우기 시작하세요!",
    _WHATCOURSES: "어떤 강의를 들을 수 있나요?",
    _RECOMMENDS: "추천 비디오",
    _VIDEO1: "Phantom 지갑 튜토리얼",
    _VIDEO2: "DEXSCREENER에서 폭발적인 MEMECOINS 찾기",
    _VIDEO3: "GMGN을 전문가처럼 마스터하기",
    _VIDEO4: "완벽한 MEMECOINS 봇",
    _VIDEO5: "큰 잠재력을 가진 MEMECOINS 찾는 방법",
  },
  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
