export const ro = {
  _LOGIN: {
    _EMAIL: "Email",
    _PASSWORD: "Parolă",
    _REMEMBER: "Ține-mă minte",
    _LOGIN: "AUTENTIFICARE",
    _SIGNUP: "Înregistrare",
    _FORGOTPASSWORD: "Ai uitat parola?",
    _NOACCOUNT: "Nu ai un cont?",
    _ERROR_EMAIL: "Se pare că nu ești înregistrat! Încearcă din nou.",
    _ERROR_PASSWORD: "Parolă incorectă",
    _SUCCESS: "Autentificare reușită",
  },
  _REGISTER: {
    _REGISTER: "Creează un cont",
    _NAME: "Nume utilizator",
    _EMAIL: "Email-ul tău",
    _PASSWORD: "Parola ta",
    _CONFIRMPASSWORD: "Confirmă parola",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (opțional)",
    _HAVEACCOUNT: "Am deja un cont",
    _ERROR_REFERAL: "Cod de referință incorect",
    _ERROR_EMAIL: "Acest email există deja",
    _SUCCESS: "Înregistrare reușită",
  },
  _SIDEBAR: {
    _PAGES: "Pagini",
    _OPEN: "Deschide bara laterală",
    _DASHBOARD: "Acasă",
    _NETWORK: "Rețea",
    _FINANCE: "Portofel",
    _PACKAGES: "Planuri",
    _SIGNOUT: "Deconectare",
    _LANGUAGE: "Limbă",
    _PROFILE: "Profil",
  },
  _DASHBOARD: {
    _TITLE: "Sold",
    _TITLE2: "Depozit",
    _TITLE3: "Câștiguri totale",
    _TITLE4: "Câștiguri Unilevel",
    _TITLE5: "Bonus de rang",
    _TITLE6: "Plan",
    _TOTAL: "Total",
    _TABLE3: "Lista pachetelor",
    _START: "Început",
    _FINISH: "Final",
    _STATUS: "Stare",
    _STATUS_ACTIVE: "Activ",
    _STATUS_INACTIVE: "Inactiv",
    _CURRENT: "Curent",
    _UPGRADE: "Upgrade",
    _COPYMESSAGE: "Copiere reușită",
    _NOTIFICATION1: "Depozit nou",
    _NOTIFICATION2: "Profit Cryptotradex",
    _NOTIFICATION3: "Profit Unilevel",
    _NOTIFICATION4: "Gamă infinită",
    _NOTIFICATION5: "Cumpără pachet",
    _NOTIFICATION7: "Bonus Recurring de Referință Directă",
    _PREV: "Anterior",
    _NEXT: "Următor",
    _TABLE4_A: "Operațiune",
    _TABLE4_B: "Profit",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Dată",
    _SHARED: "Distribuie linkul tău de referință",
  },
  _FINANCE: {
    _DEPOSIT: "Depozit",
    _QR: "Scanează codul QR de mai jos sau copiază adresa",
    _NETWORK: "Rețea",
    _INSTRUCTIONS: "Instrucțiuni pentru trimiterea TRON (TRX)",
    _STEP1: "Asigură-te că rețeaua selectată este TRON (TRC20).",
    _STEP2: "Trimite suma de TRON (TRX) la adresa următoare.",
    _COPY: "Copiază",
    _DISCLAIMER:
      "Declinarea răspunderii: Nu suntem responsabili pentru tranzacțiile efectuate în afara rețelei TRON (TRC20) sau pentru trimiterea altor criptomonede în afară de TRON (TRX). Orice pierdere de fonduri din cauza acestor erori va fi responsabilitatea exclusivă a expeditorului.",
    _TABLE1: "Istoric depozite",
    _TABLE1_A: "Comandă",
    _TABLE1_B: "Sumă",
    _TABLE1_C: "Dată",
    _TABLE1_D: "Stare",
    _SUCCESS: "Pachetul a fost adăugat cu succes",
    _COPYMESSAGE: "Adresa portofelului a fost copiată cu succes",
  },
  _PACKAGES: {
    _ATTENTION:
      "Atenție: Ai un cont corporativ. Cumpărând un pachet, vei reseta contul și toate beneficiile acestuia.",
    _PACKAGES: "Selectează cel mai bun plan pentru tine",
    _SUBTITLE: "Ai 8 opțiuni",
    _BALANCE: "Sold depozit",
    _PACKAGE: "Pachet",
    _PACKAGE_1: "Vei câștiga începând cu nivelul",
    _PACKAGE_2: "Câștigă până la",
    _PACKAGE_3: "ROI zilnic",
    _BUTTON1: "Completat",
    _BUTTON2: "Cumpără",
    _CALCULATE: "Calculează planul",
    _RECHARGE: "Nu ai suficienți bani, încarcă portofelul",
    _PACKAGEMESSAGE: "Trebuie să cumperi un pachet superior",
    _SUCCESS: "Pachetul a fost adăugat cu succes",
    _UPDATE: "Preț de actualizare pachet",
  },
  _NETWORK: {
    _TODAY: "Astăzi",
    _AVAILABLE: "Disponibil",
    _WITHDRAW: "Total retras",
    _SEARCHLABEL: "Căutare email",
    _SEARCH: "Caută",
    _NETWORK: "Rețea",
    _DIRECTS: "Direct",
    _SUCCESS: "Căutare reușită",
    _ERROR: "Nu face parte din arborele tău binar.",
    _LEVEL: "Nivel",
    _USERS: "Utilizatori",
    _AMOUNT: "Sumă",
    _USERNAME: "Nume utilizator",
    _EMAIL: "Email",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Pachet",
  },
  _PROFILE: {
    _TITLE: "Profil",
    _NAME: "Numele tău",
    _EMAIL: "Email-ul tău",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Parolă actuală",
    _PASSWORD: "Parolă nouă",
    _CONFIRMPASSWORD: "Confirmă parola nouă",
    _WALLET: "Adresă de retragere",
    _ACTION: "Editează",
    _TIPS: "Sfaturi",
    _TIPS_1:
      "Numele trebuie să fie lizibil și să nu depășească 20 de caractere.",
    _TIPS_2:
      "Parola trebuie să includă cifre, litere și simboluri; cu cât este mai complexă, cu atât mai bine.",
    _TIPS_3:
      "Email-ul nu poate fi schimbat. Dacă trebuie să faci o modificare, te rugăm să contactezi suportul.",
    _TIPS_4:
      "Adresa de retragere poate fi modificată doar cu un autentificator cu 2 factori, care trebuie activat anterior.",
    _ERROR_PASSWORD: "Eroare parolă anterioară",
    _SUCCESS: "Profil actualizat cu succes",
    _MIN: "Retragere minimă",
    _UPDATE: "Actualizează profilul",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Ai uitat parola?",
    _EMAIL: "Email-ul tău",
    _BUTTON: "Trimite email",
    _MESSAGE: "Parola ta a fost trimisă pe email",
    _ERROR_EMAIL: "Acest email nu este înregistrat",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Rang curent",
    _NEXT: "Rang următor",
    _SILVER: "Argint",
    _GOLD: "Aur",
    _RUBY: "Rubin",
    _EMERALD: "Smarald",
    _DIAMOND: "Diamant",
    _DIAMONDB: "Diamant Albastru",
    _DIAMONDBB: "Diamant Negru",
    _NONE: "Niciunul",
  },
  _AUTH: {
    _TITLEDEFAULT: "Schimbă portofelul de retragere",
    _TITLE: "Pasul 1",
    _TITLE2: "Pasul 2",
    _TITLE3: "Pasul 3",
    _STEP1: "Descarcă și instalează autentificatorul pe dispozitivul tău",
    _STEP2: "Scanează codul pentru a salva",
    _FORMEMAIL: "Verificare email",
    _FORMHELPEMAIL: "Introdu codul de verificare din 6 cifre trimis la",
    _FORMHELPAUTH:
      "Introdu codul din 6 cifre generat de aplicația Authenticator",
    _FORM1: "Pin prin Authenticator",
    _FORM2: "Adresă de retragere",
    _FORMMIN: "Adresa de retragere trebuie să aibă 34 de caractere",
    _FORMCODESEND: "Cod trimis",
    _VERIFF: "Verificare",
    _ACTION: "Verifică și salvează",
    _ERROR: "Eroare, încearcă din nou.",
    _SUCCESS: "Schimbare reușită",
    _RETRYOTP: "Eroare la trimiterea codului, încearcă din nou.",
    _SENDOTP: "Cod trimis cu succes",
    _SUCCESSOTP: "OTP verificat cu succes",
    _SUCCESSAUTH2F: "Autentificarea cu 2 factori verificată cu succes",
    _ERRORCODE: "Cod incorect, încearcă din nou",
  },
  _COUNTDOWN: {
    _TITLE: "Următorul termen:",
    _DAYS: "Zile",
    _HOURS: "Ore",
    _MINUTES: "Minute",
    _SECONDS: "Secunde",
  },
  _WITHDRAWAL: {
    _TITLE: "Tranzacții de retragere",
  },
  _SYSTEM: {
    _SYSTEM: "Sistem",
    _GLOBAL: "Global",
    _INVESTMENT: "Retrage investiția",
    _INVESTMENTTEXT:
      "Rețineți că veți avea o penalizare de 35% din investiția dvs.",
    _ACCELERATE: "Accelerează",
    _REPORT: "Raport de investiții Cryptotradex",
    _PARNERT: "Informații despre sponsor",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Retrage Investiția",
    _MESSAGE:
      "Retragerea capitalului acum implică un comision operațional de 35% - câștiguri acumulate și va fi procesat în 24 de ore. Dacă faci acest lucru, vei opri potențialul de creștere imediată. Decizi cu viziune pe termen lung!",
    _BUTTON: "Retrage",
    _BUTTONWAIT: "Retragere în proces",
  },
  _SIGNAL: {
    _NEWS: "Nou",
    _SIGNALS: "Semnale",
    _WHATIS: "Ce este Cryptotradex Signal?",
    _DESCRIPTION:
      "Alătură-te grupului nostru exclusiv de semnale și primește peste 10 recomandări zilnice pentru a-ți maximiza câștigurile, cu randamente de până la 200% în investiții cu risc mare și mic.",
    _COMMING: "În curând.",
    _SIGNALS10: "SEMNALE10X",
    _CHAT: "Chat exclusiv",
    _DESCRIPTIONGLANDING:
      "În chat-ul nostru exclusiv, vei primi semnale în timp real și vei putea contribui sau pune întrebări despre fiecare semnal într-un mod organizat.",
    _TESTIMONIALS: "TESTIMONIALE",
    _GETDAILY:
      "Primește semnale zilnice de la CTX și începe să îți multipli capitalul!",
    _TOBE:
      "Pentru a face parte din Cryptotradex Signals, trebuie să ai o investiție de cel puțin 1.000 USD.",
    _IWANT: "Vreau semnalele",
    _TESTIMONIALS1:
      "Am început să folosesc aceste semnale pe rețeaua Solana și am reușit să transform cei 200 USD inițiali în 600 USD în doar câteva ore. Informațiile oferite prin Dexscreener au făcut toată diferența!",
    _TESTIMONIALS2:
      "Cu aceste semnale în timp real, am multiplicat constant investiția inițială săptămână după săptămână. Combinația de date Solana și Dexscreener m-a ajutat să câștig mai mult de 3.000 USD în doar 5 săptămâni!",
    _TESTIMONIALS3:
      "Comunitatea din spatele acestor semnale este uimitoare. Nu doar că mi-am crescut semnificativ deținerile de Solana, dar am găsit și un grup de susținere grozav care m-a ghidat în fiecare tranzacție.",
    _TESTIMONIALS4:
      "La început eram sceptic, dar sinergia dintre oportunitățile bazate pe Solana și informațiile de la Dexscreener mi-au arătat că mă înșelam. Portofoliul meu a crescut mult mai mult decât mi-am imaginat!",
  },
  _ACADEMY: {
    _NEWS: "Nou",
    _TITLE: "Academia",
    _WHATIS: "Ce este Cryptotradex Academy?",
    _DESCRIPTION:
      "Învață despre criptomonede, portofele, meme și Web3 cu platforma noastră online concepută pentru toate nivelurile.",
    _ACTION1: "Mergi la Academie",
    _DISABLED: "Este necesar un depozit minim de 500 USD",
    _TITLANDING: "Devino un expert în Cryptomemes",
    _DESCRIPTIONLANDING:
      "Accesează tutoriale exclusive din pachetul BRETT și învață să stăpânești lumea MEMECOINS.",
    _ACTIONLANDING: "Înscrie-te acum și începe să înveți!",
    _WHATCOURSES: "Ce cursuri poți obține?",
    _RECOMMENDS: "Videoclipuri recomandate",
    _VIDEO1: "Tutorial portofel Phantom",
    _VIDEO2: "Găsește MEMECOINS explozivi pe DEXSCREENER",
    _VIDEO3: "Stăpânește GMGN ca un expert",
    _VIDEO4: "Botul perfect pentru MEMECOINS",
    _VIDEO5: "Cum să găsești MEMECOINS cu mare potențial",
  },
  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
