import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import AppContext from "../context/AppContext";
import Logo from "../assets/svg/academy/logo.svg";
import Hero from "../assets/svg/academy/hero.png";
import Business from "../assets/svg/academy/business.svg";
import Crypto from "../assets/svg/academy/crypto.svg";
import Camera from "../assets/svg/academy/camera.svg";
import Design from "../assets/svg/academy/design.svg";
import Animation from "../assets/svg/academy/animation.svg";

export default function Academy() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const { language, showLanguage } = useContext(AppContext);
    const CardJson = [
        {
            icon: Animation,
            title: "Domina la billetera más popular.",
            desc: "Configura y usa Phantom Wallet para manejar tus MEMECOINS de manera segura y efectiva.",
        },
        {
            icon: Design,
            title: "Hazlo como un experto.",
            desc: "Te enseñamos a encontrar las MEMECOINS más prometedoras usando DEXSCREENER.",
        },
        {
            icon: Camera,
            title: "Sé un experto en GMGN.",
            desc: "EAprende todo sobre esta criptomoneda y cómo aprovechar su potencial.",
        },
        {
            icon: Crypto,
            title: "Automatiza tus inversiones.",
            desc: "Conoce cómo configurar el bot ideal para MEMECOINS y optimiza tu tiempo y rentabilidad.",
        },
        {
            icon: Business,
            title: "Descubre las mejores oportunidades.",
            desc: "Aprende a leer el mercado y hacer análisis técnicos para encontrar MEMECOINS prometedoras.",
        },
    ];

    return (
        <div className="h-full w-full bg-black text-white bg-no-repeat overflow-hidden">
            <div className="px-5 xl:px-0 lg:max-w-7xl w-full mx-auto relative">
                <div className="flex justify-between pt-5 max-w-2xl lg:max-w-[850px] xl:max-w-5xl ">
                    <div className="flex items-center cursor-pointer ">
                        <img src={Logo} alt="Logo" className='w-[40%] cursor-pointer z-10 ' onClick={() => navigate("/")} />
                    </div>
                    <div>
                        <button onClick={() => showLanguage(!language)} className="z-10 bg-[color:#7EFF00] hover:bg-[color:#9AFF40]  group relative overflow-hidden py-2 px-4 rounded rounded-full" >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-9 h-9 p-1.5  transition duration-75 text-black cursor-pointer" >
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="absolute inset-0 flex justify-center items-center -top-[50%] z-1">
                    <span className="[color:transparent] [-webkit-text-stroke:2px_#FFFFFF33] [-webkit-text-fill-color:transparent]  text-[18vw] uppercase">
                        {t("_ACADEMY._TITLE")}
                    </span>
                </div>
                <div className="flex justify-start items-end my-5 xl:my-20">
                    <div className="md:max-w-[400px] flex flex-col gap-6 my-14">
                        <div>
                            <h1 className="text-[color:#51A013]  xl:text-[45px] text-[40px] font-extrabold leading-[50px]">
                                {t("_ACADEMY._TITLANDING")}
                            </h1>
                        </div>
                        <div>
                            <p className="text-[#83869a]">
                                {t("_ACADEMY._DESCRIPTIONLANDING")}
                            </p>
                        </div>
                        <div>
                            <button className="py-3 px-7 text-white font-bold bg-gradient-to-r from-[color:#8fba10] to-green-800 rounded-3xl group relative overflow-hidden" onClick={() => navigate("/")}>
                                {t("_ACADEMY._ACTIONLANDING")}
                                <div className="absolute duration-300 inset-0 w-full h-full transition-all scale-0 group-hover:scale-100 group-hover:bg-white/30 rounded-2xl"></div>
                            </button>
                        </div>
                    </div>
                    <div className="absolute left-1/2 -translate-x-1/2 md:translate-x-0 md:-right-32 md:left-auto xl:-right-80 xl:-top-20 md:-top-5 lg:-right-28 lg:-top-14 lg:block hidden">
                        <img
                            src={Hero}
                            alt=""
                            className="relative md:max-w-[500px] lg:max-w-[600px] xl:max-w-[650px] hidden md:block"
                        />
                        <img
                            src={Hero}
                            alt="mobileback"
                            className="relative -bottom-72 md:hidden block max-w-[343px] sm:max-w-[400px]"
                        />
                    </div>
                </div>
                <div className="relative pt-56 md:pt-0 grid sm:grid-cols-2 lg:grid-cols-3 gap-24 my-24 lg:my-32 place-items-center sm:place-items-stretch">
                    <div className="hover:drop-shadow-2xl w-full bg-gradient-to-r from-[color:#8fba10] to-green-800 sm:max-w-xs rounded-xl px-10 py-5 text-white text-xl font-extrabold leading-8 shadow-xl">
                        {t("_ACADEMY._WHATCOURSES")}
                    </div>

                    {CardJson.map((data, index) => {
                        return (
                            <div
                                key={index}
                                className="shadow-xl hover:drop-shadow-2xl relative bg-white sm:max-w-xs rounded-xl px-5 py-5 flex flex-col gap-4"
                            >
                                <img className="absolute -top-12" src={data.icon} alt="icon" />
                                <h1 className="text-[#13183f] font-extrabold text-xl">
                                    {data.title}
                                </h1>
                                <p className="text-[#83869a] min-h-[80px]">{data.desc}</p>
                            </div>
                        );
                    })}
                    <div className="absolute inset-0 flex justify-center items-center ">
                        <span className="[color:transparent] [-webkit-text-stroke:2px_#FFFFFF33] [-webkit-text-fill-color:transparent] text-[18vw] uppercase">
                            {t("_ACADEMY._TITLE")}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
