import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import One from "../assets/svg/academy/1.png";
import Two from "../assets/svg/academy/2.png";
import Three from "../assets/svg/academy/3.png";
import Four from "../assets/svg/academy/4.png";
import Five from "../assets/svg/academy/5.png";
import { useTranslation } from "react-i18next";
import AppContext from "../context/AppContext";
import { useNavigate } from 'react-router-dom';
import Countdown from "../components/Countdown"

const MyAcademy = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentVideo, setCurrentVideo] = useState(0); // Estado para el video actual
  const { loading, updateLoading } = useContext(AppContext);

  useEffect(() => {
    getUsersData();
  }, []);

  const getUsersData = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/users`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const data = response?.data;
        if (parseInt(data.total_deposit) < 1990) {
          // navigate("/");
        }
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }
  // Array de videos con HTML, título, miniatura, y Script
  const videos = [
    {
      id: "676729d0d30d7bd0bf729f9d",
      title: t("_ACADEMY._VIDEO1"),
      thumbnail: One,
      HTMLVturb: `<div id="vid_676729d0d30d7bd0bf729f9d" style="position: relative; width: 100%; padding: 56.25% 0 0;">
                    <img id="thumb_676729d0d30d7bd0bf729f9d" src="https://images.converteai.net/46e33e96-f683-4e2a-8314-ead0488a0a35/players/676729d0d30d7bd0bf729f9d/thumbnail.jpg"
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; display: block;" alt="thumbnail">
                    <div id="backdrop_676729d0d30d7bd0bf729f9d" style=" -webkit-backdrop-filter: blur(5px); backdrop-filter: blur(5px); position: absolute; top: 0; height: 100%; width: 100%;"></div>
                  </div>`,
      SCRIPTVturb: `var s=document.createElement("script"); s.src="https://scripts.converteai.net/46e33e96-f683-4e2a-8314-ead0488a0a35/players/676729d0d30d7bd0bf729f9d/player.js", s.async=!0,document.head.appendChild(s);`,
    },
    {
      id: "67673a361ba5525c8c3656a2",
      title: t("_ACADEMY._VIDEO2"),
      thumbnail: Two,
      HTMLVturb: `<div id="vid_67673a361ba5525c8c3656a2" style="position: relative; width: 100%; padding: 56.25% 0 0;">
                    <img id="thumb_67673a361ba5525c8c3656a2" src="https://images.converteai.net/46e33e96-f683-4e2a-8314-ead0488a0a35/players/67673a361ba5525c8c3656a2/thumbnail.jpg"
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; display: block;" alt="thumbnail">
                    <div id="backdrop_67673a361ba5525c8c3656a2" style=" -webkit-backdrop-filter: blur(5px); backdrop-filter: blur(5px); position: absolute; top: 0; height: 100%; width: 100%;"></div>
                  </div>`,
      SCRIPTVturb: `var s=document.createElement("script"); s.src="https://scripts.converteai.net/46e33e96-f683-4e2a-8314-ead0488a0a35/players/67673a361ba5525c8c3656a2/player.js", s.async=!0,document.head.appendChild(s);`,
    },
    {
      id: "6767498ec4c52a496f6b0b48",
      title: t("_ACADEMY._VIDEO3"),
      thumbnail: Three,
      HTMLVturb: `<div id="vid_6767498ec4c52a496f6b0b48" style="position: relative; width: 100%; padding: 56.25% 0 0;">
                    <img id="thumb_6767498ec4c52a496f6b0b48" src="https://images.converteai.net/46e33e96-f683-4e2a-8314-ead0488a0a35/players/6767498ec4c52a496f6b0b48/thumbnail.jpg"
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; display: block;" alt="thumbnail">
                    <div id="backdrop_6767498ec4c52a496f6b0b48" style=" -webkit-backdrop-filter: blur(5px); backdrop-filter: blur(5px); position: absolute; top: 0; height: 100%; width: 100%;"></div>
                  </div>`,
      SCRIPTVturb: `var s=document.createElement("script"); s.src="https://scripts.converteai.net/46e33e96-f683-4e2a-8314-ead0488a0a35/players/6767498ec4c52a496f6b0b48/player.js", s.async=!0,document.head.appendChild(s);`,
    },
    {
      id: "6768626ec4c52a496f6b4110",
      title: t("_ACADEMY._VIDEO4"),
      thumbnail: Four,
      HTMLVturb: `<div id="vid_6768626ec4c52a496f6b4110" style="position: relative; width: 100%; padding: 56.25% 0 0;">
                    <img id="thumb_6768626ec4c52a496f6b4110" src="https://images.converteai.net/46e33e96-f683-4e2a-8314-ead0488a0a35/players/6768626ec4c52a496f6b4110/thumbnail.jpg"
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; display: block;" alt="thumbnail">
                    <div id="backdrop_6768626ec4c52a496f6b4110" style=" -webkit-backdrop-filter: blur(5px); backdrop-filter: blur(5px); position: absolute; top: 0; height: 100%; width: 100%;"></div>
                  </div>`,
      SCRIPTVturb: `var s=document.createElement("script"); s.src="https://scripts.converteai.net/46e33e96-f683-4e2a-8314-ead0488a0a35/players/6768626ec4c52a496f6b4110/player.js", s.async=!0,document.head.appendChild(s);`,
    },
    {
      id: "67686969bc3daf367a820a7d",
      title: t("_ACADEMY._VIDEO5"),
      thumbnail: Five,
      HTMLVturb: `<div id="vid_67686969bc3daf367a820a7d" style="position: relative; width: 100%; padding: 56.25% 0 0;">
                    <img id="thumb_67686969bc3daf367a820a7d" src="https://images.converteai.net/46e33e96-f683-4e2a-8314-ead0488a0a35/players/67686969bc3daf367a820a7d/thumbnail.jpg"
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; display: block;" alt="thumbnail">
                    <div id="backdrop_67686969bc3daf367a820a7d" style=" -webkit-backdrop-filter: blur(5px); backdrop-filter: blur(5px); position: absolute; top: 0; height: 100%; width: 100%;"></div>
                  </div>`,
      SCRIPTVturb: `var s=document.createElement("script"); s.src="https://scripts.converteai.net/46e33e96-f683-4e2a-8314-ead0488a0a35/players/67686969bc3daf367a820a7d/player.js", s.async=!0,document.head.appendChild(s);`,
    }
  ];

  return (
    <>
      <p className="text-2xl text-center text-lime-300 my-2">{t("_FREE._FREEFOR")}</p>
      <Countdown targetDate={"2025-01-30"} />
      <div className="mx-auto lg:p-4 flex gap-4">

        <div className="w-3/4 bg-zinc-800 p-6 rounded-lg shadow flex flex-col text-left h-auto relative border-lime-600 hover:border-lime-300 border-2">
          {/* Video Player */}
          <div className="flex flex-col flex-auto h-full">
            <div dangerouslySetInnerHTML={{ __html: videos[currentVideo].HTMLVturb }} />
            <Helmet>
              <script>{videos[currentVideo].SCRIPTVturb}</script>
            </Helmet>
          </div>
        </div>

        {/* Sidebar - similar to YouTube */}
        <div className="w-1/4 bg-zinc-800 p-6 rounded-lg text-white">
          <h2 className="text-lg font-semibold mb-2">{t("_ACADEMY._RECOMMENDS")}</h2>
          <div className="space-y-4">
            {videos.map((video, index) => (
              <div
                key={index}
                className="flex items-center space-x-2 hover:bg-gray-700 p-2 rounded-lg cursor-pointer"
                onClick={() => setCurrentVideo(index)}
              >
                <img
                  src={video.thumbnail}
                  alt={video.title}
                  className="w-20 h-20 object-cover rounded-lg"
                />
                <div>
                  <h3 className="text-sm font-semibold">{video.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAcademy;
