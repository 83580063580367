export const mk = {
  _LOGIN: {
    _EMAIL: "Е-пошта",
    _PASSWORD: "Лозинка",
    _REMEMBER: "Запомни ме",
    _LOGIN: "ВЛЕЗ",
    _SIGNUP: "Регистрирај се",
    _FORGOTPASSWORD: "Заборавивте ја лозинката?",
    _NOACCOUNT: "Немате акаунт?",
    _ERROR_EMAIL: "Очигледно не сте регистрирани! Обидете се повторно.",
    _ERROR_PASSWORD: "Невалидна лозинка",
    _SUCCESS: "Успешно пријавување",
  },
  _REGISTER: {
    _REGISTER: "Регистрирајте акаунт",
    _NAME: "Корисничко име",
    _EMAIL: "Вашата е-пошта",
    _PASSWORD: "Вашата лозинка",
    _CONFIRMPASSWORD: "Потврди лозинка",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (опционално)",
    _HAVEACCOUNT: "Имам акаунт",
    _ERROR_REFERAL: "Невалиден реферален код",
    _ERROR_EMAIL: "Оваа е-пошта веќе постои",
    _SUCCESS: "Успешна регистрација",
  },
  _SIDEBAR: {
    _PAGES: "Страници",
    _OPEN: "Отвори странична лента",
    _DASHBOARD: "Дома",
    _NETWORK: "Мрежа",
    _FINANCE: "Паричник",
    _PACKAGES: "Планови",
    _SIGNOUT: "Одјави се",
    _LANGUAGE: "Јазик",
    _PROFILE: "Профил",
  },
  _DASHBOARD: {
    _TITLE: "Баланс",
    _TITLE2: "Депозит",
    _TITLE3: "Вкупни заработувања",
    _TITLE4: "Универзални заработувања",
    _TITLE5: "Ранги бонус",
    _TITLE6: "План",
    _TOTAL: "Вкупно",
    _TABLE3: "Листа на пакети",
    _START: "Почеток",
    _FINISH: "Завршеток",
    _STATUS: "Статус",
    _STATUS_ACTIVE: "Активен",
    _STATUS_INACTIVE: "Неактивен",
    _CURRENT: "Тековен",
    _UPGRADE: "Подобри",
    _COPYMESSAGE: "Успешно копирано",
    _NOTIFICATION1: "Нов депозит",
    _NOTIFICATION2: "Cryptotradex профит",
    _NOTIFICATION3: "Универзален профит",
    _NOTIFICATION4: "Опсег бесконечен",
    _NOTIFICATION5: "Купи пакет",
    _NOTIFICATION7: "Повторувачки директен бонус за препорака",
    _PREV: "Претходно",
    _NEXT: "Следно",
    _TABLE4_A: "Операција",
    _TABLE4_B: "Профит",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Датум",
    _SHARED: "Споделете ја вашата реферална врска",
  },
  _FINANCE: {
    _DEPOSIT: "Депозит",
    _QR: "Скенирајте го следниот QR или копирајте ја адресата",
    _NETWORK: "Мрежа",
    _INSTRUCTIONS: "Упатства за испраќање TRON (TRX)",
    _STEP1: "Убедете се дека избраната мрежа е TRON (TRC20).",
    _STEP2:
      "Испратете го износот на TRON (TRX) на следната адреса на паричникот.",
    _COPY: "Копирај",
    _DISCLAIMER:
      "Одговорност: Ние не сме одговорни за трансакции направени надвор од TRON (TRC20) мрежата или за испраќање криптовалути освен TRON (TRX). Секое губење на средства поради овие грешки ќе биде целосна одговорност на испраќачот.",
    _TABLE1: "Историја на депозити",
    _TABLE1_A: "Нарачка",
    _TABLE1_B: "Износ",
    _TABLE1_C: "Датум",
    _TABLE1_D: "Статус",
    _SUCCESS: "Пакетот беше успешно додаден",
    _COPYMESSAGE: "Адресата на паричникот успешно копирана",
  },
  _PACKAGES: {
    _ATTENTION:
      "Внимание: Имате корпоративен акаунт. Со купување пакет, ќе го рестартирате акаунтот и сите негови предности.",
    _PACKAGES: "Изберете го најдобриот план за вас",
    _SUBTITLE: "Имате 8 опции",
    _BALANCE: "Баланс Депозит",
    _PACKAGE: "Пакет ",
    _PACKAGE_1: "Ќе заработите до ниво ",
    _PACKAGE_2: "Заработете до ",
    _PACKAGE_3: "Дневен ROI",
    _BUTTON1: "Исполнето",
    _BUTTON2: "Купи",
    _CALCULATE: "Пресметај план",
    _RECHARGE: "Немате доволно средства, наплатете го вашиот паричник",
    _PACKAGEMESSAGE: "Мора да купите повисок пакет",
    _SUCCESS: "Пакетот беше успешно додаден",
    _UPDATE: "Цена за надградба на пакетот",
  },
  _NETWORK: {
    _TODAY: "Денес",
    _AVAILABLE: "Достапно",
    _WITHDRAW: "Вкупно повлечено",
    _SEARCHLABEL: "Пребарај е-пошта",
    _SEARCH: "Пребарај",
    _NETWORK: "Мрежа",
    _DIRECTS: "Директни",
    _SUCCESS: "Успешно пребарување",
    _ERROR: "Не е дел од вашето бинарно дрво.",
    _LEVEL: "Ниво",
    _USERS: "Корисници",
    _AMOUNT: "Износ",
    _USERNAME: "Корисничко име",
    _EMAIL: "Е-пошта",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Пакет",
  },
  _PROFILE: {
    _TITLE: "Профил",
    _NAME: "Вашето име",
    _EMAIL: "Вашата е-пошта",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Тековна лозинка",
    _PASSWORD: "Нова лозинка",
    _CONFIRMPASSWORD: "Потврди нова лозинка",
    _WALLET: "Адреса за повлекување",
    _ACTION: "Уреди",
    _TIPS: "Совети",
    _TIPS_1: "Името мора да биде читливо и да не надминува 20 знаци.",
    _TIPS_2:
      "Лозинката мора да вклучува броеви, букви и симболи; колку е потешка, толку е подобро.",
    _TIPS_3:
      "Е-поштата не може да се промени. Ако треба да направите промена, ве молиме пишете до поддршката.",
    _TIPS_4:
      "Адресата за повлекување може да се модифицира само со двофакторен аутентификатор, кој мора да биде претходно активиран.",
    _ERROR_PASSWORD: "Грешка во претходната лозинка",
    _SUCCESS: "Профилот беше успешно ажуриран",
    _MIN: "Минимално повлекување",
    _UPDATE: "Ажурирај профил",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Заборавивте ја лозинката?",
    _EMAIL: "Вашата е-пошта",
    _BUTTON: "Испрати е-пошта",
    _MESSAGE: "Вашата лозинка беше испратена на вашата е-пошта",
    _ERROR_EMAIL: "Оваа е-пошта не е регистрирана",
  },
  _GLOBALV: {
    _TITLE: "ГВ",
    _CURRENT: "Тековен ранг",
    _NEXT: "Следен ранг",
    _SILVER: "Сребро",
    _GOLD: "Злато",
    _RUBY: "Рубин",
    _EMERALD: "Изумруд",
    _DIAMOND: "Дијамант",
    _DIAMONDB: "Дијамант Блу",
    _DIAMONDBB: "Дијамант Црн",
    _NONE: "Нема",
  },
  _AUTH: {
    _TITLEDEFAULT: "Промена на адресата за повлекување",
    _TITLE: "Чекор 1",
    _TITLE2: "Чекор 2",
    _TITLE3: "Чекор 3",
    _STEP1: "Преземете и инсталирајте го аутентификаторот на вашиот уред",
    _STEP2: "Скенирајте го кодот за да го зачувате",
    _FORMEMAIL: "Верификација на е-пошта",
    _FORMHELPEMAIL: "Внесете го 6-цифрениот код за верификација испратен на",
    _FORMHELPAUTH:
      "Внесете го 6-цифрениот код генериран од Аутентификатор Апликацијата",
    _FORM1: "Пин од Аутентификатор",
    _FORM2: "Адреса за повлекување",
    _FORMMIN: "Адресата за повлекување мора да има 34 знаци",
    _FORMCODESEND: "Испрати код",
    _VERIFF: "Veriff",
    _ACTION: "Проверете и зачувајте",
    _ERROR: "Грешка, обидете се повторно.",
    _SUCCESS: "Промена успешна",
    _RETRYOTP: "Грешка при испраќање на кодот, обидете се повторно.",
    _SENDOTP: "Кодот успешно испратен",
    _SUCCESSOTP: "OTP успешно потврден",
    _SUCCESSAUTH2F: "Auth2fa успешно потврден",
    _ERRORCODE: "Невалиден код, обидете се повторно",
  },
  _COUNTDOWN: {
    _TITLE: "Следно пресек:",
    _DAYS: "Дена",
    _HOURS: "Часови",
    _MINUTES: "Минути",
    _SECONDS: "Секунди",
  },
  _WITHDRAWAL: {
    _TITLE: "Трансакции за повлекување",
  },
  _SYSTEM: {
    _SYSTEM: "Систем",
    _GLOBAL: "Глобален",
    _INVESTMENT: "Повлечи инвестиција",
    _INVESTMENTTEXT:
      "Запомнете дека ќе имате казна од 35% од вашата инвестиција",
    _ACCELERATE: "Убрзајте",
    _REPORT: "Извештај за инвестиции Cryptotradex",
    _PARNERT: "Информации за спонзор",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Повлечи инвестиција",
    _MESSAGE:
      "Влегувањето на вашиот капитал сега подразбира оперативна такса од 35% - акумулираните приходи и ќе биде обработено во рок од 24 часа. Ако го направите тоа, ќе го стопирате неговиот непосреден потенцијал за раст. Одлучете со долгорочна визија!",
    _BUTTON: "Повлечи",
    _BUTTONWAIT: "Повлекувањето е во процес",
  },
  _SIGNAL: {
    _NEWS: "Ново",
    _SIGNALS: "Сигнали",
    _WHATIS: "Што е Cryptotradex Signal?",
    _DESCRIPTION:
      "Придружете се на нашата ексклузивна група за сигнали и добијте повеќе од 10 препораки дневно за да ги максимизирате вашите приходи, со поврати до 200% на инвестиции со висок и низок ризик.",
    _COMMING: "Скоро ќе биде достапно.",
    _SIGNALS10: "СИГНАЛИ10X",
    _CHAT: "Ексклузивен Чат",
    _DESCRIPTIONGLANDING:
      "Во нашиот ексклузивен чат, ќе добиете сигнали во реално време и можете да придонесувате или поставувате прашања за секој сигнал на организиран начин.",
    _TESTIMONIALS: "ТЕСТИМОНИЈАЛИ",
    _GETDAILY:
      "Добијте дневни сигнали од CTX и започнете да го множите вашиот капитал!",
    _TOBE:
      "За да станете дел од Cryptotradex Signals, треба да имате инвестиција од најмалку 1.000 USD.",
    _IWANT: "Сакам сигнали",
    _TESTIMONIALS1:
      "Јас започнав да ги користам овие сигнали на Solana мрежата и успеав да ги претворам моите почетни 200 USD во 600 USD за само неколку часа. Информациите обезбедени преку Dexscreener беа клучни!",
    _TESTIMONIALS2:
      "Со овие сигнали во реално време, постојано го зголемував мојот почетен инвестиции недела по недела. Комбинацијата на податоци од Solana и Dexscreener ми помогна да заработам повеќе од 3.000 USD за само 5 недели!",
    _TESTIMONIALS3:
      "Заедницата зад овие сигнали е неверојатна. Не само што ги зголемив моите Solana средства значително, туку и најдов одлична поддршка која ме водеше во секој трговски потег.",
    _TESTIMONIALS4:
      "Во почетокот бев скептичен, но синергијата помеѓу можностите базирани на Solana и информациите од Dexscreener покажа дека бев погрешен. Мојот портфолио се зголеми многу повеќе од што што можев да замислам!",
  },
  _ACADEMY: {
    _NEWS: "Ново",
    _TITLE: "Академија",
    _WHATIS: "Што е Cryptotradex Academy?",
    _DESCRIPTION:
      "Научете за криптовалути, портфолија, меми и Web3 со нашата платформа онлајн дизајнирана за сите нивоа.",
    _ACTION1: "Оди во Академијата",
    _DISABLED: "Потребен е минимален депозит од 500 USD",
    _TITLANDING: "Стани експерт за Cryptomemes",
    _DESCRIPTIONLANDING:
      "Добијте пристап до ексклузивни туторијали од BRETT пакетот и научете да го владеете светот на MEMECOINS.",
    _ACTIONLANDING: "Регистрирајте се сега и започнете да учите!",
    _WHATCOURSES: "Кои курсеви можете да добиете?",
    _RECOMMENDS: "Препорачани видеа",
    _VIDEO1: "Phantom Портфолио Туторијал",
    _VIDEO2: "Најдете експлозивни MEMECOINS на DEXSCREENER",
    _VIDEO3: "Мајсторирај GMGN како експерт",
    _VIDEO4: "Перфектниот бот за MEMECOINS",
    _VIDEO5: "Како да најдете MEMECOINS со голем потенцијал",
  },
  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
