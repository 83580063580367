import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import AppContext from "../context/AppContext";
import Logo from "../assets/svg/signals/logo.svg";
import Footer from "../assets/svg/signals/footer.svg";
import Chat from "../assets/svg/signals/chat.svg";

export default function Signals() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const { language, showLanguage } = useContext(AppContext);

    const testimonials = [
        {
            username: '@petfew',
            text: t("_SIGNAL._TESTIMONIALS1")
        },
        {
            username: '@nattef',
            text: t("_SIGNAL._TESTIMONIALS2")
        },
        {
            username: '@johndoe',
            text: t("_SIGNAL._TESTIMONIALS3")
        },
        {
            username: '@moonshot',
            text: t("_SIGNAL._TESTIMONIALS4")
        }
    ];

    const [selectedTestimonialIndex, setSelectedTestimonialIndex] = useState(0);

    return (
        <>
            <section className="h-full w-full bg-black text-white bg-no-repeat overflow-hidden font-sans">
                <div className="px-5 xl:px-0 lg:max-w-7xl w-full mx-auto relative">
                    <div className="absolute top-4 right-4 z-10">
                        <button
                            onClick={() => showLanguage(!language)}
                            className="bg-[color:#7EFF00] hover:bg-[color:#9AFF40] text-black font-bold py-2 px-4 rounded rounded-full transition"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-9 h-9 p-1.5  transition duration-75 group-hover:text-lime-500 group-hover:font-bold cursor-pointer" >
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25" />
                            </svg>
                        </button>
                    </div>
                    <div className='flex flex-col justify-center items-center p-6 z-10 cursor-pointer'>
                        <img src={Logo} alt="Cryptotradex Signals" className='w-[10%] cursor-pointer z-10 ' onClick={() => navigate("/")} />
                    </div>
                    <div className="absolute inset-0 flex justify-center items-center -top-[50%] z-1">
                        <span className="[color:transparent] [-webkit-text-stroke:2px_#FFFFFF33] [-webkit-text-fill-color:transparent] font-bold text-[18vw] uppercase">
                            {t("_SIGNAL._SIGNALS10")}
                        </span>
                    </div>
                    <div className="relative z-10 max-w-7xl mx-auto px-4 py-14 flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-8">
                        <div className="flex-1 space-y-6">
                            <div className="flex items-center space-x-2">
                                <span className="inline-block bg-[color:#7EFF00] text-black font-black py-1 px-2 text-lg">CT SIGNS</span>
                            </div>
                            <h2 className="text-3xl md:text-5xl font-bold text-[color:#7EFF00]"> {t("_SIGNAL._CHAT")}</h2>
                            <p className="text-lg max-w-md">
                                {t("_SIGNAL._DESCRIPTIONGLANDING")}
                            </p>

                            <div className="flex space-x-4 text-[color:#7EFF00]">
                                <span className="w-8 h-8 bg-white text-black flex items-center justify-center rounded-full">💬</span>
                                <span className="w-8 h-8 bg-white text-black flex items-center justify-center rounded-full">🤖</span>
                                <span className="w-8 h-8 bg-white text-black flex items-center justify-center rounded-full">✨</span>
                            </div>
                        </div>

                        <div className="flex-1 flex justify-end items-center relative -px-4">
                            <img src={Chat} alt="Chat" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative bg-black text-white py-36 overflow-hidden">
                <div className="absolute inset-0 flex justify-center items-center -top-[68%]">
                    <span className="[color:transparent] [-webkit-text-stroke:2px_#FFFFFF33] [-webkit-text-fill-color:transparent] text-[18vw] uppercase">
                        {t("_SIGNAL._TESTIMONIALS")}
                    </span>
                </div>
                <div className="px-5 xl:px-0 lg:max-w-7xl w-full mx-auto relative z-10 mx-auto flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-8">
                    <div className="relative w-full md:w-1/2">
                        <div className="transform rotate-6 bg-[color:#7EFF00] p-8 h-80 w-full flex flex-col justify-center items-center">
                            <div className="transform -rotate-6 text-black w-full">
                                <span className="text-[8rem] leading-none">“</span>
                                <p className="text-xl font-bold mb-4 text-center">
                                    {testimonials[selectedTestimonialIndex].text}
                                </p>
                                <span className="text-[8rem] leading-none block text-right">”</span>
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 flex items-center justify-center space-x-4">
                        <button className="text-xl cursor-pointer">←</button>
                        <div className="flex space-x-4">
                            {testimonials.map((item, index) => (
                                <div
                                    key={item.username}
                                    onClick={() => setSelectedTestimonialIndex(index)}
                                    className={`flex flex-col items-center space-y-2 cursor-pointer border-2 p-2 transition-colors ${selectedTestimonialIndex === index
                                        ? 'border-gray-700'
                                        : 'border-transparent hover:border-gray-700'
                                        }`}
                                >
                                    <img src="https://via.placeholder.com/100" alt="User" className="rounded-xl shadow-lg" />
                                    <span className="text-[color:#7EFF00]">{item.username}</span>
                                </div>
                            ))}
                        </div>
                        <button className="text-xl cursor-pointer">→</button>
                    </div>

                </div>
            </section>
            <section className="relative bg-black text-white py-32 overflow-hidden p-20">
                <div className="absolute inset-0 flex justify-center items-center -top-[50%]">
                    <span className="[color:transparent] [-webkit-text-stroke:2px_#FFFFFF33] [-webkit-text-fill-color:transparent] text-[18vw] uppercase">
                        GOOOOOOO
                    </span>
                </div>
                <div
                    className="relative z-10 max-w-7xl mx-auto px-4 rounded-xl border border-[color:#7EFF00] py-10 text-center bg-cover bg-center m-10"
                    style={{ backgroundImage: `url(${Footer})` }}
                >
                    <div className="flex flex-col justify-center items-center p-6">
                        <img src={Logo} alt="Cryptotradex Signals" className="w-[10%]" />
                    </div>
                    <p className="mb-8">
                        {t("_SIGNAL._GETDAILY")}
                    </p>
                    <p className="mb-8 text-sm">
                        {t("_SIGNAL._TOBE")}
                    </p>
                    <button className="bg-[color:#7EFF00] hover:bg-[color:#9AFF40] text-black font-bold py-3 px-6 rounded transition" onClick={() => navigate("/")}>
                        {t("_SIGNAL._IWANT")}
                    </button>
                </div>
            </section>
        </>
    );
}
