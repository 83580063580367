import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import AppContext from "../context/AppContext";
import { formatDistanceToNow, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';
import toast from "react-hot-toast";
import CustomButton from '../components/CustomButton';

export default function Finance() {
  const { t } = useTranslation();
  const [wallet, setWallet] = useState();
  const [listDeposit, setListDeposit] = useState([]);
  const { loading, updateLoading } = useContext(AppContext);

  useEffect(() => {
    getWallet();
    getDepositList();
  }, []);

  const getWallet = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/deposit`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          updateLoading(false);
        } else {
          setWallet(response?.address);
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }

  const getDepositList = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/deposit?limit=40`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === false) {
          updateLoading(false);
        } else {
          setListDeposit(response?.data?.items);
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return formatDistanceToNow(date, { locale: enUS, addSuffix: true });
  }

  const copyWallet = () => {
    navigator.clipboard.writeText(
      `${wallet}`
    );
    toast.success(t("_FINANCE._COPYMESSAGE"));
  };

  return (
    <div className="mx-auto lg:p-4">
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="bg-zinc-800 text-white p-6 rounded-lg w-full lg:w-2/6 border-lime-600 hover:border-lime-300 border-2">
          <h2 className="text-lg font-semibold mb-2">{t("_FINANCE._DEPOSIT")}</h2>
          <p className="text-center text-md mb-4">{t("_FINANCE._QR")}</p>
          <div className="flex justify-center items-center w-full">
            <QRCode
              size={256}
              value={wallet ? wallet : "Loading"}
              className={"w-4/6 p-2 rounded-lg bg-lime-600"}
              viewBox={`0 0 256 256`}
            />
          </div>
        </div>
        <div className="bg-zinc-800 p-6 rounded-lg shadow w-full lg:w-4/6 flex flex-col text-left h-auto relative border-lime-600 hover:border-lime-300 border-2">
          <div className="flex flex-col w-full p-4">
            <h2 className="text-lg text-white ">{t("_FINANCE._NETWORK")}: TRON</h2>
            <p className="text-white">{t("_FINANCE._INSTRUCTIONS")}</p>
            <ul className="text-sm text-white list-disc">
              <li>{t("_FINANCE._STEP1")}</li>
              <li className="text-red-600">{t("_FINANCE._STEP2")}</li>
            </ul>
            <br />
            <div className="flex flex-row w-full lg:w-3/5 mx-auto mt-4 bg-gray-800 p-4 gap-2 text-white cursor-pointer rounded-lg justify-center items-center" onClick={() => copyWallet()}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
              </svg>
              <p className="hidden lg:block">
                {wallet ? wallet : "Loading"}
              </p>
              <p className="block lg:hidden">
                {wallet ? wallet.slice(0, 4) + "......" + wallet.slice(-4) : "Loading"}
              </p>
            </div>
            <br />
            <p className="text-xs text-lime-600 mt-4">
              {t("_FINANCE._DISCLAIMER")}
            </p>
          </div>
        </div>
      </div>
      <div className="my-8">
        <h2 className="text-lg font-semibold mb-4 text-white" >{t("_FINANCE._TABLE1")} </h2>
        <div className="relative overflow-x-auto w-full">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-lg ">
            <thead className="text-md text-white border-b-2">
              <tr>
                <th className="px-6 py-4">{t("_FINANCE._TABLE1_A")}</th>
                <th className="px-6 py-4">{t("_FINANCE._TABLE1_B")}</th>
                <th className="px-6 py-4">{t("_FINANCE._TABLE1_C")}</th>
                <th className="px-6 py-4 ">{t("_FINANCE._TABLE1_D")}</th>
              </tr>
            </thead>
            <tbody>
              {listDeposit.map((deposit, index) => (
                <tr className="border-b-2" key={index}>
                  <td className="py-2 px-4 text-white">#{parseInt(deposit.id) + parseInt(1000)}</td>
                  <td className="py-2 px-4 text-white">{deposit.balance} TRX</td>
                  <td className="py-2 px-4 text-white">{formatDate(deposit.created)}</td>
                  <td className="py-2 px-4 text-white"><span className={`px-2 py-1 rounded ${deposit.status === '1' ? 'bg-yellow-500' : 'bg-green-500'} text-white`}>{deposit.error === '0' ? 'Success' : 'In Progress'}</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div >
  );
}
