import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Plan1 from "../assets/svg/plans/1.svg"
import Plan2 from "../assets/svg/plans/2.svg"
import Plan3 from "../assets/svg/plans/3.svg"
import Plan4 from "../assets/svg/plans/4.svg"
import Plan5 from "../assets/svg/plans/5.svg"
import Plan6 from "../assets/svg/plans/6.svg"
import Plan7 from "../assets/svg/plans/7.svg"
import Plan8 from "../assets/svg/plans/8.svg"
import Plan9 from "../assets/svg/plans/9.svg"
import Plan10 from "../assets/svg/plans/10.svg"
import None from "../assets/svg/plans/none.svg"


import Plan11 from "../assets/svg/v2/plans/1.svg"
import Plan12 from "../assets/svg/v2/plans/2.svg"
import Plan13 from "../assets/svg/v2/plans/3.svg"
import Plan14 from "../assets/svg/v2/plans/4.svg"
import Plan15 from "../assets/svg/v2/plans/5.svg"
import Plan16 from "../assets/svg/v2/plans/6.svg"
import Plan17 from "../assets/svg/v2/plans/7.svg"
import Plan18 from "../assets/svg/v2/plans/8.svg"



const PackageCard = ({ userData }) => {
    const { t } = useTranslation();
    const [currentLevel, setCurrentLevel] = useState({});

    const levels = [
        {
            image: Plan1,
            plan: 0,
            name: "",
        },
        {
            image: Plan1,
            plan: 1,
            name: "Bone",
        },
        {
            image: Plan2,
            plan: 2,
            name: "Baby Doge",
        }, {
            image: Plan3,
            plan: 3,
            name: "Popcat",
        }, {
            image: Plan4,
            plan: 4,
            name: "Brett",
        }, {
            image: Plan5,
            plan: 5,
            name: "Floki",
        }, {
            image: Plan6,
            plan: 6,
            name: "Bonk",
        }, {
            image: Plan7,
            plan: 7,
            name: "Dogwifhat",
        }, {
            image: Plan8,
            plan: 8,
            name: "Pepe",
        }, {
            image: Plan9,
            plan: 9,
            name: "Shiba",
        }, {
            image: Plan10,
            plan: 10,
            name: "Doge",
        }, {
            image: Plan11,
            plan: 11,
            name: "HBAR",
        }, {
            image: Plan12,
            plan: 12,
            name: "SUI",
        }, {
            image: Plan13,
            plan: 13,
            name: "TRX",
        }, {
            image: Plan14,
            plan: 14,
            name: "Doge",
        }, {
            image: Plan15,
            plan: 15,
            name: "SOL",
        }, {
            image: Plan16,
            plan: 16,
            name: "BNB",
        }, {
            image: Plan17,
            plan: 17,
            name: "ETH",
        }, {
            image: Plan18,
            plan: 18,
            name: "BTC",
        },
    ];

    useEffect(() => {
        const userVolume = userData?.package;
        setCurrentLevel(levels[userVolume]);
    }, [userData]);



    const oraculo = (props) => {
        if (window.localStorage.getItem("tron")) {
            let value = props / window.localStorage.getItem("tron");
            let fomart = Number.parseFloat(value).toFixed(3);
            let valueFormat = formatNumber(fomart);
            return valueFormat;
        }
    };

    const formatNumber = (value) => {
        if (value >= 1000000) {
            return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        if (value >= 1000) {
            return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        return value.toString();
    }

    return (
        <>
            {userData?.package > 0 ? (
                <div className="w-full h-[12.5rem] lg:w-1/3  rounded-lg shadow-md text-white bg-cover border-lime-600 border-2 bg-no-repeat group flex flex-col justify-between" style={{ backgroundImage: `url(${currentLevel?.image})` }}>
                    <h1 className="text-white text-2xl p-4">{t("_DASHBOARD._TITLE6")}: {currentLevel?.name}</h1>
                    <div className="flex justify-between items-center">
                        <div></div>
                        <div className="flex flex-col h-auto text-end justify-end font2 text-xl text-white bg-zinc-900/80 lg:bg-transparent w-full px-4">
                            <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0">{formatNumber(userData?.balance_available || 0)} USD </p>
                            <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0">{formatNumber(userData?.total_available || 0)} {t("_DASHBOARD._TOTAL")} </p>
                            <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(userData?.balance_available || 0)}  TRX </p>
                            <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">{oraculo(userData?.total_available || 0)}  {t("_DASHBOARD._TOTAL")} </p>
                        </div>
                    </div>
                </div>
            ) :
                <div className="w-full h-[12.5rem] lg:w-1/3  rounded-lg shadow-md text-white bg-cover border-lime-600 border-2 bg-no-repeat group flex flex-col justify-between" style={{ backgroundImage: `url(${None})` }}>
                    <h1 className="text-white text-2xl p-4">{t("_DASHBOARD._TITLE6")} None</h1>
                    <div className="flex justify-between items-center">
                        <div></div>
                        <div className="flex flex-col h-auto text-end justify-end font2 text-xl text-white  bg-zinc-900/80 lg:bg-transparent w-full px-4">
                            <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0">0 TRX </p>
                            <p className="block group-hover:hidden transition-opacity duration-300 opacity-100 group-hover:opacity-0">0 TRX </p>
                            <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">0 USD </p>
                            <p className="hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100">0 USD </p>
                        </div>
                    </div>
                </div>

            }
        </>
    );
};

export default PackageCard;
