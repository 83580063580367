import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { formatInTimeZone } from 'date-fns-tz';
import { format } from 'date-fns';

const Countdown = ({ targetDate, dateServer }) => {
    const { t } = useTranslation();
    // Calculamos la fecha objetivo actualizada sumándole una hora a la fecha objetivo original
    const updatedTargetDate = new Date(new Date(targetDate).getTime() + 60 * 60 * 1000);

    // Función para calcular el tiempo restante
    const calculateTimeLeft = () => {
        const now = new Date();
        const timeZone = 'America/Lima';
        const limaTime = formatInTimeZone(now, timeZone, 'yyyy-MM-dd HH:mm:ssXXX');
        const serverTime = new Date(limaTime);
        const difference = +updatedTargetDate - +serverTime; // Calcula la diferencia en milisegundos entre la fecha objetivo y la fecha del servidor ajustada
        let timeLeft = {};

        if (difference > 0) { // Si la diferencia es mayor a cero, calculamos los días, horas, minutos y segundos restantes
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)), // Calcula los días restantes
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24), // Calcula las horas restantes
                minutes: Math.floor((difference / 1000 / 60) % 60), // Calcula los minutos restantes
                seconds: Math.floor((difference / 1000) % 60), // Calcula los segundos restantes
            };
        } else { // Si la diferencia es menor o igual a cero, el tiempo restante es cero
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }
        return timeLeft; // Retorna el objeto con el tiempo restante
    };


    // Estado inicial del tiempo restante calculado
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    // useEffect que actualiza el tiempo restante cada segundo
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft()); // Actualiza el estado con el nuevo tiempo restante
        }, 1000); // Intervalo de 1000 ms (1 segundo)

        return () => clearTimeout(timer); // Limpia el timeout para evitar fugas de memoria
    });

    return (
        <>
            <div className="flex justify-between">

                {/*
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-white cursor-pointer">
                    <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                </svg>
                */}
            </div>
            <div className="flex flex-col lg:flex-row  items-start justify-center w-full gap-1.5 count-down-main">
                <div className="timer w-full lg:w-auto">
                    <div
                        className="rounded-xl bg-black/50 backdrop-blur-sm py-3 min-w-[96px] flex items-center justify-center flex-col gap-1 px-3">
                        <h3
                            className="countdown-element days font-manrope font-semibold text-2xl text-white text-center">
                            {timeLeft.days}
                        </h3>
                        <h3 className="text-lg text-white mt-1 text-center w-full">{t("_COUNTDOWN._DAYS")}</h3>
                    </div>
                </div>
                <div className="timer w-full lg:w-auto">
                    <div
                        className="rounded-xl bg-black/50 backdrop-blur-sm py-3 min-w-[96px] flex items-center justify-center flex-col gap-1 px-3">
                        <h3
                            className="countdown-element hours font-manrope font-semibold text-2xl text-white text-center">{timeLeft.hours}
                        </h3>
                        <h3 className="text-lg text-white mt-1 text-center w-full">{t("_COUNTDOWN._HOURS")}</h3>
                    </div>
                </div>
                <div className="timer w-full lg:w-auto">
                    <div
                        className="rounded-xl bg-black/50 backdrop-blur-sm py-3 min-w-[96px] flex items-center justify-center flex-col gap-1 px-3">
                        <h3
                            className="countdown-element minutes font-manrope font-semibold text-2xl text-white text-center">{timeLeft.minutes}
                        </h3>
                        <h3 className="text-lg text-white mt-1 text-center w-full">{t("_COUNTDOWN._MINUTES")}</h3>
                    </div>
                </div>
                <div className="timer w-full lg:w-auto">
                    <div
                        className="rounded-xl bg-black/50 backdrop-blur-sm py-3 min-w-[96px] flex items-center justify-center flex-col gap-1 px-3">
                        <h3
                            className="countdown-element seconds font-manrope font-semibold text-2xl text-white text-center">{timeLeft.seconds}
                        </h3>
                        <h3 className="text-lg text-white mt-1 text-center w-full">{t("_COUNTDOWN._SECONDS")}</h3>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Countdown;
