export const de = {
  _LOGIN: {
    _EMAIL: "E-Mail",
    _PASSWORD: "Passwort",
    _REMEMBER: "Erinnere dich an mich",
    _LOGIN: "ANMELDEN",
    _SIGNUP: "Registrieren",
    _FORGOTPASSWORD: "Passwort vergessen?",
    _NOACCOUNT: "Noch kein Konto?",
    _ERROR_EMAIL: "Anscheinend bist du nicht registriert! Versuche es erneut.",
    _ERROR_PASSWORD: "Falsches Passwort",
    _SUCCESS: "Anmeldung erfolgreich",
  },
  _REGISTER: {
    _REGISTER: "Konto registrieren",
    _NAME: "Benutzername",
    _EMAIL: "Deine E-Mail",
    _PASSWORD: "Dein Passwort",
    _CONFIRMPASSWORD: "Passwort bestätigen",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (optional)",
    _HAVEACCOUNT: "Ich habe ein Konto",
    _ERROR_REFERAL: "Falscher Empfehlungscode",
    _ERROR_EMAIL: "Diese E-Mail existiert bereits",
    _SUCCESS: "Registrierung erfolgreich",
  },
  _SIDEBAR: {
    _PAGES: "Seiten",
    _OPEN: "Seitenleiste öffnen",
    _DASHBOARD: "Startseite",
    _NETWORK: "Netzwerk",
    _FINANCE: "Geldbörse",
    _PACKAGES: "Pläne",
    _SIGNOUT: "Abmelden",
    _LANGUAGE: "Sprache",
    _PROFILE: "Profil",
  },
  _DASHBOARD: {
    _TITLE: "Kontostand",
    _TITLE2: "Einzahlung",
    _TITLE3: "Gesamteinnahmen",
    _TITLE4: "Unilevel Einnahmen",
    _TITLE5: "Rangbonus",
    _TITLE6: "Plan",
    _TOTAL: "Gesamt",
    _TABLE3: "Paketliste",
    _START: "Start",
    _FINISH: "Ende",
    _STATUS: "Status",
    _STATUS_ACTIVE: "Aktiv",
    _STATUS_INACTIVE: "Inaktiv",
    _CURRENT: "Aktuell",
    _UPGRADE: "Upgrade",
    _COPYMESSAGE: "Erfolgreich kopiert",
    _NOTIFICATION1: "Neue Einzahlung",
    _NOTIFICATION2: "Cryptotradex Gewinn",
    _NOTIFICATION3: "Unilevel Gewinn",
    _NOTIFICATION4: "Unendliche Reichweite",
    _NOTIFICATION5: "Paket kaufen",
    _NOTIFICATION7: "Wiederkehrender Direktverweisbonus",
    _PREV: "Vorherige",
    _NEXT: "Nächste",
    _TABLE4_A: "Operation",
    _TABLE4_B: "Gewinn",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Datum",
    _SHARED: "Teile deinen Empfehlungslink",
  },
  _FINANCE: {
    _DEPOSIT: "Einzahlung",
    _QR: "Scanne den folgenden QR-Code oder kopiere die Adresse",
    _NETWORK: "Netzwerk",
    _INSTRUCTIONS: "Anweisungen zum Senden von TRON (TRX)",
    _STEP1: "Stelle sicher, dass das ausgewählte Netzwerk TRON (TRC20) ist.",
    _STEP2: "Sende den Betrag von TRON (TRX) an die folgende Wallet-Adresse.",
    _COPY: "Kopieren",
    _DISCLAIMER:
      "Haftungsausschluss: Wir sind nicht verantwortlich für Transaktionen außerhalb des TRON (TRC20) Netzwerks oder für das Senden anderer Kryptowährungen als TRON (TRX). Jeder Verlust von Geldern aufgrund dieser Fehler liegt in der alleinigen Verantwortung des Absenders.",
    _TABLE1: "Einzahlungshistorie",
    _TABLE1_A: "Bestellung",
    _TABLE1_B: "Betrag",
    _TABLE1_C: "Datum",
    _TABLE1_D: "Status",
    _SUCCESS: "Das Paket wurde erfolgreich hinzugefügt",
    _COPYMESSAGE: "Wallet-Adresse erfolgreich kopiert",
  },
  _PACKAGES: {
    _ATTENTION:
      "Achtung: Du hast ein Firmenkonto. Beim Kauf eines Pakets wird das Konto und alle seine Vorteile zurückgesetzt.",
    _PACKAGES: "Wähle den besten Plan für dich",
    _SUBTITLE: "Du hast 8 Optionen",
    _BALANCE: "Einzahlungssaldo",
    _PACKAGE: "Paket",
    _PACKAGE_1: "Du wirst ab Level verdienen",
    _PACKAGE_2: "Verdiene bis zu ",
    _PACKAGE_3: "Täglicher ROI",
    _BUTTON1: "Ausgefüllt",
    _BUTTON2: "Kaufen",
    _CALCULATE: "Plan berechnen",
    _RECHARGE: "Du hast nicht genug Geld, lade dein Wallet auf",
    _PACKAGEMESSAGE: "Du musst ein höheres Paket kaufen",
    _SUCCESS: "Das Paket wurde erfolgreich hinzugefügt",
    _UPDATE: "Paketaktualisierungspreis",
  },
  _NETWORK: {
    _TODAY: "Heute",
    _AVAILABLE: "Verfügbar",
    _WITHDRAW: "Gesamtabhebungen",
    _SEARCHLABEL: "E-Mail-Suche",
    _SEARCH: "Suche",
    _NETWORK: "Netzwerk",
    _DIRECTS: "Direkte",
    _SUCCESS: "Erfolgreiche Suche",
    _ERROR: "Nicht Teil deines Binärbaums.",
    _LEVEL: "Level",
    _USERS: "Benutzer",
    _AMOUNT: "Betrag",
    _USERNAME: "Benutzername",
    _EMAIL: "E-Mail",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Paket",
  },
  _PROFILE: {
    _TITLE: "Profil",
    _NAME: "Dein Name",
    _EMAIL: "Deine E-Mail",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Aktuelles Passwort",
    _PASSWORD: "Neues Passwort",
    _CONFIRMPASSWORD: "Neues Passwort bestätigen",
    _WALLET: "Auszahlungsadresse",
    _ACTION: "Bearbeiten",
    _TIPS: "Tipps",
    _TIPS_1:
      "Der Name muss lesbar sein und darf 20 Zeichen nicht überschreiten.",
    _TIPS_2:
      "Das Passwort muss Zahlen, Buchstaben und Symbole enthalten; je komplexer, desto besser.",
    _TIPS_3:
      "Die E-Mail-Adresse kann nicht geändert werden. Wenn du eine Änderung vornehmen musst, kontaktiere bitte den Support.",
    _TIPS_4:
      "Die Auszahlungsadresse kann nur mit einer zuvor aktivierten Zwei-Faktor-Authentifizierung geändert werden.",
    _ERROR_PASSWORD: "Vorheriges Passwort falsch",
    _SUCCESS: "Profil erfolgreich aktualisiert",
    _MIN: "Mindestabhebung",
    _UPDATE: "Profil aktualisieren",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Passwort vergessen?",
    _EMAIL: "Deine E-Mail",
    _BUTTON: "E-Mail senden",
    _MESSAGE: "Dein Passwort wurde an deine E-Mail gesendet",
    _ERROR_EMAIL: "Diese E-Mail ist nicht registriert",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Aktueller Rang",
    _NEXT: "Nächster Rang",
    _SILVER: "Silber",
    _GOLD: "Gold",
    _RUBY: "Rubin",
    _EMERALD: "Smaragd",
    _DIAMOND: "Diamant",
    _DIAMONDB: "Blauer Diamant",
    _DIAMONDBB: "Schwarzer Diamant",
    _NONE: "Keiner",
  },
  _AUTH: {
    _TITLEDEFAULT: "Auszahlungswallet ändern",
    _TITLE: "Schritt 1",
    _TITLE2: "Schritt 2",
    _TITLE3: "Schritt 3",
    _STEP1:
      "Lade den Authenticator auf dein Gerät herunter und installiere ihn",
    _STEP2: "Scanne den Code, um ihn zu speichern",
    _FORMEMAIL: "E-Mail-Bestätigung",
    _FORMHELPEMAIL:
      "Gib den 6-stelligen Bestätigungscode ein, der gesendet wurde an",
    _FORMHELPAUTH:
      "Gib den 6-stelligen Code ein, der von der Authenticator-App generiert wurde",
    _FORM1: "Pin durch Authenticator",
    _FORM2: "Auszahlungsadresse",
    _FORMMIN: "Die Auszahlungsadresse muss 34 Zeichen lang sein",
    _FORMCODESEND: "Code gesendet",
    _VERIFF: "Überprüfen",
    _ACTION: "Überprüfen und speichern",
    _ERROR: "Fehler, versuche es erneut.",
    _SUCCESS: "Änderung erfolgreich",
    _RETRYOTP: "Fehler beim Senden des Codes, versuche es erneut.",
    _SENDOTP: "Code erfolgreich gesendet",
    _SUCCESSOTP: "OTP erfolgreich verifiziert",
    _SUCCESSAUTH2F: "Zwei-Faktor-Authentifizierung erfolgreich verifiziert",
    _ERRORCODE: "Ungültiger Code, versuche es erneut",
  },
  _COUNTDOWN: {
    _TITLE: "Nächster Schnitt:",
    _DAYS: "Tage",
    _HOURS: "Stunden",
    _MINUTES: "Minuten",
    _SECONDS: "Sekunden",
  },
  _WITHDRAWAL: {
    _TITLE: "Abhebungstransaktionen",
  },
  _SYSTEM: {
    _SYSTEM: "System",
    _GLOBAL: "Global",
    _INVESTMENT: "Investition zurückziehen",
    _INVESTMENTTEXT:
      "Denken Sie daran, dass eine Strafe von 35% Ihrer Investition anfällt",
    _ACCELERATE: "Beschleunigen",
    _REPORT: "Cryptotradex-Investitionsbericht",
    _PARNERT: "Sponsorinformationen",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Investition abziehen",
    _MESSAGE:
      "Das Abheben deines Kapitals jetzt erfordert eine Betriebsgebühr von 35% - aufgelaufene Gewinne und wird innerhalb von 24 Stunden verarbeitet. Wenn du es tust, stoppst du sein unmittelbares Wachstumspotenzial. Entscheide mit langfristiger Vision!",
    _BUTTON: "Abziehen",
    _BUTTONWAIT: "Abzug wird bearbeitet",
  },
  _SIGNAL: {
    _NEWS: "Neu",
    _SIGNALS: "Signale",
    _WHATIS: "Was ist Cryptotradex Signal?",
    _DESCRIPTION:
      "Tritt unserer exklusiven Signal-Gruppe bei und erhalte täglich mehr als 10 Empfehlungen, um deine Gewinne zu maximieren, mit einer Rendite von bis zu 200% bei hoch- und niedrig-risiko Investitionen.",
    _COMMING: "Kommt bald.",
    _SIGNALS10: "SIGNALE10X",
    _CHAT: "Exklusiver Chat",
    _DESCRIPTIONGLANDING:
      "In unserem exklusiven Chat erhältst du Echtzeit-Signale und kannst Fragen zu jedem Signal stellen oder Beiträge organisieren.",
    _TESTIMONIALS: "TESTIMONIALS",
    _GETDAILY:
      "Erhalte täglich CTX-Signale und beginne, dein Kapital zu vervielfachen!",
    _TOBE:
      "Um Teil von Cryptotradex Signals zu werden, musst du eine Investition von mindestens 1.000 USD haben.",
    _IWANT: "Ich möchte die Signale",
    _TESTIMONIALS1:
      "Ich begann, diese Signale im Solana-Netzwerk zu verwenden und konnte meine anfänglichen 200 USD innerhalb weniger Stunden auf 600 USD steigern. Die Informationen von Dexscreener haben den Unterschied gemacht!",
    _TESTIMONIALS2:
      "Mit diesen Echtzeit-Signalen habe ich meine anfängliche Investition konsequent von Woche zu Woche vermehrt. Die Kombination der Solana-Daten und Dexscreener half mir, in nur 5 Wochen über 3.000 USD zu verdienen!",
    _TESTIMONIALS3:
      "Die Community hinter diesen Signalen ist unglaublich. Nicht nur, dass ich meine Solana-Bestände erheblich gesteigert habe, sondern ich habe auch eine großartige Gruppe von Unterstützern gefunden, die mich bei jedem Trade begleitet haben.",
    _TESTIMONIALS4:
      "Zu Beginn war ich skeptisch, aber die Synergie zwischen den Solana-basierten Chancen und den Informationen von Dexscreener hat mir gezeigt, dass ich falsch lag. Mein Portfolio ist viel mehr gewachsen, als ich je erwartet hatte!",
  },
  _ACADEMY: {
    _NEWS: "Neu",
    _TITLE: "Akademie",
    _WHATIS: "Was ist Cryptotradex Academy?",
    _DESCRIPTION:
      "Lerne über Kryptowährungen, Wallets, Memes und Web3 mit unserer Online-Plattform, die für alle Levels geeignet ist.",
    _ACTION1: "Zur Akademie gehen",
    _DISABLED: "Erfordert eine Mindestzahlung von 500 USD",
    _TITLANDING: "Werde ein Experte für Cryptomemes",
    _DESCRIPTIONLANDING:
      "Erhalte exklusive Tutorials ab dem BRETT-Paket und lerne, wie du die Welt der MEMECOINS beherrschst.",
    _ACTIONLANDING: "Melde dich jetzt an und beginne zu lernen!",
    _WHATCOURSES: "Welche Kurse kannst du erhalten?",
    _RECOMMENDS: "Empfohlene Videos",
    _VIDEO1: "Phantom Wallet Tutorial",
    _VIDEO2: "Finde explosive MEMECOINS auf DEXSCREENER",
    _VIDEO3: "Meistere GMGN wie ein Profi",
    _VIDEO4: "Der perfekte Bot für MEMECOINS",
    _VIDEO5: "Wie man MEMECOINS mit großem Potenzial findet",
  },
  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
