import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import AppContext from "../context/AppContext";
import Signals from "../assets/svg/signals-icon.svg";
import Countdown from "../components/Countdown"


export default function MySignals() {
  const { t } = useTranslation();
  const [wallet, setWallet] = useState();
  const [listDeposit, setListDeposit] = useState([]);
  const { loading, updateLoading } = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [signals, setSignals] = useState([]);


  const items = [
    { title: "Aiplague", profit: '15%', start: "8/01 5:38pm", end: "10/01 9:30pm", status: "closed" },
    { title: "Tiptoe", profit: '1200%', start: "9/01 1:13pm", end: "9/01 2:39pm", status: "closed" },
    { title: "Hollywood", profit: '800%', start: "8/01 1:13pm", end: "9/01 12:00am", status: "closed" },
    { title: "BABYSHARK", profit: '200%', start: "8/01 5:30pm", end: "8/01 9:30pm", status: "closed" },
    { title: "Solace AI", profit: '60%', start: "6/01 8:57pm", end: "7/01 9:13am", status: "closed" },
    { title: "Game Of Memes", profit: 'unknown', start: "05/01 7:58pm", end: "unknown", status: "open" },
    { title: "Swaldo", profit: '34%', start: "02/01 11:11pm", end: "02/01 11:22pm", status: "closed" },
    { title: "WINFO", profit: '25%', start: "01/01 10:51pm", end: "01/01 11:02pm", status: "closed" }
  ];

  useEffect(() => {
    getAllSignals();
  }, [page, limit]);



  const getAllSignals = async () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/signals?page=${page}&limit=${limit}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        setSignals(response?.data?.items)
        setTotal(response?.data?.total);
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };


  const formatNumber = (value) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }

    const valueString = value.toString();
    const [integerPart, decimalPart] = valueString.split('.');

    // Si no hay parte decimal o no hay ceros desplazados, solo devolvemos el número original
    if (!decimalPart || decimalPart === '0') {
      return valueString;
    }

    // Contar los ceros iniciales en la parte decimal
    let leadingZeros = 0;
    while (decimalPart[leadingZeros] === '0') {
      leadingZeros++;
    }

    // Limitar a un máximo de 4 decimales o hasta que aparezca otro cero
    let limitDecimal = decimalPart.slice(leadingZeros); // Empieza con los decimales desplazados
    let nonZeroFound = false;

    // Recortar hasta 4 decimales o encontrar un cero
    for (let i = leadingZeros; i < decimalPart.length; i++) {
      if (decimalPart[i] === '0' && nonZeroFound) {
        limitDecimal = limitDecimal.slice(0, i - leadingZeros); // Si aparece otro cero, cortar
        break;
      }
      if (decimalPart[i] !== '0') {
        nonZeroFound = true; // Marca que ya encontramos un valor diferente a cero
      }
      if (limitDecimal.length >= 4) {
        break; // Limitar a 4 decimales
      }
      limitDecimal += decimalPart[i]; // Agregar más decimales
    }

    // Si hay ceros desplazados, resaltar esa cantidad
    if (leadingZeros > 0) {
      const formattedDecimal = `0<span class="text-xs relative -bottom-1">${leadingZeros}</span>${limitDecimal}`;
      return `${integerPart}.${formattedDecimal}`;
    }

    return valueString;
  };

  return (
    <div className="mx-auto lg:p-4">
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="bg-zinc-800 text-white p-6 rounded-lg w-full lg:w-2/6 border-indigo-600 hover:border-indigo-300 border-2">
          <h2 className="text-lg font-semibold mb-2">{t("_FREE._LINK")}</h2>
          <p className="text-left text-md mb-4">{t("_FREE._TITLE")}</p>
          <p className="text-2xl text-center">{t("_FREE._FREEFOR")}</p>
          <Countdown targetDate={"2025-01-22"} />
          <div className="flex flex-col justify-center items-center w-full my-4">
            <QRCode
              size={256}
              value={"https://chat.whatsapp.com/I40EzQSOmeW6XBAD3NRkQC"}
              className={"w-4/6 p-2 rounded-lg bg-lime-600 "}
              viewBox={`0 0 256 256`}
            />
            <a href="https://chat.whatsapp.com/I40EzQSOmeW6XBAD3NRkQC" target={"_blank"} rel="noreferrer" >
              <button className="bg-lime-300 text-black p-4 rounded-lg my-2" >{t("_FREE._CHANNEL")}</button>
            </a>
          </div>
          <div className="my-4">
            <h5 className="my-2 text-red-400">{t("_FREE._DISCLAIMER")} </h5>
            <p>{t("_FREE._TEXTDISCLAIMER")} </p>
            <h5 className="my-2 text-lime-600">{t("_FREE._LEGEND")}:</h5>
            <ul className="flex flex-col gap-6">
              <li className="flex flex-row items-center"><img src={Signals} alt={"Icon Signals"} /> {t("_FREE._NAME")}</li>
              <li className="flex flex-row items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6 mx-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                </svg>
                {t("_FREE._URL")}
              </li>
              <li className="flex flex-row items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6 mx-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                {t("_FREE._START")}
              </li>
              <li className="flex flex-row items-center">
                <span className="size-6 mx-6 text-xl">{" "}%</span>
                {t("_FREE._PROFIT")}
              </li>
              <li className="flex flex-row items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6 mx-6 rotate-180">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                {t("_FREE._END")}
              </li>

            </ul>
          </div>
        </div>
        <div className="w-full lg:w-4/6">
          <div className="mb-4 w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4">
            {signals.map((item, index) => (
              <div className="rounded-lg text-white flex flex-col mb-4 relative" key={index}>
                <div
                  className={`absolute -top-4 -right-2 ${item?.status === "0" ? "bg-green-600 " : "bg-red-600 "} bg-red-600 text-white text-xs font-bold py-1 px-2 rounded-full shadow-lg z-20`}
                >
                  {item?.status === "0" ? "Open" : "Closed"}
                </div>
                <div className="h-auto flex flex-col z-10 w-full border-zinc-600 group hover:border-indigo-300 border-2 bg-zinc-800 rounded-lg shadow-md">
                  <img
                    src={item?.header}
                    alt={item?.name}
                    className="rounded-lg"
                  />
                  <div className="rounded-lg px-2 flex flex-row justify-between">
                    <div className="flex text-white flex-row items-center">
                      <img src={Signals} alt={"Icon Signals"} />
                      <h3 className="uppercase">{item?.name}</h3>
                    </div>
                    <div className="flex text-white flex-row items-center">
                      <a href={item?.url} target="_blank" rel="noreferrer">
                        <button className="flex gap-2 flex-row rounded-2xl m-4 border-2 border-[#383838] p-2 px-4">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                          </svg>
                          {t("_FREE._VER")}
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="rounded-lg px-2 flex flex-row  bg-[#1F1F1F] ">
                    <div className="flex text-white flex-row items-center my-4">
                      <button className="flex gap-2 flex-row rounded-2xl  border-2 border-[#383838] group-hover:border-indigo-300 p-2 justify-center items-center text-xs">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        {item?.date_start}
                      </button>
                      <hr className="h-px border border-indigo-300 w-10"></hr>
                      {item?.status === "0" ?
                        <>
                          <button className="rounded-2xl  border-2 border-[#383838] p-2 px-4 group-hover:bg-indigo-300 group-hover:text-black text-xs" dangerouslySetInnerHTML={{ __html: "Price Entry: " + formatNumber(item?.priceNative) + " SOL" }} />
                        </>
                        :
                        <>
                          <button className="flex gap-2 flex-row rounded-2xl  border-2 border-[#383838] p-2 px-4 group-hover:bg-indigo-300 group-hover:text-black">
                            {item?.profit}%
                          </button>
                          <hr className="h-px border border-indigo-300 w-10"></hr>
                          <button className="flex gap-2 flex-row rounded-2xl  border-2 border-[#383838] group-hover:border-indigo-300  p-2 justify-center items-center text-xs">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6 rotate-180">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            {item?.date_end}
                          </button>
                        </>}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
