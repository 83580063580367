import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../context/AppContext";

export default function Notifications() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const { notificacions, showNotifications } = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(false);
    const [listNotifications, setListNotifications] = useState([]);
    const [tab, setTab] = useState(0);


    useEffect(() => {
        getNotificationsPackages();
        window.scrollTo(0, 0);
        document.body.classList.add('overflow-y-hidden');
        return () => {
            document.body.classList.remove('overflow-y-hidden');
        };
    }, []);


    useEffect(() => {
        // Inicia un temporizador de 2 segundos al montar el componente
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 100);

        // Limpia el temporizador si el componente se desmonta antes de los 2 segundos
        return () => clearTimeout(timer);
    }, []); // El array vacío [] asegura que el efecto se ejecute solo una vez al montar el componente


    const closeNotifications = () => {
        showNotifications(false);
    }

    const getNotificationsPackages = () => {
        fetch(`${process.env.REACT_APP_BACKEND}/notification?limit=6`, {
            method: "GET",
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                const data = response?.data?.items;
                setListNotifications(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }




    const nameNotification = (name) => {
        if (name === "3") {
            return t("_DASHBOARD._NOTIFICATION3");
        } else if (name === "2") {
            return t("_DASHBOARD._NOTIFICATION2");
        } else if (name === "1") {
            return t("_DASHBOARD._NOTIFICATION1");
        } else if (name === "5") {
            return t("_DASHBOARD._NOTIFICATION5");
        } else if (name === "4") {
            return t("_DASHBOARD._NOTIFICATION4");
        } else if (name === "7") {
            return t("_DASHBOARD._NOTIFICATION7");
        }
    }


    const notificationsGlobal = [
        {
            date: "16/01/2025",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/16-01-2025/Informe+CryptoTradrex+16-01-2025.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/16-01-2025/CryptoTradrex+Report+16-01-2025.pdf"
        },
        {
            date: "31/12/2024",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/30-12-2024/Informe+CryptoTradrex+31-12-2024.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/30-12-2024/CryptoTradrex+Annual+Report+31-12-2024.pdf"
        },
        {
            date: "24/12/2024",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/23-12-2024/Informe+CryptoTradrex+24-12-2024.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/23-12-2024/CryptoTradex+Report+24-12-2024.pdf"
        },
        {
            date: "18/12/2024",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/18-12-2024/Informe+CryptoTradrex+18-12-2024.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/18-12-2024/CryptoTradex+Report+18-12-2024.pdf"
        },
        {
            date: "09/12/2024",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/09-12-2024/Informe+CryptoTradrex+09-12-2024.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/09-12-2024/CryptoTradex+Report+12-09-2024.pdf"
        },
        {
            date: "02/12/2024",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/01-12-2024/Informe+CryptoTradrex+02-12-2024.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/01-12-2024/CryptoTradex+Report+02-12-2024.pdf"
        },
        {
            date: "25/11/2024",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/25-11-2024/Informe+CryptoTradrex+25-11-2024.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/25-11-2024/CryptoTradex+Report+11-25-2024.pdf"
        },
        {
            date: "17/11/2024",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/17-11-2024/Informe+CryptoTradrex+18-11-2024.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/17-11-2024/CryptoTradex+Report+11-18-2024.pdf"
        },
        {
            date: "11/11/2024",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/11-11-2024/Informe+CryptoTradrex+10-11-2024.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/11-11-2024/CryptoTradex+Report+11-10-2024.pdf"
        },
        {
            date: "03/11/2024",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/03-11-2024/Informe+CryptoTradrex+03-11-2024.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/03-11-2024/CryptoTradex%20Report%2011-03-2024.pdf"
        },
        {
            date: "27/10/2024",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/27-10-2024/Informe+CryptoTradrex+27-10-2024.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/27-10-2024/CryptoTradex+Report+10-27-2024.pdf"
        },
        {
            date: "21/10/2024",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/21-10-2024/Informe+CryptoTradrex+21-10-2024.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/21-10-2024/CryptoTradrex+Report++21-10-2024.pdf"
        },
        {
            date: "11/10/2024",
            url: currentLanguage === "Spanish" ? "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/12-10-2024/Compras+Iniciales+CryptoTradrex+11-10-2024.pdf" : "https://cryptotradex.s3.us-west-1.amazonaws.com/newsletter/12-10-2024/Initial+Purchases+CryptoTradrex+11-10-2024.pdf"
        },
    ];

    return (
        <>
            <div
                className={`w-screen absolute h-full`}
                style={{ zIndex: "100", backgroundColor: "#00000091" }}
            >
                <div
                    className={`absolute right-0 custom-button-shadow-two rounded p-10 bg-zinc-800 w-11/12 lg:w-1/4 h-full transition-transform transform overflow-y-auto ${isVisible ? "translate-x-0" : "translate-x-full"
                        }`}
                    style={{
                        transition: "transform 0.5s ease-in-out",
                    }}
                >
                    <div className="flex flex-row justify-between">
                        {/*  <div className="text-lime-300">{t("_SIDEBAR._LANGUAGE")}</div>*/}
                        <div className="cursor-pointer" onClick={closeNotifications}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-6 text-lime-300 text-end"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </div>
                    </div>

                    <div className="flex flex-row text-white gap-2 my-4">
                        <button onClick={() => setTab(0)} className="text-md w-auto border-2  py-1 px-2 cursor-pointer rounded-lg" >{t("_SYSTEM._SYSTEM")}</button>
                        <button onClick={() => setTab(1)} className="text-md w-auto border-2  py-1 px-2 cursor-pointer rounded-lg" >{t("_SYSTEM._GLOBAL")}</button>
                    </div>

                    {tab === 0 && listNotifications.map((item, index) => (
                        <div className="bg-zinc-700/40 p-4 text-white rounded-lg my-4">
                            <h3 className="font2">{nameNotification(item?.action)} </h3>
                            {item?.action !== "2" && (<p className="font2 my-1" >{item?.message ? item?.message : ""}</p>)}
                            <p className="font2 my-1">{item?.value ? item?.value + " USD" : ""}</p>
                            <p className="font2 text-right text-lime-300 " >{item?.created}</p>
                        </div>
                    ))}
                    {tab === 1 && notificationsGlobal.map((item, index) => (
                        <div className="bg-zinc-700/40 p-4 text-white rounded-lg my-4">
                            <h3 className="font2">{t("_SYSTEM._REPORT")} {item?.date}</h3>
                            <p className="font2 text-right text-lime-300 underline cursor-pointer" > <a href={item?.url} target="_blank" rel="noreferrer">PDF</a></p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
