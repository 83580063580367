export const ita = {
  _LOGIN: {
    _EMAIL: "Email",
    _PASSWORD: "Password",
    _REMEMBER: "Ricordami",
    _LOGIN: "ACCEDI",
    _SIGNUP: "Registrati",
    _FORGOTPASSWORD: "Hai dimenticato la password?",
    _NOACCOUNT: "Non hai un account?",
    _ERROR_EMAIL: "Sembra che tu non sia registrato! Riprova.",
    _ERROR_PASSWORD: "Password errata",
    _SUCCESS: "Accesso effettuato con successo",
  },
  _REGISTER: {
    _REGISTER: "Crea un account",
    _NAME: "Nome utente",
    _EMAIL: "La tua email",
    _PASSWORD: "La tua password",
    _CONFIRMPASSWORD: "Conferma la password",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (opzionale)",
    _HAVEACCOUNT: "Ho già un account",
    _ERROR_REFERAL: "Codice referral errato",
    _ERROR_EMAIL: "Questa email esiste già",
    _SUCCESS: "Registrazione avvenuta con successo",
  },
  _SIDEBAR: {
    _PAGES: "Pagine",
    _OPEN: "Apri barra laterale",
    _DASHBOARD: "Home",
    _NETWORK: "Rete",
    _FINANCE: "Portafoglio",
    _PACKAGES: "Piani",
    _SIGNOUT: "Disconnettiti",
    _LANGUAGE: "Lingua",
    _PROFILE: "Profilo",
  },
  _DASHBOARD: {
    _TITLE: "Saldo",
    _TITLE2: "Deposito",
    _TITLE3: "Guadagni totali",
    _TITLE4: "Guadagni Unilevel",
    _TITLE5: "Bonus di rango",
    _TITLE6: "Piano",
    _TOTAL: "Totale",
    _TABLE3: "Lista pacchetti",
    _START: "Inizio",
    _FINISH: "Fine",
    _STATUS: "Stato",
    _STATUS_ACTIVE: "Attivo",
    _STATUS_INACTIVE: "Inattivo",
    _CURRENT: "Corrente",
    _UPGRADE: "Aggiorna",
    _COPYMESSAGE: "Copiato con successo",
    _NOTIFICATION1: "Nuovo deposito",
    _NOTIFICATION2: "Profitto da Cryptotradex",
    _NOTIFICATION3: "Profitto Unilevel",
    _NOTIFICATION4: "Gamma infinita",
    _NOTIFICATION5: "Acquista pacchetto",
    _NOTIFICATION7: "Bonus Ricorrente di Riferimento Diretto",
    _PREV: "Precedente",
    _NEXT: "Successivo",
    _TABLE4_A: "Operazione",
    _TABLE4_B: "Profitto",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Data",
    _SHARED: "Condividi il tuo link di referral",
  },
  _FINANCE: {
    _DEPOSIT: "Deposito",
    _QR: "Scansiona il seguente QR o copia l'indirizzo",
    _NETWORK: "Rete",
    _INSTRUCTIONS: "Istruzioni per l'invio di TRON (TRX)",
    _STEP1: "Assicurati che la rete selezionata sia TRON (TRC20).",
    _STEP2:
      "Invia la quantità di TRON (TRX) al seguente indirizzo di portafoglio.",
    _COPY: "Copia",
    _DISCLAIMER:
      "Disclaimer: Non siamo responsabili per le transazioni effettuate al di fuori della rete TRON (TRC20) o per l'invio di criptovalute diverse da TRON (TRX). Qualsiasi perdita di fondi a causa di questi errori sarà esclusiva responsabilità del mittente.",
    _TABLE1: "Storico dei depositi",
    _TABLE1_A: "Ordine",
    _TABLE1_B: "Importo",
    _TABLE1_C: "Data",
    _TABLE1_D: "Stato",
    _SUCCESS: "Pacchetto aggiunto con successo",
    _COPYMESSAGE: "Indirizzo del portafoglio copiato con successo",
  },
  _PACKAGES: {
    _ATTENTION:
      "Attenzione: Hai un account aziendale. Acquistando un pacchetto, ripristinerai l'account e tutti i suoi benefici.",
    _PACKAGES: "Scegli il miglior piano per te",
    _SUBTITLE: "Hai 8 opzioni",
    _BALANCE: "Saldo del deposito",
    _PACKAGE: "Pacchetto",
    _PACKAGE_1: "Guadagnerai dal livello",
    _PACKAGE_2: "Guadagna fino a ",
    _PACKAGE_3: "ROI giornaliero",
    _BUTTON1: "Pieno",
    _BUTTON2: "Compra",
    _CALCULATE: "Calcola piano",
    _RECHARGE: "Non hai abbastanza denaro, ricarica il tuo portafoglio",
    _PACKAGEMESSAGE: "Devi acquistare un pacchetto più alto",
    _SUCCESS: "Pacchetto aggiunto con successo",
    _UPDATE: "Prezzo di aggiornamento del pacchetto",
  },
  _NETWORK: {
    _TODAY: "Oggi",
    _AVAILABLE: "Disponibile",
    _WITHDRAW: "Totale ritirato",
    _SEARCHLABEL: "Cerca email",
    _SEARCH: "Cerca",
    _NETWORK: "Rete",
    _DIRECTS: "Diretti",
    _SUCCESS: "Ricerca riuscita",
    _ERROR: "Non fa parte del tuo albero binario.",
    _LEVEL: "Livello",
    _USERS: "Utenti",
    _AMOUNT: "Importo",
    _USERNAME: "Nome utente",
    _EMAIL: "Email",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Pacchetto",
  },
  _PROFILE: {
    _TITLE: "Profilo",
    _NAME: "Il tuo nome",
    _EMAIL: "La tua email",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Password attuale",
    _PASSWORD: "Nuova password",
    _CONFIRMPASSWORD: "Conferma nuova password",
    _WALLET: "Indirizzo di prelievo",
    _ACTION: "Modifica",
    _TIPS: "Suggerimenti",
    _TIPS_1: "Il nome deve essere leggibile e non superare i 20 caratteri.",
    _TIPS_2:
      "La password deve includere numeri, lettere e simboli; più è complessa, meglio è.",
    _TIPS_3:
      "L'email non può essere modificata. Se hai bisogno di fare un cambiamento, contatta il supporto.",
    _TIPS_4:
      "L'indirizzo di prelievo può essere modificato solo con un'autenticazione a 2 fattori, che deve essere abilitata in precedenza.",
    _ERROR_PASSWORD: "Errore password precedente",
    _SUCCESS: "Profilo aggiornato con successo",
    _MIN: "Prelievo minimo",
    _UPDATE: "Aggiorna profilo",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Hai dimenticato la password?",
    _EMAIL: "La tua email",
    _BUTTON: "Invia email",
    _MESSAGE: "La tua password è stata inviata al tuo indirizzo email",
    _ERROR_EMAIL: "Questa email non è registrata",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Rango attuale",
    _NEXT: "Prossimo rango",
    _SILVER: "Argento",
    _GOLD: "Oro",
    _RUBY: "Rubino",
    _EMERALD: "Smeraldo",
    _DIAMOND: "Diamante",
    _DIAMONDB: "Diamante Blu",
    _DIAMONDBB: "Diamante Nero",
    _NONE: "Nessuno",
  },
  _AUTH: {
    _TITLEDEFAULT: "Cambia portafoglio di prelievo",
    _TITLE: "Fase 1",
    _TITLE2: "Fase 2",
    _TITLE3: "Fase 3",
    _STEP1: "Scarica e installa l'autenticatore sul tuo dispositivo",
    _STEP2: "Scansiona il codice per salvare",
    _FORMEMAIL: "Verifica email",
    _FORMHELPEMAIL: "Inserisci il codice di verifica a 6 cifre inviato a",
    _FORMHELPAUTH:
      "Inserisci il codice a 6 cifre generato dall'app Authenticator",
    _FORM1: "Pin dell'Authenticator",
    _FORM2: "Indirizzo di prelievo",
    _FORMMIN: "L'indirizzo di prelievo deve essere di 34 caratteri",
    _FORMCODESEND: "Codice inviato",
    _VERIFF: "Verifica",
    _ACTION: "Verifica e salva",
    _ERROR: "Errore, riprova.",
    _SUCCESS: "Modifica avvenuta con successo",
    _RETRYOTP: "Errore nell'invio del codice, riprova.",
    _SENDOTP: "Codice inviato con successo",
    _SUCCESSOTP: "OTP verificato con successo",
    _SUCCESSAUTH2F: "Auth2fa verificato con successo",
    _ERRORCODE: "Codice non valido, riprova",
  },
  _COUNTDOWN: {
    _TITLE: "Prossimo taglio:",
    _DAYS: "Giorni",
    _HOURS: "Ore",
    _MINUTES: "Minuti",
    _SECONDS: "Secondi",
  },
  _WITHDRAWAL: {
    _TITLE: "Transazioni di prelievo",
  },
  _SYSTEM: {
    _SYSTEM: "Sistema",
    _GLOBAL: "Globale",
    _INVESTMENT: "Ritirare l'investimento",
    _INVESTMENTTEXT:
      "Ricorda che avrai una penalità del 35% del tuo investimento",
    _ACCELERATE: "Accelerare",
    _REPORT: "Rapporto di investimento Cryptotradex",
    _PARNERT: "Informazioni sullo sponsor",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Ritira Investimento",
    _MESSAGE:
      "Prelevare il tuo capitale ora comporta una commissione operativa del 35% - guadagni accumulati e verrà elaborato entro 24 ore. Se lo fai, fermerai il suo potenziale di crescita immediata. Decidi con una visione a lungo termine!",
    _BUTTON: "Ritira",
    _BUTTONWAIT: "Ritiro in corso",
  },
  _SIGNAL: {
    _NEWS: "Nuovo",
    _SIGNALS: "Segnali",
    _WHATIS: "Cos'è Cryptotradex Signal?",
    _DESCRIPTION:
      "Unisciti al nostro gruppo esclusivo di segnali e ricevi oltre 10 raccomandazioni giornaliere per massimizzare i tuoi guadagni, con ritorni fino al 200% su investimenti ad alto e basso rischio.",
    _COMMING: "Prossimamente.",
    _SIGNALS10: "SEGNALI10X",
    _CHAT: "Chat esclusiva",
    _DESCRIPTIONGLANDING:
      "Nella nostra chat esclusiva, riceverai segnali in tempo reale e potrai anche contribuire o fare domande su ciascuno di essi in modo organizzato.",
    _TESTIMONIALS: "TESTIMONIANZE",
    _GETDAILY:
      "Ricevi i segnali quotidiani di CTX e inizia a moltiplicare il tuo capitale!",
    _TOBE:
      "Per far parte di Cryptotradex Signals, devi avere un investimento di almeno 1.000 USD.",
    _IWANT: "Voglio i segnali",
    _TESTIMONIALS1:
      "Ho iniziato a usare questi segnali sulla rete Solana e sono riuscito a trasformare i miei 200 USD iniziali in 600 USD in poche ore. Le informazioni fornite tramite Dexscreener hanno fatto la differenza!",
    _TESTIMONIALS2:
      "Con questi segnali in tempo reale, ho costantemente moltiplicato il mio investimento iniziale settimana dopo settimana. La combinazione dei dati Solana e Dexscreener mi ha aiutato a guadagnare più di 3.000 USD in sole 5 settimane!",
    _TESTIMONIALS3:
      "La comunità dietro questi segnali è incredibile. Non solo ho aumentato notevolmente le mie partecipazioni in Solana, ma ho anche trovato un gruppo di grande supporto che mi ha guidato in ogni operazione.",
    _TESTIMONIALS4:
      "All'inizio ero scettico, ma la sinergia tra le opportunità basate su Solana e le informazioni di Dexscreener ha dimostrato che mi sbagliavo. Il mio portafoglio è cresciuto oltre le mie aspettative!",
  },
  _ACADEMY: {
    _NEWS: "Nuovo",
    _TITLE: "Accademia",
    _WHATIS: "Cos'è Cryptotradex Academy?",
    _DESCRIPTION:
      "Impara tutto su criptovalute, wallet, meme e Web3 con la nostra piattaforma online progettata per tutti i livelli.",
    _ACTION1: "Vai all'Accademia",
    _DISABLED: "È richiesto un deposito minimo di 500 USD",
    _TITLANDING: "Diventa un esperto di Cryptomemes",
    _DESCRIPTIONLANDING:
      "Accedi a tutorial esclusivi dal pacchetto BRETT e impara a dominare il mondo dei MEMECOINS.",
    _ACTIONLANDING: "Iscriviti ora e inizia a imparare!",
    _WHATCOURSES: "Quali corsi puoi ottenere?",
    _RECOMMENDS: "Video consigliati",
    _VIDEO1: "Tutorial Wallet Phantom",
    _VIDEO2: "Trova MEMECOINS esplosivi su DEXSCREENER",
    _VIDEO3: "Diventa un esperto di GMGN",
    _VIDEO4: "Il bot perfetto per MEMECOINS",
    _VIDEO5: "Come trovare MEMECOINS con grande potenziale",
  },
  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
