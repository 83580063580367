export const hin = {
  _LOGIN: {
    _EMAIL: "ईमेल",
    _PASSWORD: "पासवर्ड",
    _REMEMBER: "मुझे याद रखें",
    _LOGIN: "लॉग इन",
    _SIGNUP: "साइन अप करें",
    _FORGOTPASSWORD: "क्या आप पासवर्ड भूल गए?",
    _NOACCOUNT: "क्या आपके पास खाता नहीं है?",
    _ERROR_EMAIL: "लगता है कि आप पंजीकृत नहीं हैं! फिर से प्रयास करें।",
    _ERROR_PASSWORD: "गलत पासवर्ड",
    _SUCCESS: "लॉगिन सफल",
  },
  _REGISTER: {
    _REGISTER: "खाता पंजीकृत करें",
    _NAME: "उपयोगकर्ता नाम",
    _EMAIL: "आपका ईमेल",
    _PASSWORD: "आपका पासवर्ड",
    _CONFIRMPASSWORD: "पासवर्ड की पुष्टि करें",
    _WHATSAPP: "व्हाट्सएप",
    _TELEGRAM: "टेलीग्राम (वैकल्पिक)",
    _HAVEACCOUNT: "मेरे पास खाता है",
    _ERROR_REFERAL: "गलत रेफरल कोड",
    _ERROR_EMAIL: "यह ईमेल पहले से मौजूद है",
    _SUCCESS: "पंजीकरण सफल",
  },
  _SIDEBAR: {
    _PAGES: "पृष्ठ",
    _OPEN: "साइडबार खोलें",
    _DASHBOARD: "होम",
    _NETWORK: "नेटवर्क",
    _FINANCE: "वॉलेट",
    _PACKAGES: "योजनाएँ",
    _SIGNOUT: "लॉग आउट",
    _LANGUAGE: "भाषा",
    _PROFILE: "प्रोफ़ाइल",
  },
  _DASHBOARD: {
    _TITLE: "शेष राशि",
    _TITLE2: "जमा",
    _TITLE3: "कुल आय",
    _TITLE4: "यूनिलेवल आय",
    _TITLE5: "रैंक बोनस",
    _TITLE6: "योजना",
    _TOTAL: "कुल",
    _TABLE3: "पैकेज सूची",
    _START: "प्रारंभ",
    _FINISH: "समाप्त",
    _STATUS: "स्थिति",
    _STATUS_ACTIVE: "सक्रिय",
    _STATUS_INACTIVE: "निष्क्रिय",
    _CURRENT: "वर्तमान",
    _UPGRADE: "अपग्रेड करें",
    _COPYMESSAGE: "कॉपी सफल",
    _NOTIFICATION1: "नया जमा",
    _NOTIFICATION2: "Cryptotradex लाभ",
    _NOTIFICATION3: "यूनिलेवल लाभ",
    _NOTIFICATION4: "अनंत सीमा",
    _NOTIFICATION5: "पैकेज खरीदें",
    _NOTIFICATION7: "आवर्ती प्रत्यक्ष रेफरल बोनस",
    _PREV: "पिछला",
    _NEXT: "अगला",
    _TABLE4_A: "ऑपरेशन",
    _TABLE4_B: "लाभ",
    _TABLE4_C: "ROI",
    _TABLE4_D: "तारीख",
    _SHARED: "अपने रेफरल लिंक को साझा करें",
  },
  _FINANCE: {
    _DEPOSIT: "जमा",
    _QR: "निम्नलिखित QR को स्कैन करें या पते को कॉपी करें",
    _NETWORK: "नेटवर्क",
    _INSTRUCTIONS: "TRON (TRX) भेजने के निर्देश",
    _STEP1: "सुनिश्चित करें कि चयनित नेटवर्क TRON (TRC20) है।",
    _STEP2: "निम्नलिखित वॉलेट पते पर TRON (TRX) भेजें।",
    _COPY: "कॉपी करें",
    _DISCLAIMER:
      "अस्वीकरण: हम TRON (TRC20) नेटवर्क के बाहर किए गए लेनदेन या TRON (TRX) के अलावा अन्य क्रिप्टोकरेंसी भेजने के लिए जिम्मेदार नहीं हैं। इन त्रुटियों के कारण होने वाले किसी भी धन हानि की पूरी जिम्मेदारी प्रेषक की होगी।",
    _TABLE1: "जमा इतिहास",
    _TABLE1_A: "ऑर्डर",
    _TABLE1_B: "राशि",
    _TABLE1_C: "तारीख",
    _TABLE1_D: "स्थिति",
    _SUCCESS: "पैकेज सफलतापूर्वक जोड़ा गया",
    _COPYMESSAGE: "वॉलेट पता सफलतापूर्वक कॉपी किया गया",
  },
  _PACKAGES: {
    _ATTENTION:
      "ध्यान दें: आपके पास एक कॉर्पोरेट खाता है। एक पैकेज खरीदने से, आप खाता और इसके सभी लाभों को रीसेट करेंगे।",
    _PACKAGES: "अपने लिए सबसे अच्छा प्लान चुनें",
    _SUBTITLE: "आपके पास 8 विकल्प हैं",
    _BALANCE: "जमा शेष राशि",
    _PACKAGE: "पैकेज",
    _PACKAGE_1: "आप स्तर से कमाएंगे",
    _PACKAGE_2: "तक कमाएं",
    _PACKAGE_3: "दैनिक ROI",
    _BUTTON1: "भरा हुआ",
    _BUTTON2: "खरीदें",
    _CALCULATE: "प्लान की गणना करें",
    _RECHARGE: "आपके पास पर्याप्त धन नहीं है, अपना वॉलेट रिचार्ज करें",
    _PACKAGEMESSAGE: "आपको उच्च पैकेज खरीदना होगा",
    _SUCCESS: "पैकेज सफलतापूर्वक जोड़ा गया",
    _UPDATE: "पैकेज अपडेट की कीमत",
  },
  _NETWORK: {
    _TODAY: "आज",
    _AVAILABLE: "उपलब्ध",
    _WITHDRAW: "कुल निकासी",
    _SEARCHLABEL: "ईमेल खोजें",
    _SEARCH: "खोजें",
    _NETWORK: "नेटवर्क",
    _DIRECTS: "प्रत्यक्ष",
    _SUCCESS: "खोज सफल",
    _ERROR: "आपके बाइनरी ट्री का हिस्सा नहीं है।",
    _LEVEL: "स्तर",
    _USERS: "उपयोगकर्ता",
    _AMOUNT: "राशि",
    _USERNAME: "उपयोगकर्ता नाम",
    _EMAIL: "ईमेल",
    _WHATSAPP: "व्हाट्सएप",
    _PACKAGE: "पैकेज",
  },
  _PROFILE: {
    _TITLE: "प्रोफ़ाइल",
    _NAME: "आपका नाम",
    _EMAIL: "आपका ईमेल",
    _WHATSAPP: "व्हाट्सएप",
    _CURRENTPASSWORD: "वर्तमान पासवर्ड",
    _PASSWORD: "नया पासवर्ड",
    _CONFIRMPASSWORD: "नए पासवर्ड की पुष्टि करें",
    _WALLET: "निकासी पता",
    _ACTION: "संपादित करें",
    _TIPS: "सलाह",
    _TIPS_1: "नाम पढ़ने योग्य होना चाहिए और 20 वर्णों से अधिक नहीं होना चाहिए।",
    _TIPS_2:
      "पासवर्ड में संख्या, अक्षर और प्रतीक शामिल होने चाहिए; जितना जटिल, उतना बेहतर।",
    _TIPS_3:
      "ईमेल को बदला नहीं जा सकता है। यदि आपको कोई बदलाव करने की आवश्यकता है, तो कृपया समर्थन से संपर्क करें।",
    _TIPS_4:
      "निकासी पते को केवल 2-कारक प्रमाणीकरण के साथ संशोधित किया जा सकता है, जिसे पहले से सक्षम किया जाना चाहिए।",
    _ERROR_PASSWORD: "पिछला पासवर्ड त्रुटि",
    _SUCCESS: "प्रोफ़ाइल सफलतापूर्वक अपडेट की गई",
    _MIN: "न्यूनतम निकासी",
    _UPDATE: "प्रोफ़ाइल अपडेट करें",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "क्या आप पासवर्ड भूल गए?",
    _EMAIL: "आपका ईमेल",
    _BUTTON: "ईमेल भेजें",
    _MESSAGE: "आपका पासवर्ड आपके ईमेल पर भेजा गया है",
    _ERROR_EMAIL: "यह ईमेल पंजीकृत नहीं है",
  },
  _GLOBALV: {
    _TITLE: "वैश्विक मूल्य",
    _CURRENT: "वर्तमान रैंक",
    _NEXT: "अगला रैंक",
    _SILVER: "चांदी",
    _GOLD: "सोना",
    _RUBY: "माणिक",
    _EMERALD: "पन्ना",
    _DIAMOND: "हीरा",
    _DIAMONDB: "नीला हीरा",
    _DIAMONDBB: "काला हीरा",
    _NONE: "कोई नहीं",
  },
  _AUTH: {
    _TITLEDEFAULT: "निकासी वॉलेट बदलें",
    _TITLE: "चरण 1",
    _TITLE2: "चरण 2",
    _TITLE3: "चरण 3",
    _STEP1: "अपने डिवाइस पर प्रमाणीकरणकर्ता डाउनलोड और इंस्टॉल करें",
    _STEP2: "सहेजने के लिए कोड को स्कैन करें",
    _FORMEMAIL: "ईमेल सत्यापन",
    _FORMHELPEMAIL: "भेजे गए 6-अंकीय सत्यापन कोड को दर्ज करें",
    _FORMHELPAUTH: "प्रमाणीकरणकर्ता ऐप द्वारा उत्पन्न 6-अंकीय कोड दर्ज करें",
    _FORM1: "प्रमाणीकरणकर्ता कोड",
    _FORM2: "निकासी पता",
    _FORMMIN: "निकासी पते में 34 वर्ण होने चाहिए",
    _FORMCODESEND: "कोड भेजा गया",
    _VERIFF: "सत्यापित करें",
    _ACTION: "जाँचें और सहेजें",
    _ERROR: "त्रुटि, पुनः प्रयास करें।",
    _SUCCESS: "परिवर्तन सफल",
    _RETRYOTP: "कोड भेजने में त्रुटि, पुनः प्रयास करें।",
    _SENDOTP: "कोड सफलतापूर्वक भेजा गया",
    _SUCCESSOTP: "OTP सफलतापूर्वक सत्यापित",
    _SUCCESSAUTH2F: "Auth2fa सफलतापूर्वक सत्यापित",
    _ERRORCODE: "अमान्य कोड, पुनः प्रयास करें",
  },
  _COUNTDOWN: {
    _TITLE: "अगला कटऑफ:",
    _DAYS: "दिन",
    _HOURS: "घंटे",
    _MINUTES: "मिनट",
    _SECONDS: "सेकंड",
  },
  _WITHDRAWAL: {
    _TITLE: "निकासी लेनदेन",
  },
  _SYSTEM: {
    _SYSTEM: "सिस्टम",
    _GLOBAL: "वैश्विक",
    _INVESTMENT: "निवेश वापस लें",
    _INVESTMENTTEXT: "याद रखें कि आपके निवेश का 35% जुर्माना लगेगा",
    _ACCELERATE: "तेज करें",
    _REPORT: "Cryptotradex निवेश रिपोर्ट",
    _PARNERT: "प्रायोजक जानकारी",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "निवेश निकालें",
    _MESSAGE:
      "अब आपका पूंजी निकालने पर 35% ऑपरेशनल शुल्क लगेगा - संचयी लाभ और इसे 24 घंटे के भीतर संसाधित किया जाएगा। यदि आप ऐसा करते हैं, तो आप इसके तात्कालिक विकास क्षमता को रोक देंगे। दीर्घकालिक दृष्टिकोण के साथ निर्णय लें!",
    _BUTTON: "निकालें",
    _BUTTONWAIT: "निकासी प्रक्रिया में है",
  },
  _SIGNAL: {
    _NEWS: "नया",
    _SIGNALS: "सिग्नल",
    _WHATIS: "Cryptotradex Signal क्या है?",
    _DESCRIPTION:
      "हमारे एक्सक्लूसिव सिग्नल ग्रुप से जुड़ें और रोज़ 10 से अधिक सिफारिशें प्राप्त करें, जो आपके लाभ को अधिकतम करने में मदद करेगी, उच्च और निम्न-जोखिम वाले निवेशों में 200% तक का रिटर्न मिलेगा।",
    _COMMING: "जल्द आ रहा है।",
    _SIGNALS10: "सिग्नल10X",
    _CHAT: "एक्सक्लूसिव चैट",
    _DESCRIPTIONGLANDING:
      "हमारे एक्सक्लूसिव चैट में, आपको रियल-टाइम सिग्नल मिलेंगे, और आप प्रत्येक सिग्नल पर सवाल पूछने या योगदान देने के लिए व्यवस्थित रूप से योगदान कर सकते हैं।",
    _TESTIMONIALS: "प्रशंसा पत्र",
    _GETDAILY:
      "CTX के दैनिक सिग्नल प्राप्त करें और अपनी पूंजी को गुणा करना शुरू करें!",
    _TOBE:
      "Cryptotradex Signals का हिस्सा बनने के लिए, आपकी निवेश राशि कम से कम 1,000 USD होनी चाहिए।",
    _IWANT: "मुझे सिग्नल चाहिए",
    _TESTIMONIALS1:
      "मैंने Solana नेटवर्क पर इन सिग्नल्स का उपयोग करना शुरू किया और अपनी प्रारंभिक $200 को केवल कुछ घंटों में $600 में बदल लिया। Dexscreener द्वारा दी गई जानकारी ने सारी फर्क डाला!",
    _TESTIMONIALS2:
      "इन रियल-टाइम सिग्नल्स के साथ, मैंने लगातार अपने प्रारंभिक निवेश को सप्ताह दर सप्ताह बढ़ाया। Solana के डेटा और Dexscreener का संयोजन ने मुझे केवल 5 हफ्तों में $3,000 से अधिक कमाने में मदद की!",
    _TESTIMONIALS3:
      "इन सिग्नल्स के पीछे की समुदाय शानदार है। न केवल मैंने अपनी Solana होल्डिंग्स को काफी बढ़ाया, बल्कि मैंने एक बहुत ही सहायक समूह भी पाया जिसने मुझे हर ट्रेड में मार्गदर्शन किया।",
    _TESTIMONIALS4:
      "शुरुआत में मैं संकोच कर रहा था, लेकिन Solana आधारित अवसरों और Dexscreener की जानकारी के बीच की तालमेल ने मुझे गलत साबित कर दिया। मेरी पोर्टफोलियो उस से कहीं ज्यादा बढ़ी जितना मैंने कभी सोचा था!",
  },
  _ACADEMY: {
    _NEWS: "नया",
    _TITLE: "अकादमी",
    _WHATIS: "Cryptotradex Academy क्या है?",
    _DESCRIPTION:
      "हमारे ऑनलाइन प्लेटफॉर्म के साथ क्रिप्टोकरेंसी, वॉलेट्स, मिम्स और Web3 के बारे में सीखें, जो सभी स्तरों के लिए डिज़ाइन किया गया है।",
    _ACTION1: "अकादमी पर जाएं",
    _DISABLED: "500 USD का न्यूनतम जमा आवश्यक है",
    _TITLANDING: "Cryptomemes के एक्सपर्ट बनें",
    _DESCRIPTIONLANDING:
      "BRETT पैकेज से एक्सक्लूसिव ट्यूटोरियल्स तक पहुंच प्राप्त करें और MEMECOINS की दुनिया में महारत हासिल करें।",
    _ACTIONLANDING: "अब साइन अप करें और सीखना शुरू करें!",
    _WHATCOURSES: "आप कौन से पाठ्यक्रम प्राप्त कर सकते हैं?",
    _RECOMMENDS: "सिफारिश की गई वीडियो",
    _VIDEO1: "Phantom वॉलेट ट्यूटोरियल",
    _VIDEO2: "DEXSCREENER पर विस्फोटक MEMECOINS खोजें",
    _VIDEO3: "GMGN को एक एक्सपर्ट की तरह मास्टर करें",
    _VIDEO4: "MEMECOINS के लिए परफेक्ट बोट",
    _VIDEO5: "कैसे MEMECOINS खोजें जिनमें बड़ा पोटेंशियल हो",
  },
  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
