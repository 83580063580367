export const rus = {
  _LOGIN: {
    _EMAIL: "Электронная почта",
    _PASSWORD: "Пароль",
    _REMEMBER: "Запомнить меня",
    _LOGIN: "ВОЙТИ",
    _SIGNUP: "Зарегистрироваться",
    _FORGOTPASSWORD: "Забыли пароль?",
    _NOACCOUNT: "Нет аккаунта?",
    _ERROR_EMAIL: "Кажется, вы не зарегистрированы! Попробуйте снова.",
    _ERROR_PASSWORD: "Неверный пароль",
    _SUCCESS: "Успешный вход в систему",
  },
  _REGISTER: {
    _REGISTER: "Создать аккаунт",
    _NAME: "Имя пользователя",
    _EMAIL: "Ваш электронный адрес",
    _PASSWORD: "Ваш пароль",
    _CONFIRMPASSWORD: "Подтвердить пароль",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (опционально)",
    _HAVEACCOUNT: "У меня уже есть аккаунт",
    _ERROR_REFERAL: "Неверный реферальный код",
    _ERROR_EMAIL: "Этот адрес электронной почты уже существует",
    _SUCCESS: "Успешная регистрация",
  },
  _SIDEBAR: {
    _PAGES: "Страницы",
    _OPEN: "Открыть боковую панель",
    _DASHBOARD: "Главная",
    _NETWORK: "Сеть",
    _FINANCE: "Кошелёк",
    _PACKAGES: "Планы",
    _SIGNOUT: "Выйти",
    _LANGUAGE: "Язык",
    _PROFILE: "Профиль",
  },
  _DASHBOARD: {
    _TITLE: "Баланс",
    _TITLE2: "Депозит",
    _TITLE3: "Общий заработок",
    _TITLE4: "Unilevel заработок",
    _TITLE5: "Бонус за ранг",
    _TITLE6: "План",
    _TOTAL: "Всего",
    _TABLE3: "Список пакетов",
    _START: "Начало",
    _FINISH: "Конец",
    _STATUS: "Статус",
    _STATUS_ACTIVE: "Активен",
    _STATUS_INACTIVE: "Неактивен",
    _CURRENT: "Текущий",
    _UPGRADE: "Обновить",
    _COPYMESSAGE: "Копирование выполнено успешно",
    _NOTIFICATION1: "Новый депозит",
    _NOTIFICATION2: "Доход от Cryptotradex",
    _NOTIFICATION3: "Доход Unilevel",
    _NOTIFICATION4: "Бесконечный ранг",
    _NOTIFICATION5: "Покупка пакета",
    _NOTIFICATION7: "Повторяющийся бонус за прямую рекомендацию",
    _PREV: "Предыдущий",
    _NEXT: "Следующий",
    _TABLE4_A: "Операция",
    _TABLE4_B: "Доход",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Дата",
    _SHARED: "Поделитесь своей реферальной ссылкой",
  },
  _FINANCE: {
    _DEPOSIT: "Депозит",
    _QR: "Сканируйте QR-код или скопируйте адрес",
    _NETWORK: "Сеть",
    _INSTRUCTIONS: "Инструкции по отправке TRON (TRX)",
    _STEP1: "Убедитесь, что выбранная сеть — это TRON (TRC20).",
    _STEP2: "Отправьте сумму TRON (TRX) на следующий адрес кошелька.",
    _COPY: "Копировать",
    _DISCLAIMER:
      "Отказ от ответственности: Мы не несем ответственности за транзакции, совершенные вне сети TRON (TRC20), или за отправку криптовалюты, отличной от TRON (TRX). Любая потеря средств в результате этих ошибок будет на ответственности отправителя.",
    _TABLE1: "История депозитов",
    _TABLE1_A: "Заказ",
    _TABLE1_B: "Сумма",
    _TABLE1_C: "Дата",
    _TABLE1_D: "Статус",
    _SUCCESS: "Пакет успешно добавлен",
    _COPYMESSAGE: "Адрес кошелька успешно скопирован",
  },
  _PACKAGES: {
    _ATTENTION:
      "Внимание: У вас корпоративный аккаунт. При покупке пакета вы сбросите аккаунт и все его преимущества.",
    _PACKAGES: "Выберите лучший план для вас",
    _SUBTITLE: "У вас есть 8 вариантов",
    _BALANCE: "Баланс депозита",
    _PACKAGE: "Пакет",
    _PACKAGE_1: "Вы начнете зарабатывать с уровня",
    _PACKAGE_2: "Заработайте до ",
    _PACKAGE_3: "Ежедневный ROI",
    _BUTTON1: "Заполнен",
    _BUTTON2: "Купить",
    _CALCULATE: "Рассчитать план",
    _RECHARGE: "У вас недостаточно средств, пополните свой кошелёк",
    _PACKAGEMESSAGE: "Вам нужно купить более высокий пакет",
    _SUCCESS: "Пакет успешно добавлен",
    _UPDATE: "Цена обновления пакета",
  },
  _NETWORK: {
    _TODAY: "Сегодня",
    _AVAILABLE: "Доступно",
    _WITHDRAW: "Всего выведено",
    _SEARCHLABEL: "Поиск по email",
    _SEARCH: "Поиск",
    _NETWORK: "Сеть",
    _DIRECTS: "Прямые",
    _SUCCESS: "Поиск успешно выполнен",
    _ERROR: "Не является частью вашего бинарного дерева.",
    _LEVEL: "Уровень",
    _USERS: "Пользователи",
    _AMOUNT: "Сумма",
    _USERNAME: "Имя пользователя",
    _EMAIL: "Электронная почта",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Пакет",
  },
  _PROFILE: {
    _TITLE: "Профиль",
    _NAME: "Ваше имя",
    _EMAIL: "Ваш email",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Текущий пароль",
    _PASSWORD: "Новый пароль",
    _CONFIRMPASSWORD: "Подтвердите новый пароль",
    _WALLET: "Адрес для вывода",
    _ACTION: "Редактировать",
    _TIPS: "Советы",
    _TIPS_1: "Имя должно быть читаемым и не превышать 20 символов.",
    _TIPS_2:
      "Пароль должен включать числа, буквы и символы; чем сложнее, тем лучше.",
    _TIPS_3:
      "Электронная почта не может быть изменена. Если вам нужно сделать изменение, пожалуйста, напишите в службу поддержки.",
    _TIPS_4:
      "Адрес для вывода может быть изменен только с помощью двухфакторной аутентификации, которая должна быть включена заранее.",
    _ERROR_PASSWORD: "Ошибка предыдущего пароля",
    _SUCCESS: "Профиль успешно обновлен",
    _MIN: "Минимальная сумма для вывода",
    _UPDATE: "Обновить профиль",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Забыли пароль?",
    _EMAIL: "Ваш email",
    _BUTTON: "Отправить email",
    _MESSAGE: "Ваш пароль был отправлен на вашу электронную почту",
    _ERROR_EMAIL: "Этот email не зарегистрирован",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Текущий ранг",
    _NEXT: "Следующий ранг",
    _SILVER: "Серебро",
    _GOLD: "Золото",
    _RUBY: "Рубин",
    _EMERALD: "Изумруд",
    _DIAMOND: "Алмаз",
    _DIAMONDB: "Синий алмаз",
    _DIAMONDBB: "Черный алмаз",
    _NONE: "Нет",
  },
  _AUTH: {
    _TITLEDEFAULT: "Изменить адрес для вывода",
    _TITLE: "Шаг 1",
    _TITLE2: "Шаг 2",
    _TITLE3: "Шаг 3",
    _STEP1: "Загрузите и установите аутентификатор на ваше устройство",
    _STEP2: "Отсканируйте код для сохранения",
    _FORMEMAIL: "Подтверждение по электронной почте",
    _FORMHELPEMAIL: "Введите 6-значный код подтверждения, отправленный на",
    _FORMHELPAUTH:
      "Введите 6-значный код, сгенерированный приложением аутентификатора",
    _FORM1: "Пин аутентификатора",
    _FORM2: "Адрес для вывода",
    _FORMMIN: "Адрес для вывода должен быть длиной 34 символа",
    _FORMCODESEND: "Код отправлен",
    _VERIFF: "Проверить",
    _ACTION: "Проверить и сохранить",
    _ERROR: "Ошибка, попробуйте снова.",
    _SUCCESS: "Изменение успешно",
    _RETRYOTP: "Ошибка при отправке кода, попробуйте снова.",
    _SENDOTP: "Код успешно отправлен",
    _SUCCESSOTP: "OTP успешно подтвержден",
    _SUCCESSAUTH2F: "Двухфакторная аутентификация успешно подтверждена",
    _ERRORCODE: "Неверный код, попробуйте снова",
  },
  _COUNTDOWN: {
    _TITLE: "Следующий отсечка:",
    _DAYS: "Дни",
    _HOURS: "Часы",
    _MINUTES: "Минуты",
    _SECONDS: "Секунды",
  },
  _WITHDRAWAL: {
    _TITLE: "Транзакции вывода",
  },
  _SYSTEM: {
    _SYSTEM: "Система",
    _GLOBAL: "Глобальный",
    _INVESTMENT: "Отозвать инвестицию",
    _INVESTMENTTEXT:
      "Помните, что с вас будет удержано 35% от вашей инвестиции в качестве штрафа",
    _ACCELERATE: "Ускорить",
    _REPORT: "Отчет о вложениях Cryptotradex",
    _PARNERT: "Информация о спонсоре",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Вывести инвестиции",
    _MESSAGE:
      "Снятие вашего капитала сейчас предполагает операционный сбор в размере 35% - накопленные доходы, и это будет обработано в течение 24 часов. Если вы это сделаете, вы остановите его немедленный потенциал роста. Принимайте решение с долгосрочной перспективой!",
    _BUTTON: "Вывести",
    _BUTTONWAIT: "Вывод в процессе",
  },
  _SIGNAL: {
    _NEWS: "Новое",
    _SIGNALS: "Сигналы",
    _WHATIS: "Что такое Cryptotradex Signal?",
    _DESCRIPTION:
      "Присоединяйтесь к нашей эксклюзивной группе сигналов и получайте более 10 рекомендаций ежедневно для максимизации вашей прибыли, с доходностью до 200% на инвестиции с высоким и низким риском.",
    _COMMING: "Скоро.",
    _SIGNALS10: "СИГНАЛЫ10X",
    _CHAT: "Эксклюзивный чат",
    _DESCRIPTIONGLANDING:
      "В нашем эксклюзивном чате вы будете получать сигналы в реальном времени и сможете вносить вклад или задавать вопросы по каждому сигналу организованным способом.",
    _TESTIMONIALS: "ОТЗЫВЫ",
    _GETDAILY:
      "Получайте ежедневные сигналы от CTX и начинайте умножать свой капитал!",
    _TOBE:
      "Чтобы стать частью Cryptotradex Signals, ваш инвестиционный капитал должен составлять не менее 1 000 USD.",
    _IWANT: "Я хочу сигналы",
    _TESTIMONIALS1:
      "Я начал использовать эти сигналы в сети Solana и смог превратить свои первоначальные 200 USD в 600 USD всего за несколько часов. Информация, предоставленная через Dexscreener, сделала всю разницу!",
    _TESTIMONIALS2:
      "С этими сигналами в реальном времени я постоянно увеличивал свою начальную инвестицию из недели в неделю. Комбинация данных Solana и Dexscreener помогла мне заработать более 3 000 USD всего за 5 недель!",
    _TESTIMONIALS3:
      "Сообщество, стоящее за этими сигналами, потрясающее. Я не только значительно увеличил свои активы в Solana, но и нашел отличную группу поддержки, которая помогала мне на каждом шаге.",
    _TESTIMONIALS4:
      "Сначала я был скептически настроен, но синергия между возможностями на базе Solana и информацией от Dexscreener доказала, что я ошибался. Мой портфель вырос намного больше, чем я мог себе представить!",
  },
  _ACADEMY: {
    _NEWS: "Новое",
    _TITLE: "Академия",
    _WHATIS: "Что такое Cryptotradex Academy?",
    _DESCRIPTION:
      "Изучайте криптовалюты, кошельки, мемы и Web3 с нашей онлайн-платформой, разработанной для всех уровней.",
    _ACTION1: "Перейти в Академию",
    _DISABLED: "Необходим минимальный депозит 500 USD",
    _TITLANDING: "Станьте экспертом по Cryptomemes",
    _DESCRIPTIONLANDING:
      "Получите доступ к эксклюзивным учебникам с пакетом BRETT и научитесь управлять миром MEMECOINS.",
    _ACTIONLANDING: "Зарегистрируйтесь сейчас и начните учиться!",
    _WHATCOURSES: "Какие курсы вы можете получить?",
    _RECOMMENDS: "Рекомендуемые видео",
    _VIDEO1: "Учебник по кошельку Phantom",
    _VIDEO2: "Находите взрывные MEMECOINS на DEXSCREENER",
    _VIDEO3: "Освойте GMGN как эксперт",
    _VIDEO4: "Идеальный бот для MEMECOINS",
    _VIDEO5: "Как находить MEMECOINS с большим потенциалом",
  },

  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
