export const fra = {
  _LOGIN: {
    _EMAIL: "Email",
    _PASSWORD: "Mot de passe",
    _REMEMBER: "Se souvenir de moi",
    _LOGIN: "CONNEXION",
    _SIGNUP: "S'inscrire",
    _FORGOTPASSWORD: "Mot de passe oublié ?",
    _NOACCOUNT: "Pas de compte ?",
    _ERROR_EMAIL: "Il semble que vous ne soyez pas enregistré ! Réessayez.",
    _ERROR_PASSWORD: "Mot de passe incorrect",
    _SUCCESS: "Connexion réussie",
  },
  _REGISTER: {
    _REGISTER: "Créer un compte",
    _NAME: "Nom d'utilisateur",
    _EMAIL: "Votre email",
    _PASSWORD: "Votre mot de passe",
    _CONFIRMPASSWORD: "Confirmer le mot de passe",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (optionnel)",
    _HAVEACCOUNT: "J'ai déjà un compte",
    _ERROR_REFERAL: "Code de parrainage incorrect",
    _ERROR_EMAIL: "Cet email existe déjà",
    _SUCCESS: "Inscription réussie",
  },
  _SIDEBAR: {
    _PAGES: "Pages",
    _OPEN: "Ouvrir le menu",
    _DASHBOARD: "Accueil",
    _NETWORK: "Réseau",
    _FINANCE: "Portefeuille",
    _PACKAGES: "Plans",
    _SIGNOUT: "Se déconnecter",
    _LANGUAGE: "Langue",
    _PROFILE: "Profil",
  },
  _DASHBOARD: {
    _TITLE: "Solde",
    _TITLE2: "Dépôt",
    _TITLE3: "Gains totaux",
    _TITLE4: "Gains Unilevel",
    _TITLE5: "Bonus de rang",
    _TITLE6: "Plan",
    _TOTAL: "Total",
    _TABLE3: "Liste des forfaits",
    _START: "Début",
    _FINISH: "Fin",
    _STATUS: "Statut",
    _STATUS_ACTIVE: "Actif",
    _STATUS_INACTIVE: "Inactif",
    _CURRENT: "Actuel",
    _UPGRADE: "Améliorer",
    _COPYMESSAGE: "Copie réussie",
    _NOTIFICATION1: "Nouveau dépôt",
    _NOTIFICATION2: "Profit de Cryptotradex",
    _NOTIFICATION3: "Profit Unilevel",
    _NOTIFICATION4: "Rang infini",
    _NOTIFICATION5: "Achat de forfait",
    _NOTIFICATION7: "Prime de Référence Directe Récurrente",
    _PREV: "Précédent",
    _NEXT: "Suivant",
    _TABLE4_A: "Opération",
    _TABLE4_B: "Profit",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Date",
    _SHARED: "Partagez votre lien de parrainage",
  },
  _FINANCE: {
    _DEPOSIT: "Dépôt",
    _QR: "Scannez le QR code suivant ou copiez l'adresse",
    _NETWORK: "Réseau",
    _INSTRUCTIONS: "Instructions pour envoyer du TRON (TRX)",
    _STEP1: "Assurez-vous que le réseau sélectionné est TRON (TRC20).",
    _STEP2:
      "Envoyez la quantité de TRON (TRX) à l'adresse de portefeuille suivante.",
    _COPY: "Copier",
    _DISCLAIMER:
      "Avertissement : Nous ne sommes pas responsables des transactions effectuées en dehors du réseau TRON (TRC20) ou de l'envoi de cryptomonnaies autres que TRON (TRX). Toute perte de fonds due à ces erreurs sera la seule responsabilité de l'expéditeur.",
    _TABLE1: "Historique des dépôts",
    _TABLE1_A: "Commande",
    _TABLE1_B: "Montant",
    _TABLE1_C: "Date",
    _TABLE1_D: "Statut",
    _SUCCESS: "Le forfait a été ajouté avec succès",
    _COPYMESSAGE: "Adresse du portefeuille copiée avec succès",
  },
  _PACKAGES: {
    _ATTENTION:
      "Attention : Vous avez un compte entreprise. En achetant un forfait, vous réinitialiserez le compte et tous ses avantages.",
    _PACKAGES: "Choisissez le meilleur plan pour vous",
    _SUBTITLE: "Vous avez 8 options",
    _BALANCE: "Solde du dépôt",
    _PACKAGE: "Forfait",
    _PACKAGE_1: "Vous gagnerez à partir du niveau",
    _PACKAGE_2: "Gagnez jusqu'à ",
    _PACKAGE_3: "ROI quotidien",
    _BUTTON1: "Complet",
    _BUTTON2: "Acheter",
    _CALCULATE: "Calculer le plan",
    _RECHARGE: "Vous n'avez pas assez d'argent, rechargez votre portefeuille",
    _PACKAGEMESSAGE: "Vous devez acheter un forfait supérieur",
    _SUCCESS: "Le forfait a été ajouté avec succès",
    _UPDATE: "Prix de mise à jour du forfait",
  },
  _NETWORK: {
    _TODAY: "Aujourd'hui",
    _AVAILABLE: "Disponible",
    _WITHDRAW: "Total retiré",
    _SEARCHLABEL: "Recherche par email",
    _SEARCH: "Rechercher",
    _NETWORK: "Réseau",
    _DIRECTS: "Parrainages directs",
    _SUCCESS: "Recherche réussie",
    _ERROR: "Ne fait pas partie de votre arbre binaire.",
    _LEVEL: "Niveau",
    _USERS: "Utilisateurs",
    _AMOUNT: "Montant",
    _USERNAME: "Nom d'utilisateur",
    _EMAIL: "Email",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Forfait",
  },
  _PROFILE: {
    _TITLE: "Profil",
    _NAME: "Votre nom",
    _EMAIL: "Votre email",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Mot de passe actuel",
    _PASSWORD: "Nouveau mot de passe",
    _CONFIRMPASSWORD: "Confirmer le nouveau mot de passe",
    _WALLET: "Adresse de retrait",
    _ACTION: "Modifier",
    _TIPS: "Conseils",
    _TIPS_1: "Le nom doit être lisible et ne pas dépasser 20 caractères.",
    _TIPS_2:
      "Le mot de passe doit inclure des chiffres, des lettres et des symboles ; plus il est complexe, mieux c'est.",
    _TIPS_3:
      "L'email ne peut pas être modifié. Si vous avez besoin de faire un changement, veuillez contacter le support.",
    _TIPS_4:
      "L'adresse de retrait ne peut être modifiée qu'avec une authentification à deux facteurs, qui doit être activée au préalable.",
    _ERROR_PASSWORD: "Erreur de mot de passe précédent",
    _SUCCESS: "Profil mis à jour avec succès",
    _MIN: "Retrait minimum",
    _UPDATE: "Mettre à jour le profil",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Mot de passe oublié ?",
    _EMAIL: "Votre email",
    _BUTTON: "Envoyer un email",
    _MESSAGE: "Votre mot de passe a été envoyé à votre email",
    _ERROR_EMAIL: "Cet email n'est pas enregistré",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Rang actuel",
    _NEXT: "Prochain rang",
    _SILVER: "Argent",
    _GOLD: "Or",
    _RUBY: "Rubis",
    _EMERALD: "Émeraude",
    _DIAMOND: "Diamant",
    _DIAMONDB: "Diamant bleu",
    _DIAMONDBB: "Diamant noir",
    _NONE: "Aucun",
  },
  _AUTH: {
    _TITLEDEFAULT: "Changer l'adresse de retrait",
    _TITLE: "Étape 1",
    _TITLE2: "Étape 2",
    _TITLE3: "Étape 3",
    _STEP1: "Téléchargez et installez l'authenticator sur votre appareil",
    _STEP2: "Scannez le code pour sauvegarder",
    _FORMEMAIL: "Vérification de l'email",
    _FORMHELPEMAIL: "Entrez le code de vérification à 6 chiffres envoyé à",
    _FORMHELPAUTH:
      "Entrez le code à 6 chiffres généré par l'application Authenticator",
    _FORM1: "Code de l'Authenticator",
    _FORM2: "Adresse de retrait",
    _FORMMIN: "L'adresse de retrait doit comporter 34 caractères",
    _FORMCODESEND: "Code envoyé",
    _VERIFF: "Vérifier",
    _ACTION: "Vérifier et enregistrer",
    _ERROR: "Erreur, réessayez.",
    _SUCCESS: "Changement réussi",
    _RETRYOTP: "Erreur d'envoi du code, réessayez.",
    _SENDOTP: "Code envoyé avec succès",
    _SUCCESSOTP: "OTP vérifié avec succès",
    _SUCCESSAUTH2F: "Auth2fa vérifié avec succès",
    _ERRORCODE: "Code invalide, réessayez",
  },
  _COUNTDOWN: {
    _TITLE: "Prochaine coupure :",
    _DAYS: "Jours",
    _HOURS: "Heures",
    _MINUTES: "Minutes",
    _SECONDS: "Secondes",
  },
  _WITHDRAWAL: {
    _TITLE: "Transactions de retrait",
  },
  _SYSTEM: {
    _SYSTEM: "Système",
    _GLOBAL: "Global",
    _INVESTMENT: "Retirer l'investissement",
    _INVESTMENTTEXT:
      "N'oubliez pas que vous aurez une pénalité de 35% de votre investissement",
    _ACCELERATE: "Accélérer",
    _REPORT: "Rapport d'investissement Cryptotradex",
    _PARNERT: "Informations sur le sponsor",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Retirer l'investissement",
    _MESSAGE:
      "Retirer votre capital maintenant implique des frais opérationnels de 35% - gains accumulés et sera traité dans les 24 heures. Si vous faites cela, vous arrêterez son potentiel de croissance immédiat. Décidez avec une vision à long terme !",
    _BUTTON: "Retirer",
    _BUTTONWAIT: "Retrait en cours",
  },
  _SIGNAL: {
    _NEWS: "Nouveau",
    _SIGNALS: "Signaux",
    _WHATIS: "Qu'est-ce que Cryptotradex Signal ?",
    _DESCRIPTION:
      "Rejoignez notre groupe exclusif de signaux et recevez plus de 10 recommandations quotidiennes pour maximiser vos profits, avec des rendements allant jusqu'à 200% dans des investissements à haut et bas risque.",
    _COMMING: "Bientôt disponible.",
    _SIGNALS10: "SIGNAUX10X",
    _CHAT: "Chat exclusif",
    _DESCRIPTIONGLANDING:
      "Dans notre chat exclusif, vous recevrez des signaux en temps réel et pourrez également contribuer ou poser des questions sur chacun d'eux de manière organisée.",
    _TESTIMONIALS: "TÉMOIGNAGES",
    _GETDAILY:
      "Recevez les signaux quotidiens de CTX et commencez à multiplier votre capital !",
    _TOBE:
      "Pour faire partie de Cryptotradex Signals, vous devez avoir un investissement d'au moins 1 000 USD.",
    _IWANT: "Je veux les signaux",
    _TESTIMONIALS1:
      "J'ai commencé à utiliser ces signaux sur le réseau Solana et j'ai réussi à transformer mes 200 USD initiaux en 600 USD en quelques heures seulement. Les informations fournies par Dexscreener ont fait toute la différence !",
    _TESTIMONIALS2:
      "Avec ces signaux en temps réel, j'ai constamment multiplié mon investissement initial semaine après semaine. La combinaison des données Solana et Dexscreener m'a aidé à gagner plus de 3 000 USD en seulement 5 semaines !",
    _TESTIMONIALS3:
      "La communauté derrière ces signaux est incroyable. Non seulement j'ai considérablement augmenté mes avoirs en Solana, mais j'ai également trouvé un groupe de soutien formidable qui m'a guidé à chaque transaction.",
    _TESTIMONIALS4:
      "Au début, j'étais sceptique, mais la synergie entre les opportunités basées sur Solana et les informations de Dexscreener m'a prouvé que j'avais tort. Mon portefeuille a beaucoup plus grandi que ce que j'avais imaginé !",
  },
  _ACADEMY: {
    _NEWS: "Nouveau",
    _TITLE: "Académie",
    _WHATIS: "Qu'est-ce que Cryptotradex Academy ?",
    _DESCRIPTION:
      "Apprenez tout sur les crypto-monnaies, les portefeuilles, les mèmes et le Web3 grâce à notre plateforme en ligne conçue pour tous les niveaux.",
    _ACTION1: "Aller à l'Académie",
    _DISABLED: "Un dépôt minimum de 500 USD est requis",
    _TITLANDING: "Devenez un expert en Cryptomèmes",
    _DESCRIPTIONLANDING:
      "Accédez à des tutoriels exclusifs à partir du forfait BRETT et apprenez à maîtriser le monde des MEMECOINS.",
    _ACTIONLANDING: "Inscrivez-vous maintenant et commencez à apprendre !",
    _WHATCOURSES: "Quels cours pouvez-vous obtenir ?",
    _RECOMMENDS: "Vidéos recommandées",
    _VIDEO1: "Tutoriel Portefeuille Phantom",
    _VIDEO2: "Trouvez des MEMECOINS explosifs sur DEXSCREENER",
    _VIDEO3: "Maîtrisez GMGN comme un expert",
    _VIDEO4: "Le bot parfait pour les MEMECOINS",
    _VIDEO5: "Comment trouver des MEMECOINS avec un grand potentiel",
  },
  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
