export const eng = {
  _LOGIN: {
    _EMAIL: "Email",
    _PASSWORD: "Password",
    _REMEMBER: "Remember me",
    _LOGIN: "LOGIN",
    _SIGNUP: "Sign up",
    _FORGOTPASSWORD: "Forgot your password?",
    _NOACCOUNT: "Don't have an account?",
    _ERROR_EMAIL: "Apparently you are not registered! Try again.",
    _ERROR_PASSWORD: "Incorrect password",
    _SUCCESS: "Login Success",
  },
  _REGISTER: {
    _REGISTER: "Register Account",
    _NAME: "Username",
    _EMAIL: "Your email",
    _PASSWORD: "Your password",
    _CONFIRMPASSWORD: "Confirm Password",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (optional)",
    _HAVEACCOUNT: "I have an account",
    _ERROR_REFERAL: "Wrong referral code",
    _ERROR_EMAIL: "This email already exists",
    _SUCCESS: "Registration Success",
  },
  _SIDEBAR: {
    _PAGES: "Pages",
    _OPEN: "Open sidebar",
    _DASHBOARD: "Home",
    _NETWORK: "Network",
    _FINANCE: "Wallet",
    _PACKAGES: "Plans",
    _SIGNOUT: "Sign Out",
    _LANGUAGE: "Language",
    _PROFILE: "Profile",
  },
  _DASHBOARD: {
    _TITLE: "Balance",
    _TITLE2: "Deposit",
    _TITLE3: "Total Earnings",
    _TITLE4: "Unilevel Earnings",
    _TITLE5: "Rank Bonus",
    _TITLE6: "Plan",
    _TOTAL: "Total",
    _TABLE3: "Packages List",
    _START: "Start",
    _FINISH: "Finish",
    _STATUS: "Status",
    _STATUS_ACTIVE: "Active",
    _STATUS_INACTIVE: "Inactive",
    _CURRENT: "Current",
    _UPGRADE: "Upgrade",
    _COPYMESSAGE: "Copy successful",
    _NOTIFICATION1: "New Deposit",
    _NOTIFICATION2: "Cryptotradex Profit",
    _NOTIFICATION3: "Unilevel Profit",
    _NOTIFICATION4: "Range Infinite",
    _NOTIFICATION5: "Purchase Package",
    _NOTIFICATION7: "Recurring Direct Referral Bonus",
    _PREV: "Previous",
    _NEXT: "Next",
    _TABLE4_A: "Operation",
    _TABLE4_B: "Profit",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Date",
    _SHARED: "Share your referral link",
  },
  _FINANCE: {
    _DEPOSIT: "Deposit",
    _QR: "Scan the following QR or copy the address",
    _NETWORK: "Network",
    _INSTRUCTIONS: "Instructions for Sending TRON (TRX)",
    _STEP1: "Make sure the selected network is TRON (TRC20).",
    _STEP2: "Send the amount of TRON (TRX) to the following wallet address.",
    _COPY: "Copy",
    _DISCLAIMER:
      "Disclaimer: We are not responsible for transactions made outside the TRON (TRC20) network or for sending cryptocurrencies other than TRON (TRX). Any loss of funds due to these errors will be the sole responsibility of the sender.",
    _TABLE1: "Deposit History",
    _TABLE1_A: "Order",
    _TABLE1_B: "Amount",
    _TABLE1_C: "Date",
    _TABLE1_D: "Status",
    _SUCCESS: "The package was added successfully",
    _COPYMESSAGE: "Wallet address copied successfully",
  },
  _PACKAGES: {
    _ATTENTION:
      "Attention: You have a corporate account. By purchasing a package, you will restart the account and all its benefits.",
    _PACKAGES: "Select the best plan for you",
    _SUBTITLE: "You have 8 options",
    _BALANCE: "Balance Deposit",
    _PACKAGE: "Package ",
    _PACKAGE_1: "You will earn up to level ",
    _PACKAGE_2: "Earn up to",
    _PACKAGE_3: "Daily ROI",
    _BUTTON1: "Filled",
    _BUTTON2: "Buy",
    _CALCULATE: "Calculate Plan",
    _RECHARGE: "You don't have enough money, recharge your wallet",
    _PACKAGEMESSAGE: "You must purchase a higher package",
    _SUCCESS: "The package was added successfully",
    _UPDATE: "Package upgrade price",
  },
  _NETWORK: {
    _TODAY: "Today",
    _AVAILABLE: "Available",
    _WITHDRAW: "Total Withdrawn",
    _SEARCHLABEL: "Email Search",
    _SEARCH: "Search",
    _NETWORK: "Network",
    _DIRECTS: "Directs",
    _SUCCESS: "Search Successful",
    _ERROR: "Not part of your tree.",
    _LEVEL: "Level",
    _USERS: "Users",
    _AMOUNT: "Amount",
    _USERNAME: "Username",
    _EMAIL: "Email",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Package",
  },
  _PROFILE: {
    _TITLE: "Profile",
    _NAME: "Your name",
    _EMAIL: "Your email",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Current password",
    _PASSWORD: "New password",
    _CONFIRMPASSWORD: "Confirm new password",
    _WALLET: "Withdrawal address",
    _ACTION: "Edit",
    _TIPS: "Tips",
    _TIPS_1: "The name must be legible and not exceed 20 characters.",
    _TIPS_2:
      "The password must include numbers, letters, and symbols; the more complex, the better.",
    _TIPS_3:
      "The email cannot be changed. If you need to make a change, please write to support.",
    _TIPS_4:
      "The withdrawal address can only be modified with a 2-factor authenticator, which must be enabled previously.",
    _ERROR_PASSWORD: "Previous password error",
    _SUCCESS: "Profile updated successfully",
    _MIN: "Minimum withdrawal",
    _UPDATE: "Update Profile",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Forgot your password?",
    _EMAIL: "Your email",
    _BUTTON: "Send email",
    _MESSAGE: "Your password was sent to your email",
    _ERROR_EMAIL: "This email is not registered",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Current Rank",
    _NEXT: "Next Rank",
    _SILVER: "Silver",
    _GOLD: "Gold",
    _RUBY: "Ruby",
    _EMERALD: "Emerald",
    _DIAMOND: "Diamond",
    _DIAMONDB: "Diamond Blue",
    _DIAMONDBB: "Diamond Black",
    _NONE: "None",
  },
  _AUTH: {
    _TITLEDEFAULT: "Change withdrawal wallet",
    _TITLE: "Step 1",
    _TITLE2: "Step 2",
    _TITLE3: "Step 3",
    _STEP1: "Download and install the authenticator on your device",
    _STEP2: "Scan the code to save",
    _FORMEMAIL: "Email Verification",
    _FORMHELPEMAIL: "Enter the 6-digit verification code sent to",
    _FORMHELPAUTH: "Enter the 6-digit code generated by the Authenticator App",
    _FORM1: "Pin by Authenticator",
    _FORM2: "Withdrawal address",
    _FORMMIN: "The withdrawal address must be 34 characters long",
    _FORMCODESEND: "Code Send",
    _VERIFF: "Veriff",
    _ACTION: "Check and Save",
    _ERROR: "Error, try again.",
    _SUCCESS: "Change successful",
    _RETRYOTP: "Error sending the code, try again.",
    _SENDOTP: "Code sent successfully",
    _SUCCESSOTP: "OTP Satisfactorily Verified",
    _SUCCESSAUTH2F: "Auth2fa Satisfactorily Verified",
    _ERRORCODE: "Invalid code try again",
  },
  _COUNTDOWN: {
    _TITLE: "Next cut:",
    _DAYS: "Days",
    _HOURS: "Hours",
    _MINUTES: "Minutes",
    _SECONDS: "Seconds",
  },
  _WITHDRAWAL: {
    _TITLE: "Withdrawal Transactions",
  },
  _SYSTEM: {
    _SYSTEM: "System",
    _GLOBAL: "Global",
    _INVESTMENT: "Withdraw investment",
    _INVESTMENTTEXT:
      "Remember that you will have a penalty of 35% of your investment",
    _ACCELERATE: "Accelerate",
    _REPORT: "Cryptotradex Investment Report",
    _PARNERT: "Sponsor information",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Withdraw Investment",
    _MESSAGE:
      "Withdrawing your capital now involves a 35% operational fee - accumulated earnings, and it will be processed within 24 hours. If you do this, you will stop its immediate growth potential. Decide with a long-term vision!",
    _BUTTON: "Withdraw",
    _BUTTONWAIT: "Withdrawal in process",
  },
  _SIGNAL: {
    _NEWS: "New",
    _SIGNALS: "Signals",
    _WHATIS: "What is Cryptotradex Signal?",
    _DESCRIPTION:
      "Join our exclusive Signals group and receive more than 10 daily recommendations to maximize your profits, with returns of up to 200% on high and low-risk investments.",
    _COMMING: "Coming soon.",
    _SIGNALS10: "SIGNALS10X",
    _CHAT: "Exclusive chat",
    _DESCRIPTIONGLANDING:
      "In our exclusive chat, you will receive real-time signals, and you can also contribute or ask questions about each one in an organized manner.",
    _TESTIMONIALS: "TESTIMONIALS",
    _GETDAILY: "Receive daily CTX signals and start multiplying your capital!",
    _TOBE:
      "To be part of Cryptotradex Signals, you must have an investment of $1,000 USD or more.",
    _IWANT: "I want the signals",
    _TESTIMONIALS1:
      "I started using these signals on the Solana network and turned my initial $200 into $600 in just a few hours. The information provided through Dexscreener made all the difference!",
    _TESTIMONIALS2:
      "With these real-time signals, I’ve consistently multiplied my initial investment week after week. The combination of Solana data and Dexscreener helped me earn over $3000 in just 5 weeks!",
    _TESTIMONIALS3:
      "The community behind these signals is amazing. Not only did I significantly increase my Solana holdings, but I also found a supportive group that guided me through each trade.",
    _TESTIMONIALS4:
      "At first, I was skeptical, but the synergy between Solana-based opportunities and Dexscreener’s information proved me wrong. My portfolio has grown beyond what I ever imagined!",
  },
  _ACADEMY: {
    _NEWS: "New",
    _TITLE: "Academy",
    _WHATIS: "What is Cryptotradex Academy?",
    _DESCRIPTION:
      "Learn about cryptocurrencies, wallets, memes, and Web3 with our online platform designed for all levels.",
    _ACTION1: "Go to the Academy",
    _DISABLED: "A minimum deposit of 500 USD is required",
    _TITLANDING: "Become an expert in Cryptomemes",
    _DESCRIPTIONLANDING:
      "Access exclusive tutorials from the BRETT package and learn to master the world of MEMECOINS.",
    _ACTIONLANDING: "Sign up now and start learning!",
    _WHATCOURSES: "What courses will you get?",
    _RECOMMENDS: "Recommended videos",
  },
  _SIGNAL: {
    _NEWS: "New",
    _SIGNALS: "Signals",
    _WHATIS: "What is Cryptotradex Signal?",
    _DESCRIPTION:
      "Join our exclusive Signal group and receive over 10 daily recommendations to maximize your profits, with returns of up to 200% in high and low-risk investments.",
    _COMMING: "Coming soon.",
    _SIGNALS10: "SIGNALS10X",
    _CHAT: "Exclusive Chat",
    _DESCRIPTIONGLANDING:
      "In our exclusive chat, you will receive real-time signals, and you can also contribute or ask questions about each one in an organized manner.",
    _TESTIMONIALS: "TESTIMONIALS",
    _GETDAILY: "Get daily CTX signals and start multiplying your capital!",
    _TOBE:
      "To be part of Cryptotradex Signals, you must have an investment of at least $1,000 USD.",
    _IWANT: "I want the signals",
    _TESTIMONIALS1:
      "I started using these signals on the Solana network and was able to turn my initial $200 into $600 in just a few hours. The information provided through Dexscreener made all the difference!",
    _TESTIMONIALS2:
      "With these real-time signals, I have consistently multiplied my initial investment week after week. The combination of Solana data and Dexscreener helped me earn over $3,000 in just 5 weeks!",
    _TESTIMONIALS3:
      "The community behind these signals is amazing. Not only did I significantly increase my Solana holdings, but I also found a great support group that guided me through every trade.",
    _TESTIMONIALS4:
      "At first, I was skeptical, but the synergy between Solana-based opportunities and Dexscreener’s information proved me wrong. My portfolio has grown beyond what I ever imagined!",
  },
  _ACADEMY: {
    _NEWS: "New",
    _TITLE: "Academy",
    _WHATIS: "What is Cryptotradex Academy?",
    _DESCRIPTION:
      "Learn about cryptocurrencies, wallets, memes, and Web3 with our online platform designed for all levels.",
    _ACTION1: "Go to Academy",
    _DISABLED: "A minimum deposit of $500 USD is required",
    _TITLANDING: "Become a Cryptomemes Expert",
    _DESCRIPTIONLANDING:
      "Access exclusive tutorials starting from the BRETT package and learn to master the world of MEMECOINS.",
    _ACTIONLANDING: "Sign up now and start learning!",
    _WHATCOURSES: "What courses can you get?",
    _RECOMMENDS: "Recommended videos",
    _VIDEO1: "Phantom Wallet Tutorial",
    _VIDEO2: "Find explosive MEMECOINS on DEXSCREENER",
    _VIDEO3: "Master GMGN like an expert",
    _VIDEO4: "The Perfect Bot for MEMECOINS",
    _VIDEO5: "How to find MEMECOINS with great potential",
  },
  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
