import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { json, Link, useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import AppContext from "../context/AppContext";
import Signals from "../assets/svg/signals-icon.svg";

const AdminSignals = () => {
  const navigate = useNavigate();
  const { loading, updateLoading } = useContext(AppContext);
  const { register, handleSubmit, setValue, watch, reset, formState: { errors }, } = useForm();
  const [tokenSearch, setTokenSearch] = useState([]);
  const [data, setData] = useState([]);


  const onSubmit = async () => {
    if (!data) {
      alert("No existe ningun token!");
      return;
    }
    updateLoading(true);
    const formData = new FormData();
    formData.append("url", data.url);
    formData.append("token", data.token);
    formData.append("name", data.name);
    formData.append("symbol", data.symbol);
    formData.append("priceNative", data.priceNative);
    formData.append("priceUsd", data.priceUsd);
    formData.append("fdv", data.fdv);
    formData.append("marketCap", data.marketCap);
    formData.append("imageUrl", data.imageUrl);
    formData.append("header", data.header);
    formData.append("website", data.website);
    formData.append("social", data.social);
    fetch(`${process.env.REACT_APP_BACKEND}/signals`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          toast.error("Error al crear señal");
          updateLoading(false);
        } else {
          toast.success("Se agrego la señal");
          reset();
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };


  const handleFetch = async (token) => {
    if (!tokenSearch) {
      alert("Please enter a token value!");
      return;
    }
    updateLoading(true);
    try {
      const response = await fetch(`https://api.dexscreener.com/token-pairs/v1/solana/${token}`);
      const jsonData = await response.json();
      const extractedData = jsonData.map(item => ({
        url: item.url,
        token: item.baseToken.address,
        name: item.baseToken.name,
        symbol: item.baseToken.symbol,
        priceNative: item.priceNative,
        priceUsd: item.priceUsd,
        fdv: item.fdv,
        marketCap: item.marketCap,
        imageUrl: item.info.imageUrl,
        header: item.info.header,
        website: item.info.websites[0]?.url,
        social: item.info.socials?.find(social => social.type === 'twitter')?.url,
      }));
      setData(extractedData[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      updateLoading(false);
    }
  };

  return (
    <div className="container mx-auto h-auto flex flex-col lg:flex-row gap-4 p-4">
      <div className="flex flex-col lg:flex-row gap-4 p-4 h-auto w-full lg:w-1/2 ">
        <div className="relative overflow-x-auto w-full">
          {data?.header && (
            <>
              <div className="rounded-lg text-white flex flex-col mb-4 relative">
                <div className="h-auto flex flex-col z-10 w-full border-zinc-600 border-lime-300 border-2 bg-zinc-800 rounded-lg shadow-md">
                  <img
                    src={data?.header}
                    alt={data?.name}
                    className="rounded-lg"
                  />
                  <div className="rounded-lg px-2 flex flex-row justify-between">
                    <div className="flex text-white flex-row items-center">
                      <img src={data?.imageUrl || Signals} alt={"Icon Signals"} className="rounded-lg" />
                      <h3 className="ml-2 uppercase">{data?.name} - {data?.symbol}</h3>
                    </div>
                    <div className="flex text-white flex-row items-center">
                      <a href={data?.url} target="_blank" rel="noreferrer">
                        <button className="flex gap-2 flex-row rounded-2xl my-4 border-2 border-[#383838] p-2 px-4">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                          </svg>
                          Ir
                        </button>
                      </a>
                      <a href={data?.website} target="_blank" rel="noreferrer">
                        <button className="flex gap-2 flex-row rounded-2xl m-4 border-2 border-[#383838] p-2 px-4">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                          </svg>
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="rounded-lg px-2 flex flex-row bg-[#1F1F1F] p-4">
                    <div className="flex text-white flex-row items-center justify-center my-1 gap-4 mx-auto">
                      <div className="flex flex-col gap-2 rounded-2xl  border-2 border-[#383838] border-lime-300 p-2 justify-center items-center w-40">
                        <span className='text-xs'>Precio USD</span>
                        <strong>{data?.priceUsd}</strong>
                      </div>
                      <div className="flex flex-col gap-2 rounded-2xl  border-2 border-[#383838] border-lime-300 p-2 justify-center items-center w-40">
                        <span className='text-xs'>Precio </span>
                        <strong>{data?.priceUsd}</strong>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-lg px-2 flex flex-row  mx-auto">
                    <div className="flex text-white flex-row items-center my-4">
                      <button className="flex gap-2 flex-col rounded-2xl  border-2 border-[#383838] border-lime-300 p-2 justify-center items-center text-xs">
                        <span>Liquidez</span>
                        <strong>{data?.priceUsd}</strong>
                      </button>
                      <hr className="h-px border border-lime-300 w-10"></hr>
                      <button className="flex gap-2 flex-col rounded-2xl  border-2 border-[#383838] border-lime-300 p-2 justify-center items-center text-xs">
                        <span>FDV</span>
                        <strong>{data?.fdv}</strong>
                      </button>
                      <hr className="h-px border border-lime-300 w-10"></hr>
                      <button className="flex gap-2 flex-col rounded-2xl  border-2 border-[#383838] border-lime-300 p-2 justify-center items-center text-xs">
                        <span>MKT CAP</span>
                        <strong>{data?.marketCap}</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="w-full lg:w-1/2  text-white p-4 bg-zinc-800 text-white p-6 rounded-lg border-lime-600 hover:border-lime-300 border-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="email">
              Dirección de Token
            </label>
            <div className="flex flex-col lg:flex-row gap-4">
              <input
                type="text"
                id="token"
                {...register('token', { required: true })}
                placeholder="ERKbvKU1Md4AXNyzWQbagRJWpGE7rwUxGep9ESaxpump"
                className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4"
              />
              <button
                type="button"
                onClick={() => handleFetch(watch('token'))}
                className="text-md border-2 p-1 cursor-pointer rounded-lg w-full lg:w-32"
              >
                Buscar
              </button>
            </div>
          </div>
          <div className="mb-4">
            <button
              type="submit"
              className={`text-md border-2 p-1 cursor-pointer rounded-lg w-full ${!data?.header ? 'opacity-10 cursor-not-allowed' : ''}`}
              disabled={data?.header ? false : true}
            >
              Guardar Señal
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminSignals;
