export const fil = {
  _LOGIN: {
    _EMAIL: "Email",
    _PASSWORD: "Password",
    _REMEMBER: "Tandaan ako",
    _LOGIN: "MAG-LOG IN",
    _SIGNUP: "Mag-sign up",
    _FORGOTPASSWORD: "Nakalimutan ang iyong password?",
    _NOACCOUNT: "Wala ka pang account?",
    _ERROR_EMAIL: "Mukhang hindi ka pa rehistrado! Subukang muli.",
    _ERROR_PASSWORD: "Maling password",
    _SUCCESS: "Matagumpay na pag-login",
  },
  _REGISTER: {
    _REGISTER: "Magrehistro ng Account",
    _NAME: "Username",
    _EMAIL: "Iyong email",
    _PASSWORD: "Iyong password",
    _CONFIRMPASSWORD: "Kumpirmahin ang Password",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (opsyonal)",
    _HAVEACCOUNT: "May account na ako",
    _ERROR_REFERAL: "Maling referral code",
    _ERROR_EMAIL: "Ang email na ito ay umiiral na",
    _SUCCESS: "Matagumpay na Rehistrasyon",
  },
  _SIDEBAR: {
    _PAGES: "Mga Pahina",
    _OPEN: "Buksan ang sidebar",
    _DASHBOARD: "Home",
    _NETWORK: "Network",
    _FINANCE: "Wallet",
    _PACKAGES: "Mga Plano",
    _SIGNOUT: "Mag-logout",
    _LANGUAGE: "Wika",
    _PROFILE: "Profile",
  },
  _DASHBOARD: {
    _TITLE: "Balanse",
    _TITLE2: "Deposito",
    _TITLE3: "Kabuuang Kita",
    _TITLE4: "Unilevel na Kita",
    _TITLE5: "Bonus sa Ranggo",
    _TITLE6: "Plano",
    _TOTAL: "Kabuuan",
    _TABLE3: "Listahan ng Mga Package",
    _START: "Simula",
    _FINISH: "Tapos",
    _STATUS: "Katayuan",
    _STATUS_ACTIVE: "Aktibo",
    _STATUS_INACTIVE: "Hindi Aktibo",
    _CURRENT: "Kasalukuyan",
    _UPGRADE: "I-upgrade",
    _COPYMESSAGE: "Matagumpay na nakopya",
    _NOTIFICATION1: "Bagong Deposito",
    _NOTIFICATION2: "Kita mula sa Cryptotradex",
    _NOTIFICATION3: "Kita mula sa Unilevel",
    _NOTIFICATION4: "Walang Hanggang Ranggo",
    _NOTIFICATION5: "Bumili ng Package",
    _NOTIFICATION7: "Umulit na Direktang Referral na Bonus",
    _PREV: "Nakaraan",
    _NEXT: "Susunod",
    _TABLE4_A: "Operasyon",
    _TABLE4_B: "Kita",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Petsa",
    _SHARED: "Ibahagi ang iyong referral link",
  },
  _FINANCE: {
    _DEPOSIT: "Deposito",
    _QR: "I-scan ang sumusunod na QR o kopyahin ang address",
    _NETWORK: "Network",
    _INSTRUCTIONS: "Mga Tagubilin para sa Pagpadala ng TRON (TRX)",
    _STEP1: "Tiyaking ang napiling network ay TRON (TRC20).",
    _STEP2: "Ipadala ang halagang TRON (TRX) sa sumusunod na wallet address.",
    _COPY: "Kopyahin",
    _DISCLAIMER:
      "Paunawa: Hindi kami mananagot para sa mga transaksyon na ginawa sa labas ng TRON (TRC20) network o sa pagpapadala ng iba pang cryptocurrency bukod sa TRON (TRX). Anumang pagkawala ng pondo dahil sa mga pagkakamaling ito ay magiging responsibilidad ng nagpapadala.",
    _TABLE1: "Kasaysayan ng Deposito",
    _TABLE1_A: "Order",
    _TABLE1_B: "Halaga",
    _TABLE1_C: "Petsa",
    _TABLE1_D: "Katayuan",
    _SUCCESS: "Matagumpay na naidagdag ang package",
    _COPYMESSAGE: "Matagumpay na nakopya ang address ng wallet",
  },
  _PACKAGES: {
    _ATTENTION:
      "Pansin: Mayroon kang corporate account. Sa pagbili ng package, ire-reset mo ang account at lahat ng benepisyo nito.",
    _PACKAGES: "Piliin ang pinakamahusay na plano para sa iyo",
    _SUBTITLE: "Mayroon kang 8 pagpipilian",
    _BALANCE: "Balanse ng Deposito",
    _PACKAGE: "Package",
    _PACKAGE_1: "Makakakuha ka mula sa level",
    _PACKAGE_2: "Kumita ng hanggang ",
    _PACKAGE_3: "Araw-araw na ROI",
    _BUTTON1: "Puno na",
    _BUTTON2: "Bumili",
    _CALCULATE: "Kalkulahin ang Plano",
    _RECHARGE: "Wala kang sapat na pondo, mag-recharge ng iyong wallet",
    _PACKAGEMESSAGE: "Kailangan mong bumili ng mas mataas na package",
    _SUCCESS: "Matagumpay na naidagdag ang package",
    _UPDATE: "Presyo ng pag-upgrade ng package",
  },
  _NETWORK: {
    _TODAY: "Ngayon",
    _AVAILABLE: "Magagamit",
    _WITHDRAW: "Kabuuang Nai-withdraw",
    _SEARCHLABEL: "Paghahanap ng Email",
    _SEARCH: "Maghanap",
    _NETWORK: "Network",
    _DIRECTS: "Mga Direktang Referral",
    _SUCCESS: "Matagumpay na paghahanap",
    _ERROR: "Hindi bahagi ng iyong binary tree.",
    _LEVEL: "Level",
    _USERS: "Mga User",
    _AMOUNT: "Halaga",
    _USERNAME: "Username",
    _EMAIL: "Email",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Package",
  },
  _PROFILE: {
    _TITLE: "Profile",
    _NAME: "Iyong pangalan",
    _EMAIL: "Iyong email",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Kasalukuyang password",
    _PASSWORD: "Bagong password",
    _CONFIRMPASSWORD: "Kumpirmahin ang bagong password",
    _WALLET: "Address ng Withdrawal",
    _ACTION: "I-edit",
    _TIPS: "Mga Tips",
    _TIPS_1: "Dapat malinaw at hindi lalampas sa 20 karakter ang pangalan.",
    _TIPS_2:
      "Dapat maglaman ng mga numero, letra, at simbolo ang password; mas kumplikado, mas mabuti.",
    _TIPS_3:
      "Ang email ay hindi maaaring mabago. Kung kailangan mong magbago, mangyaring sumulat sa support.",
    _TIPS_4:
      "Ang address ng withdrawal ay maaari lamang mabago gamit ang 2-factor authenticator na dapat naunang i-enable.",
    _ERROR_PASSWORD: "Maling kasalukuyang password",
    _SUCCESS: "Matagumpay na na-update ang profile",
    _MIN: "Minimum na withdrawal",
    _UPDATE: "I-update ang Profile",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Nakalimutan ang iyong password?",
    _EMAIL: "Iyong email",
    _BUTTON: "Magpadala ng email",
    _MESSAGE: "Ipinadala na ang iyong password sa iyong email",
    _ERROR_EMAIL: "Hindi rehistrado ang email na ito",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Kasalukuyang Ranggo",
    _NEXT: "Susunod na Ranggo",
    _SILVER: "Pilak",
    _GOLD: "Ginto",
    _RUBY: "Rubi",
    _EMERALD: "Esmeralda",
    _DIAMOND: "Diyamante",
    _DIAMONDB: "Asul na Diyamante",
    _DIAMONDBB: "Itim na Diyamante",
    _NONE: "Wala",
  },
  _AUTH: {
    _TITLEDEFAULT: "Baguhin ang withdrawal wallet",
    _TITLE: "Hakbang 1",
    _TITLE2: "Hakbang 2",
    _TITLE3: "Hakbang 3",
    _STEP1: "I-download at i-install ang authenticator sa iyong device",
    _STEP2: "I-scan ang code para mai-save",
    _FORMEMAIL: "Pag-verify ng Email",
    _FORMHELPEMAIL: "Ilagay ang 6-digit verification code na ipinadala sa",
    _FORMHELPAUTH: "Ilagay ang 6-digit na code mula sa Authenticator App",
    _FORM1: "Authenticator Pin",
    _FORM2: "Withdrawal address",
    _FORMMIN: "Ang withdrawal address ay dapat 34 karakter ang haba",
    _FORMCODESEND: "Code na ipinadala",
    _VERIFF: "I-verify",
    _ACTION: "Suriin at I-save",
    _ERROR: "Error, subukang muli.",
    _SUCCESS: "Matagumpay na naipadala",
    _RETRYOTP: "Error sa pagpapadala ng code, subukang muli.",
    _SENDOTP: "Matagumpay na naipadala ang code",
    _SUCCESSOTP: "Matagumpay na na-verify ang OTP",
    _SUCCESSAUTH2F: "Matagumpay na na-verify ang Auth2fa",
    _ERRORCODE: "Invalid na code, subukang muli",
  },
  _COUNTDOWN: {
    _TITLE: "Susunod na cut-off:",
    _DAYS: "Araw",
    _HOURS: "Oras",
    _MINUTES: "Minuto",
    _SECONDS: "Segundo",
  },
  _WITHDRAWAL: {
    _TITLE: "Mga Transaksyon ng Withdrawal",
  },
  _SYSTEM: {
    _SYSTEM: "Sistema",
    _GLOBAL: "Pandaigdigan",
    _INVESTMENT: "Bawiin ang pamumuhunan",
    _INVESTMENTTEXT:
      "Tandaan na magkakaroon ka ng parusa na 35% ng iyong pamumuhunan",
    _ACCELERATE: "Pabilisin",
    _REPORT: "Ulat sa Pamumuhunan ng Cryptotradex",
    _PARNERT: "Impormasyon ng sponsor",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Mag-withdraw ng Investment",
    _MESSAGE:
      "Ang pag-withdraw ng iyong kapital ngayon ay may kasamang 35% operational fee - mga naipon na kita at ipoproseso ito sa loob ng 24 oras. Kung gagawin mo ito, ititigil mo ang agarang potensyal ng paglago nito. Magdesisyon nang may pangmatagalang pananaw!",
    _BUTTON: "Mag-withdraw",
    _BUTTONWAIT: "Pag-withdraw sa proseso",
  },
  _SIGNAL: {
    _NEWS: "Bago",
    _SIGNALS: "Mga Signal",
    _WHATIS: "Ano ang Cryptotradex Signal?",
    _DESCRIPTION:
      "Sumali sa aming eksklusibong grupo ng Signal at tumanggap ng higit sa 10 rekomendasyon araw-araw upang mapalaki ang iyong kita, na may mga balik na hanggang 200% sa mataas at mababang panganib na mga pamumuhunan.",
    _COMMING: "Malapit na.",
    _SIGNALS10: "SIGNAL10X",
    _CHAT: "Eksklusibong Chat",
    _DESCRIPTIONGLANDING:
      "Sa aming eksklusibong chat, makakatanggap ka ng mga signal sa real-time, at maaari ka ring mag-ambag o magtanong tungkol sa bawat isa nang organisado.",
    _TESTIMONIALS: "MGA PATOTOO",
    _GETDAILY:
      "Tanggapin ang mga araw-araw na signal ng CTX at simulan ang pagpapalago ng iyong kapital!",
    _TOBE:
      "Upang maging bahagi ng Cryptotradex Signals, kailangan mong magkaroon ng isang pamumuhunan na hindi bababa sa 1,000 USD.",
    _IWANT: "Gusto ko ng mga signal",
    _TESTIMONIALS1:
      "Nagsimula akong gumamit ng mga signal na ito sa Solana network at nakapag-convert ng aking 200 USD sa 600 USD sa loob lamang ng ilang oras. Ang impormasyon na ibinigay sa pamamagitan ng Dexscreener ang nagdala ng malaking kaibahan!",
    _TESTIMONIALS2:
      "Sa mga signal na ito sa real-time, patuloy kong pinapalago ang aking paunang pamumuhunan mula linggo hanggang linggo. Ang kumbinasyon ng data ng Solana at Dexscreener ay tumulong sa akin upang kumita ng higit sa 3,000 USD sa loob lamang ng 5 linggo!",
    _TESTIMONIALS3:
      "Ang komunidad sa likod ng mga signal na ito ay kamangha-mangha. Hindi lang naipon ko ang aking Solana holdings, nakakita rin ako ng isang mahusay na grupo ng mga sumusuporta na nag-gabay sa akin sa bawat transaksyon.",
    _TESTIMONIALS4:
      "Noong una, nagduda ako, pero ipinakita ng sinerhiya ng mga pagkakataon batay sa Solana at impormasyon mula sa Dexscreener na mali ako. Ang aking portfolio ay lumago higit pa sa aking inaasahan!",
  },
  _ACADEMY: {
    _NEWS: "Bago",
    _TITLE: "Akademya",
    _WHATIS: "Ano ang Cryptotradex Academy?",
    _DESCRIPTION:
      "Matutunan ang tungkol sa mga cryptocurrency, wallet, memes, at Web3 gamit ang aming online platform na dinisenyo para sa lahat ng antas.",
    _ACTION1: "Pumunta sa Akademya",
    _DISABLED: "Kinakailangan ang minimum na deposito na 500 USD",
    _TITLANDING: "Maging isang Eksperto sa Cryptomemes",
    _DESCRIPTIONLANDING:
      "Mag-access ng mga eksklusibong tutorial mula sa BRETT package at matutunan kung paano pamahalaan ang mundo ng MEMECOINS.",
    _ACTIONLANDING: "Mag-sign up ngayon at magsimulang matuto!",
    _WHATCOURSES: "Anong mga kurso ang maaari mong makuha?",
    _RECOMMENDS: "Inirerekomendang mga video",
    _VIDEO1: "Phantom Wallet Tutorial",
    _VIDEO2: "Pumili ng mga explosive MEMECOINS sa DEXSCREENER",
    _VIDEO3: "Masterin ang GMGN tulad ng isang eksperto",
    _VIDEO4: "Ang Perpektong Bot para sa MEMECOINS",
    _VIDEO5: "Paano makahanap ng MEMECOINS na may malaking potensyal",
  },
  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
