export const sl = {
  _LOGIN: {
    _EMAIL: "E-pošta",
    _PASSWORD: "Geslo",
    _REMEMBER: "Zapomni si me",
    _LOGIN: "PRIJAVA",
    _SIGNUP: "Registriraj se",
    _FORGOTPASSWORD: "Ste pozabili geslo?",
    _NOACCOUNT: "Nimate računa?",
    _ERROR_EMAIL: "Očitno niste registrirani! Poskusite znova.",
    _ERROR_PASSWORD: "Napačno geslo",
    _SUCCESS: "Uspešna prijava",
  },
  _REGISTER: {
    _REGISTER: "Registracija računa",
    _NAME: "Uporabniško ime",
    _EMAIL: "Vaša e-pošta",
    _PASSWORD: "Vaše geslo",
    _CONFIRMPASSWORD: "Potrdite geslo",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (neobvezno)",
    _HAVEACCOUNT: "Že imam račun",
    _ERROR_REFERAL: "Napačna referenčna koda",
    _ERROR_EMAIL: "Ta e-pošta že obstaja",
    _SUCCESS: "Uspešna registracija",
  },
  _SIDEBAR: {
    _PAGES: "Strani",
    _OPEN: "Odpri stransko vrstico",
    _DASHBOARD: "Domov",
    _NETWORK: "Mreža",
    _FINANCE: "Denarnica",
    _PACKAGES: "Načrti",
    _SIGNOUT: "Odjavi se",
    _LANGUAGE: "Jezik",
    _PROFILE: "Profil",
  },
  _DASHBOARD: {
    _TITLE: "Stanje",
    _TITLE2: "Depozit",
    _TITLE3: "Skupni dobički",
    _TITLE4: "Unilevel dobički",
    _TITLE5: "Bonusi po rangih",
    _TITLE6: "Načrt",
    _TOTAL: "Skupaj",
    _TABLE3: "Seznam paketov",
    _START: "Začetek",
    _FINISH: "Konec",
    _STATUS: "Status",
    _STATUS_ACTIVE: "Aktiven",
    _STATUS_INACTIVE: "Neaktiven",
    _CURRENT: "Trenutno",
    _UPGRADE: "Nadgradnja",
    _COPYMESSAGE: "Uspešno kopirano",
    _NOTIFICATION1: "Nov depozit",
    _NOTIFICATION2: "Cryptotradex dobiček",
    _NOTIFICATION3: "Unilevel dobiček",
    _NOTIFICATION4: "Obseg neskončen",
    _NOTIFICATION5: "Nakup paketa",
    _NOTIFICATION7: "Ponavljajoči neposredni referenčni bonus",
    _PREV: "Prejšnji",
    _NEXT: "Naslednji",
    _TABLE4_A: "Operacija",
    _TABLE4_B: "Dobiček",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Datum",
    _SHARED: "Delite svojo referenčno povezavo",
  },
  _FINANCE: {
    _DEPOSIT: "Depozit",
    _QR: "Skenirajte naslednji QR ali kopirajte naslov",
    _NETWORK: "Mreža",
    _INSTRUCTIONS: "Navodila za pošiljanje TRON (TRX)",
    _STEP1: "Prepričajte se, da je izbrana mreža TRON (TRC20).",
    _STEP2: "Pošljite znesek TRON (TRX) na naslednji naslov denarnice.",
    _COPY: "Kopiraj",
    _DISCLAIMER:
      "Odzivnost: Nismo odgovorni za transakcije, opravljene zunaj mreže TRON (TRC20), ali za pošiljanje kriptovalut, razen TRON (TRX). Vse izgube sredstev zaradi teh napak bodo izključno odgovornost pošiljatelja.",
    _TABLE1: "Zgodovina depozitov",
    _TABLE1_A: "Naročilo",
    _TABLE1_B: "Znesek",
    _TABLE1_C: "Datum",
    _TABLE1_D: "Status",
    _SUCCESS: "Paket je bil uspešno dodan",
    _COPYMESSAGE: "Naslov denarnice je bil uspešno kopiran",
  },
  _PACKAGES: {
    _ATTENTION:
      "Pozor: Imate korporativni račun. Z nakupom paketa boste ponastavili račun in vse njegove koristi.",
    _PACKAGES: "Izberite najboljši načrt za vas",
    _SUBTITLE: "Imate 8 možnosti",
    _BALANCE: "Bilans depozita",
    _PACKAGE: "Paket ",
    _PACKAGE_1: "Zaslužite do nivoja ",
    _PACKAGE_2: "Zaslužite do",
    _PACKAGE_3: "Dnevni ROI",
    _BUTTON1: "Poln",
    _BUTTON2: "Kupite",
    _CALCULATE: "Izračunaj načrt",
    _RECHARGE: "Nimate dovolj denarja, dopolnite svoj denarnico",
    _PACKAGEMESSAGE: "Morate kupiti višji paket",
    _SUCCESS: "Paket je bil uspešno dodan",
    _UPDATE: "Cena za nadgradnjo paketa",
  },
  _NETWORK: {
    _TODAY: "Danes",
    _AVAILABLE: "Na voljo",
    _WITHDRAW: "Skupaj dvignjeno",
    _SEARCHLABEL: "Iskanje po e-pošti",
    _SEARCH: "Iskanje",
    _NETWORK: "Mreža",
    _DIRECTS: "Direktni",
    _SUCCESS: "Uspešno iskanje",
    _ERROR: "Ni del vašega binarnega drevesa.",
    _LEVEL: "Nivo",
    _USERS: "Uporabniki",
    _AMOUNT: "Znesek",
    _USERNAME: "Uporabniško ime",
    _EMAIL: "E-pošta",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Paket",
  },
  _PROFILE: {
    _TITLE: "Profil",
    _NAME: "Vaše ime",
    _EMAIL: "Vaša e-pošta",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Trenutno geslo",
    _PASSWORD: "Novo geslo",
    _CONFIRMPASSWORD: "Potrdite novo geslo",
    _WALLET: "Naslov za dvig",
    _ACTION: "Uredi",
    _TIPS: "Nasveti",
    _TIPS_1: "Ime mora biti čitljivo in ne sme presegati 20 znakov.",
    _TIPS_2:
      "Geslo mora vključevati številke, črke in simbole; bolj ko je zapleteno, bolje je.",
    _TIPS_3:
      "E-pošte ni mogoče spremeniti. Če potrebujete spremembo, pišite podpori.",
    _TIPS_4:
      "Naslov za dvig lahko spremenite le z dvofaktorskim avtentifikatorjem, ki mora biti prej omogočen.",
    _ERROR_PASSWORD: "Napaka pri prejšnjem geslu",
    _SUCCESS: "Profil je bil uspešno posodobljen",
    _MIN: "Minimalni dvig",
    _UPDATE: "Posodobi profil",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Ste pozabili geslo?",
    _EMAIL: "Vaša e-pošta",
    _BUTTON: "Pošlji e-pošto",
    _MESSAGE: "Vaše geslo je bilo poslano na vašo e-pošto",
    _ERROR_EMAIL: "Ta e-pošta ni registrirana",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Trenutni rang",
    _NEXT: "Naslednji rang",
    _SILVER: "Srebro",
    _GOLD: "Zlato",
    _RUBY: "Rubin",
    _EMERALD: "Smaragd",
    _DIAMOND: "Diamant",
    _DIAMONDB: "Diamant Modri",
    _DIAMONDBB: "Diamant Črni",
    _NONE: "Noben",
  },
  _AUTH: {
    _TITLEDEFAULT: "Sprememba naslova za dvig",
    _TITLE: "Korak 1",
    _TITLE2: "Korak 2",
    _TITLE3: "Korak 3",
    _STEP1: "Prenesite in namestite avtentifikator na vaši napravi",
    _STEP2: "Skenirajte kodo za shranitev",
    _FORMEMAIL: "Verifikacija e-pošte",
    _FORMHELPEMAIL: "Vnesite 6-cifreni verifikacijski koda, poslan na",
    _FORMHELPAUTH:
      "Vnesite 6-cifreni koda, generiran s pomočjo aplikacije avtentifikator",
    _FORM1: "Pin z avtentifikatorja",
    _FORM2: "Naslov za dvig",
    _FORMMIN: "Naslov za dvig mora imeti 34 znakov",
    _FORMCODESEND: "Pošlji kodo",
    _VERIFF: "Veriff",
    _ACTION: "Preverite in shranite",
    _ERROR: "Napaka, poskusite znova.",
    _SUCCESS: "Sprememba uspešna",
    _RETRYOTP: "Napaka pri pošiljanju kode, poskusite znova.",
    _SENDOTP: "Koda uspešno poslana",
    _SUCCESSOTP: "OTP uspešno potrjen",
    _SUCCESSAUTH2F: "Auth2fa uspešno potrjen",
    _ERRORCODE: "Neveljavna koda, poskusite znova",
  },
  _COUNTDOWN: {
    _TITLE: "Naslednji prekid:",
    _DAYS: "Dni",
    _HOURS: "Ure",
    _MINUTES: "Minute",
    _SECONDS: "Sekunde",
  },
  _WITHDRAWAL: {
    _TITLE: "Transakcije dvigov",
  },
  _SYSTEM: {
    _SYSTEM: "Sistem",
    _GLOBAL: "Globalno",
    _INVESTMENT: "Dvignite investicijo",
    _INVESTMENTTEXT:
      "Ne pozabite, da boste imeli kazen v višini 35% vaše investicije",
    _ACCELERATE: "Pospeši",
    _REPORT: "Poročilo o investicijah Cryptotradex",
    _PARNERT: "Informacije o sponzorju",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Dvig Naložbe",
    _MESSAGE:
      "Umik vašega kapitala zdaj vključuje operativno pristojbino v višini 35% - akumulirani dobički in bo obdelan v 24 urah. Če to storite, boste ustavili njegov takojšen potencial rasti. Odločite se z dolgoročno vizijo!",
    _BUTTON: "Dvig",
    _BUTTONWAIT: "Dvig v teku",
  },
  _SIGNAL: {
    _NEWS: "Novo",
    _SIGNALS: "Signali",
    _WHATIS: "Kaj je Cryptotradex Signal?",
    _DESCRIPTION:
      "Pridružite se naši ekskluzivni skupini signalov in prejemajte več kot 10 priporočil dnevno za maksimizacijo dobičkov, z donosom do 200% pri visokem in nizkemu tveganju naložbah.",
    _COMMING: "Kmalu.",
    _SIGNALS10: "SIGNALI10X",
    _CHAT: "Ekskluzivni klepet",
    _DESCRIPTIONGLANDING:
      "V našem ekskluzivnem klepetu boste v realnem času prejemali signale in lahko prispevate ali postavljate vprašanja o vsakem signalu na organiziran način.",
    _TESTIMONIALS: "PRIČEVANJA",
    _GETDAILY:
      "Prejmite dnevne signale CTX in začnite povečevati svoj kapital!",
    _TOBE:
      "Za članstvo v Cryptotradex Signals morate imeti naložbo v vrednosti najmanj 1.000 USD.",
    _IWANT: "Želim signale",
    _TESTIMONIALS1:
      "Začel sem uporabljati te signale na omrežju Solana in uspel sem pretvoriti začetnih 200 USD v 600 USD v samo nekaj urah. Informacije, ki jih je zagotovil Dexscreener, so naredile vse razliko!",
    _TESTIMONIALS2:
      "S temi signali v realnem času sem dosledno povečeval svojo začetno naložbo iz tedna v teden. Kombinacija podatkov Solana in Dexscreener mi je pomagala zaslužiti več kot 3.000 USD v samo 5 tednih!",
    _TESTIMONIALS3:
      "Skupnost za temi signali je neverjetna. Ne samo, da sem pomembno povečal svoje lastnine v Solani, ampak sem našel tudi odlično podporno skupino, ki me je vodila pri vsaki transakciji.",
    _TESTIMONIALS4:
      "Na začetku sem bil skeptičen, vendar je sinergija med priložnostmi na osnovi Solane in informacijami iz Dexscreenerja dokazala, da sem se motil. Moje portfelje so se povečale več, kot sem si kdajkoli predstavljal!",
  },
  _ACADEMY: {
    _NEWS: "Novo",
    _TITLE: "Akademija",
    _WHATIS: "Kaj je Cryptotradex Academy?",
    _DESCRIPTION:
      "Naučite se o kriptovalutah, denarnicah, meme-jih in Web3 z našo spletno platformo, ki je zasnovana za vse stopnje.",
    _ACTION1: "Pojdi v Akademijo",
    _DISABLED: "Zahteva minimalni depozit 500 USD",
    _TITLANDING: "Postanite strokovnjak za Cryptomemes",
    _DESCRIPTIONLANDING:
      "Dostopajte do ekskluzivnih tutorialov z BRETT paketom in se naučite obvladati svet MEMECOINS.",
    _ACTIONLANDING: "Prijavite se zdaj in začnite se učiti!",
    _WHATCOURSES: "Katere tečaje lahko pridobite?",
    _RECOMMENDS: "Priporočeni videoposnetki",
    _VIDEO1: "Phantom Wallet Tutorial",
    _VIDEO2: "Poiščite eksplozivne MEMECOINS na DEXSCREENER",
    _VIDEO3: "Obvladujte GMGN kot strokovnjak",
    _VIDEO4: "Popoln bot za MEMECOINS",
    _VIDEO5: "Kako najti MEMECOINS z velikim potencialom",
  },

  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
