export const chi = {
  _LOGIN: {
    _EMAIL: "电子邮箱",
    _PASSWORD: "密码",
    _REMEMBER: "记住我",
    _LOGIN: "登录",
    _SIGNUP: "注册",
    _FORGOTPASSWORD: "忘记密码？",
    _NOACCOUNT: "还没有账户？",
    _ERROR_EMAIL: "看起来你还没有注册！请再试一次。",
    _ERROR_PASSWORD: "密码错误",
    _SUCCESS: "登录成功",
  },
  _REGISTER: {
    _REGISTER: "注册账户",
    _NAME: "用户名",
    _EMAIL: "你的电子邮箱",
    _PASSWORD: "你的密码",
    _CONFIRMPASSWORD: "确认密码",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram（可选）",
    _HAVEACCOUNT: "我有一个账户",
    _ERROR_REFERAL: "推荐码错误",
    _ERROR_EMAIL: "此电子邮件已存在",
    _SUCCESS: "注册成功",
  },
  _SIDEBAR: {
    _PAGES: "页面",
    _OPEN: "打开侧边栏",
    _DASHBOARD: "主页",
    _NETWORK: "网络",
    _FINANCE: "钱包",
    _PACKAGES: "计划",
    _SIGNOUT: "登出",
    _LANGUAGE: "语言",
    _PROFILE: "个人资料",
  },
  _DASHBOARD: {
    _TITLE: "余额",
    _TITLE2: "存款",
    _TITLE3: "总收入",
    _TITLE4: "Unilevel 收益",
    _TITLE5: "等级奖金",
    _TITLE6: "计划",
    _TOTAL: "总计",
    _TABLE3: "套餐列表",
    _START: "开始",
    _FINISH: "结束",
    _STATUS: "状态",
    _STATUS_ACTIVE: "活跃",
    _STATUS_INACTIVE: "不活跃",
    _CURRENT: "当前",
    _UPGRADE: "升级",
    _COPYMESSAGE: "复制成功",
    _NOTIFICATION1: "新存款",
    _NOTIFICATION2: "Cryptotradex 收益",
    _NOTIFICATION3: "Unilevel 收益",
    _NOTIFICATION4: "无限级别",
    _NOTIFICATION5: "购买套餐",
    _NOTIFICATION7: "直接复发推荐奖金",
    _PREV: "上一页",
    _NEXT: "下一页",
    _TABLE4_A: "操作",
    _TABLE4_B: "收益",
    _TABLE4_C: "回报率 (ROI)",
    _TABLE4_D: "日期",
    _SHARED: "分享你的推荐链接",
  },
  _FINANCE: {
    _DEPOSIT: "存款",
    _QR: "扫描以下二维码或复制地址",
    _NETWORK: "网络",
    _INSTRUCTIONS: "发送 TRON (TRX) 的说明",
    _STEP1: "确保选择的网络为 TRON (TRC20)。",
    _STEP2: "将 TRON (TRX) 转至以下钱包地址。",
    _COPY: "复制",
    _DISCLAIMER:
      "免责声明：我们不对 TRON (TRC20) 网络之外的交易或发送非 TRON (TRX) 的加密货币负责。因这些错误造成的资金损失将由发送方自行承担。",
    _TABLE1: "存款历史",
    _TABLE1_A: "订单",
    _TABLE1_B: "金额",
    _TABLE1_C: "日期",
    _TABLE1_D: "状态",
    _SUCCESS: "套餐添加成功",
    _COPYMESSAGE: "钱包地址复制成功",
  },
  _PACKAGES: {
    _ATTENTION:
      "注意：你有一个企业账户。购买套餐后，账户及其所有权益将会重置。",
    _PACKAGES: "选择适合你的最佳计划",
    _SUBTITLE: "你有8个选项",
    _BALANCE: "存款余额",
    _PACKAGE: "套餐",
    _PACKAGE_1: "你将从第一级开始赚取收益",
    _PACKAGE_2: "最多可赚取 ",
    _PACKAGE_3: "每日回报率 (ROI)",
    _BUTTON1: "已满",
    _BUTTON2: "购买",
    _CALCULATE: "计算计划",
    _RECHARGE: "余额不足，请充值",
    _PACKAGEMESSAGE: "你必须购买更高的套餐",
    _SUCCESS: "套餐添加成功",
    _UPDATE: "套餐升级价格",
  },
  _NETWORK: {
    _TODAY: "今天",
    _AVAILABLE: "可用",
    _WITHDRAW: "总提取",
    _SEARCHLABEL: "邮箱搜索",
    _SEARCH: "搜索",
    _NETWORK: "网络",
    _DIRECTS: "直推",
    _SUCCESS: "搜索成功",
    _ERROR: "不属于你的二叉树。",
    _LEVEL: "等级",
    _USERS: "用户",
    _AMOUNT: "金额",
    _USERNAME: "用户名",
    _EMAIL: "邮箱",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "套餐",
  },
  _PROFILE: {
    _TITLE: "个人资料",
    _NAME: "你的名字",
    _EMAIL: "你的邮箱",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "当前密码",
    _PASSWORD: "新密码",
    _CONFIRMPASSWORD: "确认新密码",
    _WALLET: "提款地址",
    _ACTION: "编辑",
    _TIPS: "提示",
    _TIPS_1: "名称必须清晰可见且不超过 20 个字符。",
    _TIPS_2: "密码应包括数字、字母和符号，越复杂越好。",
    _TIPS_3: "邮箱无法更改。如需修改，请联系支持。",
    _TIPS_4: "提款地址只能通过启用的双因素身份验证进行修改。",
    _ERROR_PASSWORD: "当前密码错误",
    _SUCCESS: "个人资料更新成功",
    _MIN: "最低提款金额",
    _UPDATE: "更新资料",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "忘记密码？",
    _EMAIL: "你的邮箱",
    _BUTTON: "发送邮件",
    _MESSAGE: "你的密码已发送至邮箱",
    _ERROR_EMAIL: "此邮箱未注册",
  },
  _GLOBALV: {
    _TITLE: "全球值",
    _CURRENT: "当前等级",
    _NEXT: "下一级",
    _SILVER: "银牌",
    _GOLD: "金牌",
    _RUBY: "红宝石",
    _EMERALD: "翡翠",
    _DIAMOND: "钻石",
    _DIAMONDB: "蓝钻",
    _DIAMONDBB: "黑钻",
    _NONE: "无",
  },
  _AUTH: {
    _TITLEDEFAULT: "更改提款钱包",
    _TITLE: "步骤 1",
    _TITLE2: "步骤 2",
    _TITLE3: "步骤 3",
    _STEP1: "下载并安装身份验证器到你的设备",
    _STEP2: "扫描二维码保存",
    _FORMEMAIL: "邮箱验证",
    _FORMHELPEMAIL: "输入发送到邮箱的 6 位验证码",
    _FORMHELPAUTH: "输入身份验证器应用生成的 6 位验证码",
    _FORM1: "身份验证器验证码",
    _FORM2: "提款地址",
    _FORMMIN: "提款地址必须为 34 个字符",
    _FORMCODESEND: "验证码已发送",
    _VERIFF: "验证",
    _ACTION: "检查并保存",
    _ERROR: "错误，请重试。",
    _SUCCESS: "更改成功",
    _RETRYOTP: "验证码发送错误，请重试。",
    _SENDOTP: "验证码发送成功",
    _SUCCESSOTP: "OTP 验证成功",
    _SUCCESSAUTH2F: "双因素身份验证成功",
    _ERRORCODE: "无效验证码，请重试",
  },
  _COUNTDOWN: {
    _TITLE: "下次截单：",
    _DAYS: "天",
    _HOURS: "小时",
    _MINUTES: "分钟",
    _SECONDS: "秒",
  },
  _WITHDRAWAL: {
    _TITLE: "提款交易",
  },
  _SYSTEM: {
    _SYSTEM: "系统",
    _GLOBAL: "全球",
    _INVESTMENT: "撤回投资",
    _INVESTMENTTEXT: "请记住，您将损失35%的投资作为罚金",
    _ACCELERATE: "加速",
    _REPORT: "Cryptotradex投资报告",
    _PARNERT: "赞助商信息",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "撤回投资",
    _MESSAGE:
      "现在提取你的资本需要支付35%的操作费用——累积的收益，并将在24小时内处理。如果你这样做，将会停止其即时增长潜力。请以长远的眼光做决定！",
    _BUTTON: "撤回",
    _BUTTONWAIT: "正在处理撤回",
  },
  _SIGNAL: {
    _NEWS: "新品",
    _SIGNALS: "信号",
    _WHATIS: "什么是Cryptotradex信号？",
    _DESCRIPTION:
      "加入我们独家的信号组，每天接收超过10条推荐，帮助您最大化收益，投资高风险和低风险项目，回报率高达200%。",
    _COMMING: "即将推出。",
    _SIGNALS10: "信号10X",
    _CHAT: "专属聊天",
    _DESCRIPTIONGLANDING:
      "在我们的专属聊天中，您将实时接收信号，并可以有序地对每个信号进行讨论或提问。",
    _TESTIMONIALS: "推荐语",
    _GETDAILY: "立即获取CTX每日信号，开始扩大您的资本！",
    _TOBE: "要成为Cryptotradex信号的一部分，您的投资金额必须不低于1000美元。",
    _IWANT: "我要信号",
    _TESTIMONIALS1:
      "我开始使用这些信号，在Solana网络上仅仅几小时就将200美元变成了600美元！通过Dexscreener提供的信息是关键！",
    _TESTIMONIALS2:
      "通过这些实时信号，我不断增加我的初始投资，每周都在增长。Solana的数据和Dexscreener的结合帮助我在5周内赚到了超过3000美元！",
    _TESTIMONIALS3:
      "这个信号背后的社区太棒了。我不仅显著增加了我的Solana持仓，还找到了一个非常支持我的群体，帮助我在每一笔交易中。",
    _TESTIMONIALS4:
      "最初我持怀疑态度，但Solana的机会和Dexscreener的信息之间的协同作用证明我错了！我的投资组合增长超出了我曾经的想象！",
  },
  _ACADEMY: {
    _NEWS: "新品",
    _TITLE: "学院",
    _WHATIS: "什么是Cryptotradex学院？",
    _DESCRIPTION:
      "通过我们的在线平台学习加密货币、钱包、Memes和Web3，适合各个层次的学习者。",
    _ACTION1: "前往学院",
    _DISABLED: "需要最低存款500美元",
    _TITLANDING: "成为Cryptomemes专家",
    _DESCRIPTIONLANDING:
      "通过BRETT套餐获取独家教程，学习如何驾驭MEMECOINS的世界。",
    _ACTIONLANDING: "立即注册并开始学习！",
    _WHATCOURSES: "您可以获得哪些课程？",
    _RECOMMENDS: "推荐视频",
    _VIDEO1: "Phantom钱包教程",
    _VIDEO2: "在DEXSCREENER中找到爆炸性MEMECOINS",
    _VIDEO3: "像专家一样掌握GMGN",
    _VIDEO4: "完美的MEMECOINS机器人",
    _VIDEO5: "如何找到具有巨大潜力的MEMECOINS",
  },
  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
