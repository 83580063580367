export const vie = {
  _LOGIN: {
    _EMAIL: "Email",
    _PASSWORD: "Mật khẩu",
    _REMEMBER: "Ghi nhớ tôi",
    _LOGIN: "ĐĂNG NHẬP",
    _SIGNUP: "Đăng ký",
    _FORGOTPASSWORD: "Quên mật khẩu?",
    _NOACCOUNT: "Chưa có tài khoản?",
    _ERROR_EMAIL: "Có vẻ như bạn chưa đăng ký! Thử lại lần nữa.",
    _ERROR_PASSWORD: "Mật khẩu không đúng",
    _SUCCESS: "Đăng nhập thành công",
  },
  _REGISTER: {
    _REGISTER: "Đăng ký tài khoản",
    _NAME: "Tên người dùng",
    _EMAIL: "Email của bạn",
    _PASSWORD: "Mật khẩu của bạn",
    _CONFIRMPASSWORD: "Xác nhận mật khẩu",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (tùy chọn)",
    _HAVEACCOUNT: "Tôi đã có tài khoản",
    _ERROR_REFERAL: "Mã giới thiệu không đúng",
    _ERROR_EMAIL: "Email này đã tồn tại",
    _SUCCESS: "Đăng ký thành công",
  },
  _SIDEBAR: {
    _PAGES: "Trang",
    _OPEN: "Mở thanh bên",
    _DASHBOARD: "Trang chủ",
    _NETWORK: "Mạng lưới",
    _FINANCE: "Ví",
    _PACKAGES: "Kế hoạch",
    _SIGNOUT: "Đăng xuất",
    _LANGUAGE: "Ngôn ngữ",
    _PROFILE: "Hồ sơ",
  },
  _DASHBOARD: {
    _TITLE: "Số dư",
    _TITLE2: "Gửi tiền",
    _TITLE3: "Tổng thu nhập",
    _TITLE4: "Thu nhập Unilevel",
    _TITLE5: "Thưởng cấp bậc",
    _TITLE6: "Kế hoạch",
    _TOTAL: "Tổng",
    _TABLE3: "Danh sách gói",
    _START: "Bắt đầu",
    _FINISH: "Kết thúc",
    _STATUS: "Trạng thái",
    _STATUS_ACTIVE: "Hoạt động",
    _STATUS_INACTIVE: "Không hoạt động",
    _CURRENT: "Hiện tại",
    _UPGRADE: "Nâng cấp",
    _COPYMESSAGE: "Sao chép thành công",
    _NOTIFICATION1: "Gửi tiền mới",
    _NOTIFICATION2: "Lợi nhuận Cryptotradex",
    _NOTIFICATION3: "Lợi nhuận Unilevel",
    _NOTIFICATION4: "Phạm vi không giới hạn",
    _NOTIFICATION5: "Mua gói",
    _NOTIFICATION7: "Tiền Thưởng Giới Thiệu Trực Tiếp Định Kỳ",
    _PREV: "Trước",
    _NEXT: "Tiếp theo",
    _TABLE4_A: "Hoạt động",
    _TABLE4_B: "Lợi nhuận",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Ngày",
    _SHARED: "Chia sẻ liên kết giới thiệu của bạn",
  },
  _FINANCE: {
    _DEPOSIT: "Gửi tiền",
    _QR: "Quét mã QR sau hoặc sao chép địa chỉ",
    _NETWORK: "Mạng lưới",
    _INSTRUCTIONS: "Hướng dẫn gửi TRON (TRX)",
    _STEP1: "Đảm bảo mạng lưới đã chọn là TRON (TRC20).",
    _STEP2: "Gửi số lượng TRON (TRX) đến địa chỉ ví sau.",
    _COPY: "Sao chép",
    _DISCLAIMER:
      "Tuyên bố từ chối trách nhiệm: Chúng tôi không chịu trách nhiệm về các giao dịch được thực hiện ngoài mạng TRON (TRC20) hoặc gửi các loại tiền điện tử khác ngoài TRON (TRX). Mọi mất mát tiền do những lỗi này sẽ hoàn toàn thuộc trách nhiệm của người gửi.",
    _TABLE1: "Lịch sử gửi tiền",
    _TABLE1_A: "Đơn hàng",
    _TABLE1_B: "Số lượng",
    _TABLE1_C: "Ngày",
    _TABLE1_D: "Trạng thái",
    _SUCCESS: "Gói đã được thêm thành công",
    _COPYMESSAGE: "Địa chỉ ví được sao chép thành công",
  },
  _PACKAGES: {
    _ATTENTION:
      "Chú ý: Bạn có tài khoản doanh nghiệp. Khi mua một gói, bạn sẽ đặt lại tài khoản và tất cả các lợi ích của nó.",
    _PACKAGES: "Chọn kế hoạch tốt nhất cho bạn",
    _SUBTITLE: "Bạn có 8 tùy chọn",
    _BALANCE: "Số dư gửi tiền",
    _PACKAGE: "Gói",
    _PACKAGE_1: "Bạn sẽ kiếm được từ cấp độ",
    _PACKAGE_2: "Kiếm tối đa lần",
    _PACKAGE_3: "ROI hàng ngày",
    _BUTTON1: "Đầy",
    _BUTTON2: "Mua",
    _CALCULATE: "Tính toán kế hoạch",
    _RECHARGE: "Bạn không có đủ tiền, hãy nạp tiền vào ví của bạn",
    _PACKAGEMESSAGE: "Bạn phải mua gói cao hơn",
    _SUCCESS: "Gói đã được thêm thành công",
    _UPDATE: "Giá nâng cấp gói",
  },
  _NETWORK: {
    _TODAY: "Hôm nay",
    _AVAILABLE: "Có sẵn",
    _WITHDRAW: "Tổng số đã rút",
    _SEARCHLABEL: "Tìm kiếm Email",
    _SEARCH: "Tìm kiếm",
    _NETWORK: "Mạng lưới",
    _DIRECTS: "Trực tiếp",
    _SUCCESS: "Tìm kiếm thành công",
    _ERROR: "Không phải là một phần của cây nhị phân của bạn.",
    _LEVEL: "Cấp độ",
    _USERS: "Người dùng",
    _AMOUNT: "Số lượng",
    _USERNAME: "Tên người dùng",
    _EMAIL: "Email",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Gói",
  },
  _PROFILE: {
    _TITLE: "Hồ sơ",
    _NAME: "Tên của bạn",
    _EMAIL: "Email của bạn",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Mật khẩu hiện tại",
    _PASSWORD: "Mật khẩu mới",
    _CONFIRMPASSWORD: "Xác nhận mật khẩu mới",
    _WALLET: "Địa chỉ rút tiền",
    _ACTION: "Chỉnh sửa",
    _TIPS: "Mẹo",
    _TIPS_1: "Tên phải dễ đọc và không vượt quá 20 ký tự.",
    _TIPS_2:
      "Mật khẩu phải bao gồm số, chữ cái và ký tự đặc biệt; càng phức tạp càng tốt.",
    _TIPS_3:
      "Email không thể thay đổi. Nếu bạn cần thay đổi, vui lòng liên hệ với bộ phận hỗ trợ.",
    _TIPS_4:
      "Địa chỉ rút tiền chỉ có thể được thay đổi bằng cách xác thực hai yếu tố, điều này phải được kích hoạt trước.",
    _ERROR_PASSWORD: "Lỗi mật khẩu trước đó",
    _SUCCESS: "Hồ sơ được cập nhật thành công",
    _MIN: "Rút tiền tối thiểu",
    _UPDATE: "Cập nhật hồ sơ",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Quên mật khẩu?",
    _EMAIL: "Email của bạn",
    _BUTTON: "Gửi email",
    _MESSAGE: "Mật khẩu của bạn đã được gửi đến email của bạn",
    _ERROR_EMAIL: "Email này chưa được đăng ký",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Cấp bậc hiện tại",
    _NEXT: "Cấp bậc tiếp theo",
    _SILVER: "Bạc",
    _GOLD: "Vàng",
    _RUBY: "Hồng ngọc",
    _EMERALD: "Ngọc lục bảo",
    _DIAMOND: "Kim cương",
    _DIAMONDB: "Kim cương xanh",
    _DIAMONDBB: "Kim cương đen",
    _NONE: "Không có",
  },
  _AUTH: {
    _TITLEDEFAULT: "Thay đổi ví rút tiền",
    _TITLE: "Bước 1",
    _TITLE2: "Bước 2",
    _TITLE3: "Bước 3",
    _STEP1: "Tải xuống và cài đặt ứng dụng xác thực trên thiết bị của bạn",
    _STEP2: "Quét mã để lưu",
    _FORMEMAIL: "Xác minh email",
    _FORMHELPEMAIL: "Nhập mã xác minh 6 chữ số được gửi đến",
    _FORMHELPAUTH: "Nhập mã 6 chữ số do ứng dụng Authenticator tạo",
    _FORM1: "Mã Authenticator",
    _FORM2: "Địa chỉ rút tiền",
    _FORMMIN: "Địa chỉ rút tiền phải dài 34 ký tự",
    _FORMCODESEND: "Mã đã được gửi",
    _VERIFF: "Xác minh",
    _ACTION: "Kiểm tra và lưu",
    _ERROR: "Lỗi, vui lòng thử lại.",
    _SUCCESS: "Thay đổi thành công",
    _RETRYOTP: "Lỗi khi gửi mã, vui lòng thử lại.",
    _SENDOTP: "Mã đã được gửi thành công",
    _SUCCESSOTP: "OTP được xác minh thành công",
    _SUCCESSAUTH2F: "Xác thực hai yếu tố thành công",
    _ERRORCODE: "Mã không hợp lệ, vui lòng thử lại",
  },
  _COUNTDOWN: {
    _TITLE: "Đếm ngược:",
    _DAYS: "Ngày",
    _HOURS: "Giờ",
    _MINUTES: "Phút",
    _SECONDS: "Giây",
  },
  _WITHDRAWAL: {
    _TITLE: "Giao dịch rút tiền",
  },
  _SYSTEM: {
    _SYSTEM: "Hệ thống",
    _GLOBAL: "Toàn cầu",
    _INVESTMENT: "Rút đầu tư",
    _INVESTMENTTEXT: "Hãy nhớ rằng bạn sẽ bị phạt 35% số tiền đầu tư của mình",
    _ACCELERATE: "Tăng tốc",
    _REPORT: "Báo cáo đầu tư Cryptotradex",
    _PARNERT: "Thông tin nhà tài trợ",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Rút Đầu Tư",
    _MESSAGE:
      "Rút vốn của bạn bây giờ sẽ có phí vận hành 35% - lợi nhuận tích lũy và sẽ được xử lý trong vòng 24 giờ. Nếu bạn làm vậy, bạn sẽ ngừng tiềm năng tăng trưởng ngay lập tức của nó. Quyết định với tầm nhìn dài hạn!",
    _BUTTON: "Rút tiền",
    _BUTTONWAIT: "Đang xử lý rút tiền",
  },
  _SIGNAL: {
    _NEWS: "Mới",
    _SIGNALS: "Tín hiệu",
    _WHATIS: "Cryptotradex Signal là gì?",
    _DESCRIPTION:
      "Tham gia nhóm tín hiệu độc quyền của chúng tôi và nhận hơn 10 đề xuất mỗi ngày để tối đa hóa lợi nhuận của bạn, với tỷ suất sinh lợi lên đến 200% từ các khoản đầu tư có rủi ro cao và thấp.",
    _COMMING: "Sắp ra mắt.",
    _SIGNALS10: "TÍN HIỆU10X",
    _CHAT: "Trò chuyện độc quyền",
    _DESCRIPTIONGLANDING:
      "Trong trò chuyện độc quyền của chúng tôi, bạn sẽ nhận được tín hiệu thời gian thực và có thể đóng góp hoặc đặt câu hỏi về từng tín hiệu một cách có tổ chức.",
    _TESTIMONIALS: "LỜI CHỨNG THỰC",
    _GETDAILY: "Nhận tín hiệu hàng ngày từ CTX và bắt đầu nhân vốn của bạn!",
    _TOBE:
      "Để trở thành thành viên của Cryptotradex Signals, bạn phải có khoản đầu tư ít nhất 1.000 USD.",
    _IWANT: "Tôi muốn nhận tín hiệu",
    _TESTIMONIALS1:
      "Tôi đã bắt đầu sử dụng các tín hiệu này trên mạng Solana và có thể biến 200 USD ban đầu thành 600 USD chỉ trong vài giờ. Thông tin được cung cấp qua Dexscreener đã tạo ra sự khác biệt!",
    _TESTIMONIALS2:
      "Với các tín hiệu này theo thời gian thực, tôi đã liên tục nhân đôi khoản đầu tư ban đầu của mình tuần qua tuần. Sự kết hợp giữa dữ liệu Solana và Dexscreener đã giúp tôi kiếm được hơn 3.000 USD chỉ trong 5 tuần!",
    _TESTIMONIALS3:
      "Cộng đồng đằng sau các tín hiệu này thật tuyệt vời. Tôi không chỉ tăng đáng kể tài sản Solana của mình, mà còn tìm thấy một nhóm hỗ trợ tuyệt vời giúp tôi trong mọi giao dịch.",
    _TESTIMONIALS4:
      "Ban đầu tôi đã nghi ngờ, nhưng sự phối hợp giữa các cơ hội dựa trên Solana và thông tin từ Dexscreener đã chứng minh tôi sai. Danh mục đầu tư của tôi đã tăng trưởng vượt xa những gì tôi từng tưởng tượng!",
  },
  _ACADEMY: {
    _NEWS: "Mới",
    _TITLE: "Học viện",
    _WHATIS: "Cryptotradex Academy là gì?",
    _DESCRIPTION:
      "Tìm hiểu về tiền điện tử, ví, meme và Web3 với nền tảng trực tuyến của chúng tôi được thiết kế cho tất cả các cấp độ.",
    _ACTION1: "Đi đến Học viện",
    _DISABLED: "Cần có khoản tiền gửi tối thiểu là 500 USD",
    _TITLANDING: "Trở thành chuyên gia Cryptomemes",
    _DESCRIPTIONLANDING:
      "Truy cập các hướng dẫn độc quyền từ gói BRETT và học cách làm chủ thế giới MEMECOINS.",
    _ACTIONLANDING: "Đăng ký ngay và bắt đầu học!",
    _WHATCOURSES: "Bạn có thể nhận được các khóa học nào?",
    _RECOMMENDS: "Video được đề xuất",
    _VIDEO1: "Hướng dẫn ví Phantom",
    _VIDEO2: "Tìm MEMECOINS bùng nổ trên DEXSCREENER",
    _VIDEO3: "Làm chủ GMGN như một chuyên gia",
    _VIDEO4: "Bot hoàn hảo cho MEMECOINS",
    _VIDEO5: "Cách tìm MEMECOINS có tiềm năng lớn",
  },

  _FREE: {
    _FREE: "Free",
    _FREEFOR: "FREE FOR A LONG TIME",
    _SIGNALS: "Go to Signals",
    _LINK: "Community Link",
    _TITLE:
      "Join our WhatsApp community to get immediate and real-time information",
    _DISCLAIMER: "Disclaimer",
    _CHANNEL: "Go to Channel",
    _TEXTDISCLAIMER:
      "These signals have an execution period, at the moment we publish them in our signals group is when we achieve the demonstrated profit.",
    _LEGEND: "Legend",
    _NAME: "Token Name",
    _URL: "DexScrenner Link",
    _START: "Start Time",
    _END: "End Time",
    _PROFIT: "Profit",
    _VER: "Go",
  },
};
